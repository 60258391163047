import React from "react";

function InfoDetailsCorporate({ title }) {
  const data = {
    Medicines: {
      heading: "Medicines",
      description: "12% discount on medicine purchases.",
    },
    Pathology: {
      heading: "Pathology",
      description: "20% discount on pathology tests and diagnostic services.",
    },
    Radiology: {
      heading: "Radiology",
      description: "15% discount on imaging services (X-rays, MRIs, etc.)",
    },
  };

  const content = data[title];

  return (
    <div className="p-4 bg-white rounded-lg mb-2">
      {content ? (
        <>
          <h4 className="font-bold text-xl mb-1 border-b pb-1 text-secondaryColor">
            {content.heading}
          </h4>
          <p className="text-primaryColor text-sm">{content.description}</p>
        </>
      ) : (
        <p className="text-sm text-gray-500">No information available for {title}.</p>
      )}
    </div>
  );
  
}

export default InfoDetailsCorporate;
