import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../Layouts";
import { Col, Pagination, Row } from "antd";
import { walletdetails, walletTransactiondetails } from '../../../Redux/Action/UserprofileActions';
import { getLocalTime } from './../../Helper/LocalTimeFormat';
import moment from "moment";
import NodataImage from"../../../Assets/Images/Icons/nodata.svg";

const CurebayWallet = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const walletinfo = useSelector((state) => state.patientwallet);
  const [walletTransactionList, setWalletTransactionList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [totalSize, setTotalSize] = useState(0);
  const history = useHistory();

  useEffect(()=>{
    if(Object.keys(userData).length === 0){
      history.push('/', {isLoginModalOpen : true});
    }
  },[])

  useEffect(() => {
    dispatch(walletdetails(userData.code));
    dispatch(walletTransactiondetails(userData.code,pageNumber,pageSize));
  }, [pageNumber,pageSize]);
  useEffect(() => {
    setTransactionList(walletinfo.walletTransactiondetails)
   

  }, [walletinfo]);
  useEffect(() => {
    if (Array.isArray(transactionList)) {
      let data = transactionList.map(item => {
        if (item.amount) {
          item.createdOn = getLocalTime(item?.createdOn)
          return { ...item, amount: item.amount.toFixed(1) }
        } else {
          item.createdOn = getLocalTime(item?.createdOn)
          return item
        }
      })
      setWalletTransactionList(data);
      setTotalSize(data[0]?.totalSize || 0);
    }
  }, [transactionList]);

  const handlePaginationChange = (page, size) => {
   
    setpageNumber(page);
    setPageSize(size);
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className="font-medium md:text-2xl text-White">
              CureBay Wallet
            </div>
          </div>
          <div className="mt-5">
            <div className="w-full h-full  rounded-md px-5 m:px-8 py-4 md:py-6 bg-White">
              <div className="md:flex justify-between flex-wrap gap-8 md:gap-28">
                <h4 className="text-secondaryColor text-xl md:text-2xl font-semibold">
                  ₹ {walletinfo?.patientwalletinfoData?.totalAmount ? walletinfo?.patientwalletinfoData?.totalAmount.toFixed(2) : "0"}
                </h4>
                <div className="flex items-center gap-7 mt-2 md:mt-0">
                  <p className="text-textGray md::text-base font-light">
                    Bonus:{" "}
                    <span className="text-textGray md::text-base font-medium pl-1">
                      ₹ {walletinfo?.patientwalletinfoData?.bonus ? walletinfo?.patientwalletinfoData?.bonus.toFixed(2) : "0"}
                    </span>
                  </p>

                  <p className="text-textGray text-base font-light">
                    Total:{" "}
                    <span className="text-textGray text-base font-medium pl-1">
                      ₹ {walletinfo?.patientwalletinfoData?.totalAmount ? walletinfo?.patientwalletinfoData?.totalAmount.toFixed(2) : "0"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-3 w-full h-full rounded-md px-5 m:px-8 py-4 md:py-6 bg-lightGreen">
              <h6 className="text-secondaryColor text-base md:text-lg">
                Wallet Transactions
              </h6>
            </div>
            <div className="mt-3">
              <Row gutter={[16, 16]}>
                {walletTransactionList?.map((user, i) => (
                  <Col xs={24} sm={12} md={8}>
                    <div className="w-full rounded-md p-5 bg-White">
                      <div className="flex justify-between items-center text-primaryColor mb-3">
                        <div className="text-base ">Amount paid</div>
                        <div className="font-medium text-lg">₹ {user.amount}</div>
                      </div>
                      <div className="flex justify-between items-center gap-5">
                        <div className="text-textGray text-xs">
                          <label className="font-light mb-1 block">
                            Patient Name
                          </label>
                          <p className="text-xs font-medium">{userData.firstName}</p>
                        </div>
                        <div className="text-textGray text-xs">
                          <label className="font-light mb-1 block">
                            Transaction Type
                          </label>
                          <p className="text-xs font-medium">{user.transactionType}</p>
                        </div>
                        <div className="text-textGray text-xs">
                          <label className="font-light mb-1 block">
                            Date of payment
                          </label>
                          <p className="text-xs font-medium">{user.createdOn}</p>
                        </div>
                      </div>
                      <div className="flex justify-between items-end gap-5 mt-3">
                        <div className="text-textGray text-xs">
                          <label className="font-light mb-1 block">
                            Transaction ID
                          </label>
                          <p className=" text-xs font-medium">{user.transactionId}</p>
                        </div>
                        {/* <div className="underline text-secondaryColor text-base">Download Reciept</div> */}
                      </div>
                    </div>
                  </Col>
                ))}
                  {walletTransactionList && walletTransactionList.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No wallet amount available!
                  </p>
                </div>
                 )}
              </Row>

              { walletTransactionList?.length > 0 &&
          <div className="flex justify-center mt-4">
          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={totalSize}
            showSizeChanger={false} 
            onChange={handlePaginationChange}
          />
        </div>
}


            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default CurebayWallet;
