import React from "react";
import TrustIcon from "../../../Assets/Images/Icons/Trust Icon.svg";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import "react-toastify/dist/ReactToastify.css";
const HeroContent = ({data}) =>{
    let result = data.headerComp[0]

    const highlightedText = (text) => {
        // Split the text into two parts
        const [greenText, ...blueText] = text.split('Transforming Lives');
      
        return (
          <p className="mb-4" >
            <span className="text-lg md:text-3xl font-semibold text-secondaryColor" >{greenText.trim()}</span>{' '}
            <div className="text-xl md:text-5xl font-semibold text-primaryColor md:text-White">Transforming Lives</div>
          </p>
        );
      };

      const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth", // Smooth scroll animation
            block: "start", // Align to the top of the section
          });
        }
      }



    return(
        <section className="home-banner min-h-[70vh] my-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              {highlightedText(result?.heading)}
              <p className="text-primaryColor md:text-White text-base font-medium md:text-lg">
                {result?.subHeading}
              </p>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-base mt-1">
                {result?.description}
              </p>
              <div className="flex items-center gap-3 my-7">
                <a
                  href="#allservice"
                  className="bg-primaryColor md:bg-White text-White md:text-primaryColor text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full md:rounded"
                  onClick={(e) => {e.preventDefault(); scrollToSection("allservice")}}
                >
                  See Our Services
                </a>
                <a
                  href="#Contactus"
                  className="bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full md:rounded"
                  onClick={(e) => {e.preventDefault(); scrollToSection("Contactus")}}
                >
                  Contact Us
                </a>
              </div>
              <div className="z-1 relative flex md:hidden justify-end ">
              <div className="mt-10 md:mt-0 md:w-[90%]">
                <div className="relative z-[10]">
                  <video
                    className="w-full rounded-[20px] "
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    // ref={videoRef}
                    poster={result?.image_url}
                  >
                    <source
                      src={result?.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="absolute top-10 md:top-0 z-1">
                  <img
                    src={result?.image_url}
                    alt="images"
                    className="rounded-3xl"
                  />
                </div>
              </div>
            </div>
              <div className="hidden md:flex items-center gap-3">
                <img src={TrustIcon} alt="images" width={25} />
                <p className="text-White font-light text-sm">
                  Trusted by{" "}
                  <span className="font-medium">Over 4 Lakh Patients</span>
                </p>
              </div>
            </div>

            <div className="z-1 relative hidden md:flex justify-end ">
              <div className="mt-10 md:mt-0 md:w-[90%]">
                <div className="relative z-[10]">
                  <video
                    className="w-full rounded-[20px] "
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    // ref={videoRef}
                    poster={result?.image_url}
                  >
                    <source
                      src={result?.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="absolute top-10 md:top-0 z-1">
                  <img
                    src={result?.image_url}
                    alt="images"
                    className="rounded-3xl"
                  />
                </div>
              </div>
            </div>
            <div className="flex md:hidden justify-center items-center gap-3 mt-5">
              <img src={TrustIcon1} alt="images" width={25} />
              <p className="text-primaryColor font-extralight text-sm">
                Trusted by{" "}
                <span className="font-medium">Over 4 Lakh Patients</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default HeroContent