import React, { useRef, useEffect, useState } from "react";
import Layouts from "../Layouts";
import Login from "../Authentications/Login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getToken } from "../../Redux/Action/userActions";
import SubHeader from "../Shared/SearchBar";
import { Button, Skeleton } from "antd";

import { ToastContainer} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import DynamicSections from "./Home/DynamicSections";
import axios from "axios";

const Home = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [homePageSections, setHomePageSections] = useState([])

  useEffect(() =>{
    fetchHomeContent()
  },[])

  const fetchHomeContent = async () =>{
    try {
      let result = await axios.get(`${process.env.REACT_APP_CMS_BASEURL}home-page-v1s/?populate=sections,sections.headerComp,sections.headerComp.button,sections.firstComp,sections.firstComp.button,sections.secondComp,sections.secondComp.button,sections.static,sections.thirdComp,sections.thirdComp.button,sections.forthComp,sections.image_url,sections.static,sections.fifthComp,sections.comp,sections.scomps,sections.sixthComp,sections.sixthComp.button,sections.seventh,sections.seventh.button,sections.eighthComp,sections.eighthComp.eightsubcomp,sections.NinthComp,sections.static,sections.tenthcomp,sections.static,sections.elevencomp`)
      if(result.data.data.length){
        setHomePageSections(result.data.data[0].attributes.sections)
      }
    } catch (error) {
      
    }
  }
  

  useEffect(() => {
    dispatch(getToken());
    if (location?.state?.isLoginModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [location?.state?.isLoginModalOpen]);

  console.log(location, "sdvdsovusdgouvdsvds")

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{"HomePage | Curebay"}</title>
        <meta name="title" content={"HomePage | Curebay"} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Layouts>
        <SubHeader />
        {homePageSections.length ? <DynamicSections sections={homePageSections} /> : <LoadSkeleton/>}
      </Layouts>
      {isModalOpen && <Login mode="Login" from = {location?.state?.from} isModalOpen = {isModalOpen} />}
    </>
  );
};
const mapStateToProps = (state) => ({
  booleanValue: state.needHelp,
});
export default connect(mapStateToProps)(Home);

const LoadSkeleton = () => {
  return(
        <section className="home-banner min-h-[70vh] my-8 ">
<div className="container mx-auto">
<div className="flex justify-between flex-col md:flex-row items-center">
<Skeleton active size = "large" paragraph={{ rows: 10 }} />
<div className="hidden md:block ml-4" >
<Skeleton.Input active size="large" style={{height: 300, width: 400}} block />
</div>
<div className="md:hidden mt-4 w-full text-center" >
<Skeleton.Input active size="large" style={{height: 300}} block />
</div>

        </div>
</div>
      </section>
  )
}
