import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import micIcon from "../../../Assets/Images/mic_icon.svg";
import videoCallIcon from "../../../Assets/Images/video_call.svg";
import endCallIcon from "../../../Assets/Images/end_call.svg";
import otherUser from "../../../Assets/Images/other_user.svg";
import micOffIcon from "../../../Assets/Images/mic_off_icon.svg";
import cameraIcon from "../../../Assets/Images/camera_icon.svg";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { useSelector } from "react-redux";
import patientService from "../../../Redux/Services/patientService";

export const VideoCall = () => {
  const [calling, setCalling] = useState(false);
  const [useAgora, setUseAgora] = useState(false);
  const isConnected = useIsConnected();
  const [channel, setChannel] = useState("");
  const [token, setToken] = useState("");
  const [uId, setUId] = useState("");
  const [url, setUrl] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const joinCode =
    queryParams.get("joinCode") || sessionStorage.getItem("joinCode");
  const consultationType =
    queryParams.get("consultationType") ||
    sessionStorage.getItem("consultationType");
  const history = useHistory();
  const { state } = location;
  const appId = "5da64dc39db9467485eeaac6d515f210";
  const userData = useSelector((state) => state.authReducer.patientData);

  // Agora-specific hooks and states
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  const remoteUsers = useRemoteUsers();
  // useEffect(() => {
  //   if (localMicrophoneTrack) {
  //     // Ensure the local microphone track is muted locally
  //     localMicrophoneTrack.setVolume(0); // Mutes playback for the local user
  //   }
  // }, [localMicrophoneTrack]);

  useJoin(
    { appid: appId, channel: channel, token: token || null, uid: uId },
    calling
  );
  usePublish([localMicrophoneTrack, localCameraTrack]);

  const setupAgora = async () => {
    try {
      const channelId = joinCode
        ? String(joinCode)
        : state?.id
        ? String(state.id)
        : "defaultChannel";
      const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");
      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
      );
      const token = response.data?.data?.token;
      const uuid = response.data?.data?.uid;
      if (!token || !appId || !sanitizedChannelId) {
        throw new Error("App ID, Token, or Channel ID is undefined");
      }
      sessionStorage.removeItem("joinCode");
      sessionStorage.removeItem("consultationType");
      setToken(token);
      setChannel(channelId);
      setUId(uuid);
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  const getMaggiePlusLink = async () => {
    try {
      const header = {
        secretKey: process.env.REACT_APP_MAGGIEPLUS_SECRET_KEY,
      };
      const defaultEmail = "patient@gmail.com";
      const data = {
        clientCode: "CCM@@202!",
        userType: "HOST",
        meetingKey: joinCode ? joinCode : state.id,
        memberName: userData.firstName,
        memberEmail: userData.email || defaultEmail,
        memberMobile: userData.mobile,
      };
      const result = await axios.post(process.env.REACT_APP_MAGGIEPLUS, data, {
        headers: header,
      });
      const generatedUrl =
        consultationType === "A"
          ? result.data.response.url + "/1"
          : result.data.response.url;
      setUrl(generatedUrl);
    } catch (error) {
      console.error("Error getting Maggie Plus link:", error);
    }
  };

  useEffect(() => {
    if (useAgora) {
      setupAgora();
    } else {
      getMaggiePlusLink();
    }
  }, [useAgora]);

  useEffect(() => {
    if (token && channel && uId) {
      setCalling(true);
    }
  }, [token, channel, uId]);

  const endConsultation = async () => {
    setCalling(false);
    let payload = {
      id: joinCode
      ? String(joinCode)
      : state?.id
      ? String(state.id)
      : "defaultChannel",
      status: "END",
    };
    try {
      await patientService.callStatusUpdate(payload);
      history.push(APP_ROUTES.HOME);
    } catch (error) {
      console.error("Failed to update call status:", error);
      history.push(APP_ROUTES.HOME);
    }
  };

  const getGridClass = () => {
    const totalUsers = remoteUsers.length + 1; // Local user + remote users
    if (totalUsers === 1) return "grid-cols-1";
    if (totalUsers === 2) return "grid-cols-1 md:grid-cols-2";
    if (totalUsers >= 3) return "grid-cols-1 md:grid-cols-2";
    return "grid-cols-3 grid-rows-2";
  };

  const flagFeature = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}agoraInfo/get`
      );

      if (response.data && response.data) {
        console.log(response.data[1], "ResponseFlg");
        if (response.data[1]?.isAgora === true) {
          setUseAgora(true);
        } else {
          setUseAgora(false);
        }
      } else {
        console.error("Token or UUID is missing in the response");
      }
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  useEffect(() => {
    flagFeature();
  }, []);

  const endconsultation = async () => {
    setCalling((a) => !a);
    
    let payload = {
      id: joinCode
      ? String(joinCode)
      : state?.id
      ? String(state.id)
      : "defaultChannel",
      status: "END",
    };
    try {
      await patientService.callStatusUpdate(payload);
      history.push(APP_ROUTES.HOME);
    } catch (error) {
      console.error("Failed to update call status:", error);
      history.push(APP_ROUTES.HOME);
    }
  };

  return (
    <>
      <div className="room">
        {useAgora ? (
          isConnected ? (
            <>
              <div
                className={`grid ${getGridClass()} gap-1 flex-1`}
                style={{
                  height: "calc(85vh - 80px)", // Adjust the grid height to account for the control bar
                  paddingBottom: "80px", // Ensure space for the control bar
                }}
              >
                <div className="user">
                  <LocalUser
                    audioTrack={localMicrophoneTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    videoTrack={localCameraTrack}
                    cover={otherUser}
                  >
                    <samp className="user-name">You</samp>
                  </LocalUser>
                </div>
                {remoteUsers.map((user) => (
                  <div className="user" key={user.uid}>
                    <RemoteUser cover={cameraIcon} user={user}>
                      <samp className="user-name">Dr {state.userName}</samp>
                    </RemoteUser>
                  </div>
                ))}
              </div>
              <div
                className="control fixed bottom-0 left-0 right-0 flex items-center justify-between bg-gray-900 p-4"
                style={{
                  zIndex: 10, // Ensure it's above the grid
                  height: "80px", // Fixed height for consistent spacing
                }}
              >
                <div className="left-control flex space-x-4">
                  <button
                    className="btn flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full hover:bg-gray-600"
                    onClick={() => setMic((prev) => !prev)}
                  >
                    <img
                      src={micOn ? micIcon : micOffIcon}
                      alt="Microphone"
                      className="w-6 h-6"
                    />
                  </button>
                  <button
                    className="btn flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full hover:bg-gray-600"
                    onClick={() => setCamera((prev) => !prev)}
                  >
                    <img
                      src={cameraOn ? videoCallIcon : cameraIcon}
                      alt="Camera"
                      className="w-6 h-6"
                    />
                  </button>
                </div>
                <button
                  className={`btn btn-phone ${
                    calling ? "btn-phone-active" : ""
                  }`}
                  onClick={() => endconsultation()}
                >
                  {calling ? (
                    <i className="i-phone-hangup" />
                  ) : (
                    <i className="i-mdi-phone" />
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="h-screen flex justify-center items-center">
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </div>
          )
        ) : url ? (
          <div className="p-3 md:p-10 flex-col">
            <div className="relative">
              <iframe
                className="myframe"
                allow="camera; microphone;"
                sandbox="allow-scripts allow-same-origin allow-modals allow-top-navigation"
                style={{ width: "100%", height: "85vh" }}
                src={url}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className="self-center mt-8 text-primaryColor">
              {`To view and download your prescription please go to My Profile-->Click on My Health Records-->Click on My Prescription`}
            </div>
            <div className="w-full bottom-0 flex justify-center mt-5">
              <button
                onClick={endconsultation}
                className="bg-secondaryColor  text-sm text-White font-normal rounded-md py-2 px-3 mr-2"
              >
                {url && ` End Consultation`}
              </button>
              <br />
            </div>
          </div>
        ) : (
          <div className="h-screen flex justify-center items-center">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </div>
        )}
      </div>
      {useAgora && isConnected && (
        <div className="control">
          <div className="left-control">
            <button className="btn" onClick={() => setMic((prev) => !prev)}>
              <img src={micOn ? micIcon : micOffIcon} alt="Microphone" />
            </button>
            <button className="btn" onClick={() => setCamera((prev) => !prev)}>
              <img src={cameraOn ? videoCallIcon : cameraIcon} alt="Camera" />
            </button>
          </div>
          <button
            className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
            onClick={endConsultation}
          >
            <img src={endCallIcon} alt="End Call" />
          </button>
        </div>
      )}
    </>
  );
};

export default VideoCall;
