import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import ScrollTop from "../../Assets/Images/Icons/scrolldown.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
// import SpeedDial from '@mui/material/SpeedDial';
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";
import CareSathiBorderIcon from '../../Assets/Images/BottomNavigationIcons/Caresathi-border.svg'
import ConsultationBorderIcon from '../../Assets/Images/BottomNavigationIcons/Consultation-border.svg'
import HomeBorderIcon from '../../Assets/Images/BottomNavigationIcons/Home-border.svg'
import LabBorderIcon from '../../Assets/Images/BottomNavigationIcons/Lab-border.svg'
import MedicineBorderIcon from '../../Assets/Images/BottomNavigationIcons/Medicine-border.svg'
import MembershipBorderIcon from '../../Assets/Images/BottomNavigationIcons/Membership-border.svg'
import CareSathiFilledIcon from '../../Assets/Images/BottomNavigationIcons/Caresathi-filled.svg'
import ConsultationFilledIcon from '../../Assets/Images/BottomNavigationIcons/Consultation-filled.svg'
import HomeFilledIcon from '../../Assets/Images/BottomNavigationIcons/Home-filled.svg'
import LabFilledIcon from '../../Assets/Images/BottomNavigationIcons/Lab-filled.svg'
import MedicineFilledIcon from '../../Assets/Images/BottomNavigationIcons/Medicine-filled.svg'
import MembershipFilledIcon from '../../Assets/Images/BottomNavigationIcons/Membership-filled.svg'
import Fab from "@mui/material/Fab";
import { Tooltip } from 'antd';
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CareSathiIcon from '../../Assets/Images//BottomNavigationIcons/care-sathi-home-icon.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import SpeedDial from '@mui/material/SpeedDial';
import LayoutHelpIcon from '../../Assets/Images/Icons/LayoutHelpIcon.svg'


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: window.innerWidth <= 768 ? theme.spacing(10) : theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const Layouts = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [hidden, setHidden] = React.useState(false);
  const [showWidgets, setShowWidgets] = useState(false);
  let renderOneTime = false
  const isMobile = window.innerWidth <= 768;
  console.log(location.pathname, "dvisdgouvgsduovsdvds")
  const items = [
    {
      label: "Home",
      icon: HomeBorderIcon,
      filledIcon: HomeFilledIcon,
      path:  "/",
      command: () => {
        history.push(APP_ROUTES.HOME);
      },
    },
    {
      label: "Medicine",
      icon: MedicineBorderIcon,
      filledIcon: MedicineFilledIcon,
      path: "/services/order-medicines",
      command: () => {
        history.push(APP_ROUTES.MEDICINE);
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    {
      label: "Consultation",
      icon: ConsultationBorderIcon,
      filledIcon: ConsultationFilledIcon,
      path: "/services/book-an-appointment",
      command: () => {
        history.push(APP_ROUTES.BOOKANAPPOINTMENT);
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    {
      label: "Lab",
      icon: LabBorderIcon,
      filledIcon: LabFilledIcon,
      path: "/services/lab",
      command: () => {
        history.push(APP_ROUTES.LAB);
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    // {
    //   label: "Caresathi",
    //   icon: <CareSathiBorderIcon />,
    //   filledIcon : <CareSathiFilledIcon />,
    //   command: () => {
    //     // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
    //   },
    // },
    {
      label: "Membership",
      icon: MembershipBorderIcon,
      filledIcon: MembershipFilledIcon,
      path: "/services/membership",
      command: () => {
        history.push(APP_ROUTES.MEMBERSHIP);
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
  ];
  // Function to handle scroll event
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 200; // Adjust this value to set the height threshold
    if (scrollTop > threshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSareSathiClick = () => {
    if (Object.keys(userData).length === 0) {
      // history.push("/");
      if (location.pathname === "/") {
        history.push("/" , {isLoginModalOpen : true});
      } else {
        history.push("/" , {isLoginModalOpen : true});
      }
    } else {
      history.push(APP_ROUTES.ASKCARESATHI);
    }
  };

  useEffect(() => {
    const iframe = document.getElementById("freshworks-container");
    if(renderOneTime || iframe){
      return;
    }
    window.fwSettings = {
      widget_id: 1070000000324,
      position: "bottom-left",
    };

    if (typeof window.FreshworksWidget !== "function") {
      class n {
        constructor() {
          n.q.push(arguments);
        }
      }
      n.q = [];
      window.FreshworksWidget = n;
    }
    renderOneTime = true
    const script = document.createElement("script");
    script.src = "https://ind-widget.freshworks.com/widgets/1070000000324.js";
    script.async = true;
    document.body.appendChild(script);
 // Mutation observer to hide iframe
 const observer = new MutationObserver((mutationsList) => {
  for (let mutation of mutationsList) {
    if (mutation.type === "childList") {
      const iframe = document.getElementById("freshworks-container");
      if (iframe) {
        iframe.style.display = "none"; // Hide the iframe
        observer.disconnect(); // Stop observing once the iframe is hidden
      }
    }
  }
});

// Observe changes in the body
observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Initially hide the iframe with id="launcher-frame"
    const iframe = document.getElementById("freshworks-container");
    if (iframe && location.pathname == '/ask-care-sathi') {
      iframe.style.display = "none";
    }
  }, []);

const handleSpeedDialClick = () => {
  setShowWidgets((prev) => {
    const newVisibility = !prev;

    // Show/Hide iframe with id="launcher-frame"
    const iframeContainer = document.getElementById("freshworks-container");
    const iframe = document.getElementById("launcher-frame");
    if (iframe) {
      iframeContainer.style.display = newVisibility ? "block" : "none";
      if(isMobile){
     
       iframe.style.right = "80px"
      iframe.style.bottom = "74px"

      }else{
        iframe.style.bottom = "12px"
       iframe.style.right = "80px"
      }

      
    }

    return newVisibility;
  });
};


  return (
    <div>
      <div className="pb-[5rem]">
        <Header />
      </div>
      {/* <SubHeader/> */}
      <div>{children}</div>
      <Footer />
      {isVisible && (
        <div
          className="fixed right-0 bottom-0 cursor-pointer hidden sm:block"
          onClick={handleClick}
        >
          <img
            src={ScrollTop}
            alt="images"
            className="w-[90px] h-[50px] md:w-full"
          />
        </div>
      )}
      {/* <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: 4, right: 2 }}
        icon={<SupportAgentIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial> */}
      <div className="" style={{ position: "fixed", bottom: 0, right: 0, zIndex: 800 , width: isMobile ? "100%" : " "}}>
        <div className="md:hidden grid grid-cols-5 px-1" style={{backgroundColor:"#DAEFFF", borderTopLeftRadius: 12, borderTopRightRadius: 12}}  >
        {items.map(res => (
          <button onClick={res.command} className="flex flex-col justify-center items-center relative" >
           { res.path == location.pathname  ? <div className="mb-2" style={{width: 35, height: 4, backgroundColor: "#134171"}} /> : <div className="mb-2" style={{width: 35, height: 4}} />}
           { res.path == location.pathname ?  <img className="h-4 w-4 mb-1"  src = {res.filledIcon} /> : <img className="h-4 w-4 mb-1"  src = {res.icon} />}
            <p className="text-xs font-light mb-3" style={res.path == location.pathname ? {color:"#134171"} : {color: "#262626"} } >{res.label}</p>
            {res.path == location.pathname  ? <div className="mt-1 absolute top-12" style={{width: 30, height: 30, backgroundColor: "#134171", borderRadius: "50%"}} /> : null}
          </button>
        ))}
        </div>
        <div className="absolute right-4 bottom-36 md:right-4 md:bottom-24" >
        {/* {showWidgets && (
        <Tooltip placement="top" title="Ask Health Related Questions">
          <Fab
            style={{
              backgroundColor: "#42BA85",
              border: "1px solid #42BA85",
            }}
            aria-label="add"
          >
            <img src={CareSathiIcon} alt="CareSathi Icon" />
          </Fab>
        </Tooltip>
      )} */}

{showWidgets &&  <Tooltip placement="top" title="Ask Health Related Questions"><Fab
            style={{
              backgroundColor: "#42BA85",
              border: "1px solid #42BA85",
              zIndex: 10000
            }}
            aria-label="add"
            onClick={handleSareSathiClick}
          >
            <img src={CareSathiIcon} alt="CareSathi Icon" />
          </Fab></Tooltip>}
         
          </div>

{/* <Box sx={{ position: "relative", mt: 3, height: 320 }}> */}
<StyledSpeedDial
          ariaLabel="SpeedDial playground example"
          hidden={hidden}
          icon={ <img src= {LayoutHelpIcon} height={40} width={40} />}
          direction= "up"
          onClick={handleSpeedDialClick}
        >
        {/* <SpeedDial
          ariaLabel="SpeedDial playground example"
          icon={<SpeedDialIcon />}
          direction="up"
          onClick={handleSpeedDialClick}
        /> */}
         
        </StyledSpeedDial>
      {/* </Box> */}

      </div>
      {/* <SpeedDial model={items} radius={120} showIcon = {<SupportAgentIcon style={{color: "#ffff"}} />} type="quarter-circle" direction="up-left" style={{position: "fixed", bottom: 10, right: 10, backgroundColor: "#004171", borderRadius:"50%" }} /> */}
      {/* <div onClick={handleSareSathiClick} className="fixed right-0 bottom-0 cursor-pointer ">
        <p>Care Sathi</p>
      </div> */}
    </div>
  );
};

export default Layouts;