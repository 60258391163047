import React from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from "moment-timezone"; // <-- Use moment-timezone
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

// Import your custom lab icons
import SampleCollectedGrey from '../../../../Assets/Images/Grey icon/Sample collected.svg';
import SampleCollectedGreen from '../../../../Assets/Images/Green icons/Sample collected.svg';
import SampleReadyGrey from '../../../../Assets/Images/Grey icon/sample ready to dispatch.svg';
import SampleReadyGreen from '../../../../Assets/Images/Green icons/sample ready to dispatch.svg';
import OnBusGrey from '../../../../Assets/Images/Grey icon/sample on the bus.svg';
import OnBusGreen from '../../../../Assets/Images/Green icons/sample on the bus.svg';
import DeliveredLabGrey from '../../../../Assets/Images/Grey icon/sample delivered to lab.svg';
import DeliveredLabGreen from '../../../../Assets/Images/Green icons/sample delivered to lab.svg';

// Custom Connector (Single grey line)
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10, // Adjust position for vertical alignment
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: '100%', // Ensure the line spans the full height
    border: 0,
    backgroundColor: '#eaeaf0', // Grey color for the line
    marginLeft: 20, // Adjust for alignment with icons
    width: 3, // Adjust thickness of the line
  },
}));

// Custom Step Icon
const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
  backgroundColor: ownerState.completed ? "#59d897" : '#eaeaf0', // Green for completed, grey otherwise
  color: ownerState.completed ? "#00654D" : '#a1a1a1',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: ownerState.completed ? '0 4px 10px 0 rgba(89,216,151,.25)' : 'none',
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  const icons = {
    1: (
      <img
        src={completed ? SampleCollectedGreen : SampleCollectedGrey}
        alt="Sample Collected"
        style={{ width: 24 }}
      />
    ),
    2: (
      <img
        src={completed ? SampleReadyGreen : SampleReadyGrey}
        alt="Sample Ready to Dispatch"
        style={{ width: 24 }}
      />
    ),
    3: (
      <img
        src={completed ? OnBusGreen : OnBusGrey}
        alt="Sample Shipped"
        style={{ width: 35, height: 35 }}
      />
    ),
    4: (
      <img
        src={completed ? OnBusGreen : OnBusGrey}
        alt="Sample on Bus"
        style={{ width: 24 }}
      />
    ),
    5: (
      <img
        src={completed ? DeliveredLabGreen : DeliveredLabGrey}
        alt="Sample Delivered to Lab"
        style={{ width: 24 }}
      />
    ),
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[icon]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
};

const initialLabSteps = [
  "Sample Collected",
  "Sample Ready to Dispatch",
  "Sample Shipped",
  "Sample on the Bus (In-Transit)",
  "Sample Delivered to Lab",
];

const MobLabOrderTracking = ({ trackingStatus }) => {
  // Determine the active step based on trackingStatus
  const activeStep = trackingStatus && trackingStatus.length > 0
    ? trackingStatus.length
    : 0;

  return (
    <div className="flex flex-col items-center bg-white rounded-lg p-4 shadow">
      <div className="text-lg font-semibold mb-4">Lab Order Tracking</div>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {initialLabSteps.map((label, index) => {
            const reachedDate = trackingStatus[index]?.reachedDate;
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1}>
                  <div className="flex flex-col">
                    <div>{label}</div>
                    {reachedDate && (
                      <div className="text-xs text-gray-500">
                        {/* 
                          1) Interpret the date as UTC (if your API returns UTC).
                          2) Convert to IST (Asia/Kolkata).
                          If your API returns local time, 
                          remove `.utc(...)` and just do `moment(reachedDate).tz("Asia/Kolkata")`.
                        */}
                        {moment
                          .utc(reachedDate)
                          .tz("Asia/Kolkata")
                          .format("DD-MM-YYYY hh:mm A")}
                      </div>
                    )}
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    </div>
  );
};

MobLabOrderTracking.propTypes = {
  trackingStatus: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.number.isRequired,
      reachedDate: PropTypes.string.isRequired,
    })
  ),
};

export default MobLabOrderTracking;
