import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import sampleSurgery from "../../../Assets/Images/Cataract.svg";
import sampleSymptoms from "../../../Assets/Images/symptomsSample.svg";
import http from "../../../Redux/Services/http-common";
import Layouts from "../../Layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, InputNumber, Modal, Select, message } from "antd";
import axios from "axios";
import Vericocele from "./SurgeryDetail/varicocele.jsx";
import Cataract from "./SurgeryDetail/Cataract.jsx";
import Cystoscopy from "./SurgeryDetail/Cystoscopy.jsx";
import Phimosis from "./SurgeryDetail/Phimosis.jsx";
import Fissures from "./SurgeryDetail/Fissures.jsx";
import Hydrocele from "./SurgeryDetail/Hydrocele.jsx";
import Lasik from "./SurgeryDetail/Lasik.jsx";
import Lipoma from "./SurgeryDetail/Lipoma.jsx";
import LipoSuction from "./SurgeryDetail/LipoSuction.jsx";
import Fistula from "./SurgeryDetail/Fistula.jsx";
import Hernia from "./SurgeryDetail/Hernia.jsx";
import Gynecomastia from "./SurgeryDetail/Gynecomastia.jsx";
import KidneyStones from "./SurgeryDetail/KidneyStones.jsx";
import RhinoPlasty from "./SurgeryDetail/Rhinoplasty.jsx";
import VaricoseVeins from "./SurgeryDetail/VaricoseVeins.jsx";
import Piles from "./SurgeryDetail/Piles.jsx";
import Appendicitis from "./SurgeryDetail/Appendicitis.jsx";
import HipReplacement from "./SurgeryDetail/HipReplacement.jsx";
import GallStone from "./SurgeryDetail/GallStone.jsx";
import KneeReplacement from "./SurgeryDetail/KneeReplacement.jsx";
import Surg_2 from "../../../Assets/Images/Surgery/popup.svg";
import Surg_3 from "../../../Assets/Images/Surgery/popup_1.svg";
import Surg_4 from "../../../Assets/Images/Surgery/popup_2.svg";
import Surg_5 from "../../../Assets/Images/Surgery/popup_3.svg";
import US from "../../../Assets/Images/SurgerySub/USFDA-APPROVED.svg";
import stay from "../../../Assets/Images/SurgerySub/24hrSTAY.svg";
import Cab from "../../../Assets/Images/SurgerySub/CABSERVICES.svg";
import AD from "../../../Assets/Images/SurgerySub/AdvanceTech.svg";
import LC from "../../../Assets/Images/SurgerySub/LessPain.svg";
import QD from "../../../Assets/Images/SurgerySub/QuickDischarge.svg";
import Surgery from "./Surgery.jsx";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Slider from "react-slick";

function SurgeryDetails() {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [surgeryData, setSurgeryData] = useState({});
  const [surgeryObj, setSurgeryObj] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setModal] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [profile, setProfile] = useState(userData);
  const [formerror, setFormerror] = useState({
    patientId: "",
    patientName: "",
    mobileNo: "",
    state: "",
    city: "",
    surgeryId: "",
  });
  const [submitData, setSubmitData] = useState({
    patientId: "",
    patientName: "",
    mobileNo: "",
    state: "",
    city: "",
    surgeryId: "",
  });
  const params = useParams();
  const searchParam = params.search;
  const extractedNumber = searchParam.split("--")[1];
  console.log(extractedNumber);
  const surgeryName = searchParam.split("--")[0];

  console.log(params, "params");
  const titleCard = [{
    icon: US,
    iconName: "USFDA-Approved"
  }, {
    icon: stay,
    iconName: "24hr Stay"
  }, {
    icon: Cab,
    iconName: "Cab Services"
  }, {
    icon: AD,
    iconName: "Advance Technology"
  }, {
    icon: LC,
    iconName: "Less Cut Less Pain"
  }, {
    icon: QD,
    iconName: "Quick Discharge"
  },]
  const getsurgeryDetail = async () => {
    try {
      const config = {
        method: "get", // HTTP method
        url: `${process.env.REACT_APP_CMS_BASEURL}surgeries/${extractedNumber}?populate=fourthComp,fourthComp.points,symptoms.subdata,firstComp,thirdComp,thirdComp.points,thirdComp.points.sub_points,typesSubPoint,secondComp,secondComp.points,procedure,benefits.benefitsSub,Preventcomp.title,Preventcomp.title2,develop,develop.icons,doctor,typesofsur.doctor,doctor.doctor,DiagnoseComp,DiagnoseComp.title,DiagnoseComp.title2,fifthComp,fifthComp.points,sixthComp.points,sixthComp.points.sub_points,seventhComp,seventhComp.points,eightComp,eightComp.points.sub_points,ninthComp,ninthComp.points,tenthcomp,tenthcomp.points,tenthcomp.points.subpoints,elevencomp,elevencomp.sp,twelthcomp`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Axios request with manual configuration
      let response = await axios(config);

      setSurgeryData(response?.data?.data); // Setting surgery data in state
      console.log(response?.data?.data, "SurgerDetails");
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getsurgeryDetail();
  }, []);

  const renderHeadingWithSurgeryName = (heading, surgeryName) => {
    if (heading && surgeryName) {
      // Split the heading text based on the surgery name
      const parts = heading.split(surgeryName);
      return (
        <>
          {parts[0]}
          <span className="text-secondaryColor">{surgeryName}</span>
          {parts[1]}
        </>
      );
    }
    return heading;
  };

  const renderHeadingWithHighlightedText = (heading, termToHighlight) => {
    if (heading && termToHighlight) {
      const parts = heading.split(termToHighlight);
      return (
        <>
          {parts[0]}
          <span className="text-secondaryColor">{termToHighlight}</span>
          {parts[1]}
        </>
      );
    }
    return heading;
  };

  const renderSurgeries = (data) => {
    if (data?.surgery_id === '21') {
      return <Cataract data={data} />
    } else if (data?.surgery_id === '9') {
      return <Vericocele data={data} />
    } else if (data?.surgery_id === '16') {
      return <Cystoscopy data={data} />
    } else if (data?.surgery_id === '20') {
      return <Phimosis data={data} />
    } else if (data?.surgery_id === '3') {
      return <Fissures data={data} />
    } else if (data?.surgery_id === '6') {
      return <Hydrocele data={data} />
    } else if (data?.surgery_id === '19') {
      return <Lasik data={data} />
    } else if (data?.surgery_id === '8') {
      return <Lipoma data={data} />
    } else if (data?.surgery_id === '18') {
      return <LipoSuction data={data} />
    } else if (data?.surgery_id === '2') {
      return <Fistula data={data} />
    } else if (data?.surgery_id === '13') {
      return <Hernia data={data} />
    } else if (data?.surgery_id === '12') {
      return <Gynecomastia data={data} />
    } else if (data?.surgery_id === '5') {
      return <KidneyStones data={data} />
    } else if (data?.surgery_id === '17') {
      return <RhinoPlasty data={data} />
    } else if (data?.surgery_id === '10') {
      return <VaricoseVeins data={data} />
    } else if (data?.surgery_id === '1') {
      return <Piles data={data} />
    } else if (data?.surgery_id === '11') {
      return <Appendicitis data={data} />
    } else if (data?.surgery_id === '14') {
      return <HipReplacement data={data} />
    } else if (data?.surgery_id === '7') {
      return <GallStone data={data} />
    } else if (data?.surgery_id === '15') {
      return <KneeReplacement data={data} />
    }
  }
  const appointment = () => {
    setModal(true);
  };
  const handleCancel = () => {
    setModal(false);
  };
  const handleSubmit = () => {
    let values = form.getFieldValue();

    if (profile.firstName === undefined || profile.firstName === "") {
      if (values.patientName === undefined || values.patientName === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.email === undefined || profile.email === "") {
      if (values.patientEmail === undefined || values.patientEmail === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.mobile === undefined || profile.mobile === "") {
      if (values.mobileNo === undefined || values.mobileNo === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all fields");
      return;
    }

    if(!/^[a-zA-Z\s]{3,50}$/.test(values.patientName ? values.patientName : profile.firstName)){
      message.error("Please add valid patient Name");
      return;
    }

    if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.patientEmail ? values.patientEmail : profile.email)){
      message.error("Please add valid email");
      return;
    }


    if(!/^[6-9]\d{9}$/.test(values.mobileNo ? values.mobileNo : profile.mobile)){
      message.error("Please add valid Mobile number");
      return;
    }

    let payload;

    if (Object.keys(userData).length === 0) {
      payload = {
        patientName: values.patientName,
        patientEmail: values.patientEmail,
        city: values.city,
        patientId: userData.code,
        mobileNo: values.mobileNo,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId:  extractedNumber,
      };
    } else {
      payload = {
        patientName: values.patientName
          ? values.patientName
          : profile.firstName,
        patientEmail: values.patientEmail ? values.patientEmail : profile.email,
        city: values.city,
        patientId: userData.code,
        mobileNo: values.mobileNo ? values.mobileNo : profile.mobile,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId: extractedNumber,
      };
    }

    payload = {
      patientName: values.patientName ? values.patientName : profile.firstName,
      patientEmail: values.patientEmail ? values.patientEmail : profile.email,
      city: values.city ? values.city : profile.city,
      patientId: userData.code,
      mobileNo: values.mobileNo ? values.mobileNo : profile.mobile,
      sourceChannel: "B2c",
      state: "OD",
      status: 1,
      surgeryId: JSON.parse(extractedNumber),
    };
    setLoading(true);
    http
      .post(`${process.env.REACT_APP_BASEURL}surgery/bookAppointment`, payload)
      .then((res) => {
        message.success("Appointment Booked");
        form.resetFields();
        setLoading(false);
        setModal(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };
  const SurgerySlide = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    // autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
         
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        dots: true,
        arrows: false
        },
      },
    ],
  };
  return (
    <Layouts>
      <ToastContainer />
      <div className="relative ">
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16">
          <div className="container mx-auto py-12 flex flex-col md:flex-row items-center">
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-White">
                {surgeryData?.attributes?.firstComp.heading}
              </h1>
              <p className="text-base md:text-lg mb-6 text-White text-left">
                {surgeryData?.attributes?.firstComp?.description}
              </p>
              <button
                className="bg-secondaryColor h-10 px-6 rounded-xl text-white text-White my-1 md:my-6"
                onClick={appointment}
              >
                Consult for a Surgery
              </button>
            </div>
            <div className="w-full md:w-1/2 flex justify-center md:justify-end md:ml-4">
              <img
                src={surgeryData?.attributes?.firstComp?.image_url}
                alt="Cataract patient"
                className="rounded-lg shadow-md h-[100%] w-[100%] md:h-[70%] md:w-[70%]"
              />
            </div>
          </div>
        </div>
        <div className="md:absolute md:-bottom-16  w-full mt-8 md:mt-0">
          <div className="flex items-center gap-8 justify-center flex-wrap md:flex-nowrap md:overflow-x ">
            {titleCard.map((surgery, index) => (
              <div
                // onClick={() => handleNavigation(surgery.surgeryName)}
                className="cursor-pointer bg-[#ffffff]  rounded-xl py-2 md:p-5 border sm:h-[135px] h-[80px]"
                style={{ border: "0.5px solid #E3E3E3" }}
              >
                <div className="w-40 md:w-32" >
                  <div
                    key={index}
                    className="flex flex-col items-center w-full"
                  >
                    <img
                      src={surgery.icon}
                      alt={surgery.icon}
                      className={surgery.iconName === "Advance Technology" ? "w-[35px] md:w-[50px] mb-4 md:mb-0" : "w-[25px] md:w-[30px] mb-4 md:mb-0"}
                    />
                    <h6 style={{ fontSize: 13, fontWeight: 500 }} className="text-primaryColor mt-2 md:mt-4">
                      {surgery.iconName}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Symptoms of Cataract Section */}
      {surgeryData?.attributes?.secondComp ? (
        <div
          className="mt-8 md:mt-28 py-10 px-4 md:px-16"
          style={{ backgroundColor: "#EBF8FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-center"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {/* {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.secondComp?.heading,
                surgeryData?.attributes?.surgeryName
              )} */}
              {surgeryData?.attributes?.secondComp?.heading}
            </h2>
            <h6 className=" mb-4 text-center" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }} >{surgeryData?.attributes?.secondComp?.subtitle}</h6>

            <div className="mb-[70px] md:mb-0 ">
              <Slider {...SurgerySlide}>
                {surgeryData?.attributes?.secondComp?.points.map(
                  (symptom, index) => (
                    <div className="px-3 mb-6 md:mb-0">
                      <div className="bg-White rounded-2xl  p-5">
                        <div
                          className="p-4 rounded-lg h-[200px] overflow-y-auto scrollbar-none"
                          key={index}
                          style={{ backgroundColor: "#ffff" }}
                        >
                          <img
                            src={symptom?.icon_url}
                            // alt={symptom?.description}
                            className="pl-6 mb-4 align-left"
                          />
                          <h1
                            className="pl-6 text-secondaryColor text-base font-medium text-left"
                            style={{ color: "#676C80" }}
                          >
                            {symptom.heading ? (
                              <>
                                <span style={{ color: "#005D8D" }} className="font-semibold">{symptom.heading}</span>
                                <br />
                                {symptom.description || " "}
                              </>
                            ) : (
                              symptom.description
                            )}
                          </h1>
                          <ul className="pl-6 mt-4" style={{ listStyleType: "disc", color: "#004171" }}>
                            {symptom?.pnt1 ? <li className="py-2" >{symptom?.pnt1}</li> : null}
                            {symptom?.pnt2 ? <li className="py-2" >{symptom?.pnt2}</li> : null}
                            {symptom?.pnt3 ? <li className="py-2" >{symptom?.pnt3}</li> : null}
                            {symptom?.pnt4 ? <li className="py-2" >{symptom?.pnt4}</li> : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>

            {surgeryData?.attributes?.secondComp?.note ? <h6 className=" mb-4 text-center mt-8" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }} >{surgeryData?.attributes?.secondComp?.note}</h6> : null}
          </div>
        </div>
      ) : (
        <p>No symptoms data available</p>
      )}

      {/* Types of Cataract Section */}
      {surgeryData?.attributes?.thirdComp?.points?.length ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-12 text-center">
              {/* {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.thirdComp?.heading,
                surgeryData?.attributes?.surgeryName
              )} */}
              {surgeryData?.attributes?.thirdComp.points[0].mainTitle}
            </h2>
            {surgeryData?.attributes?.thirdComp.points[0]?.mainSubTitle && <h6 className=" mb-4 text-center" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }} >{surgeryData?.attributes?.thirdComp.points[0]?.mainSubTitle}</h6>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-0 md:mx-16">
              {surgeryData?.attributes?.thirdComp?.points.map((type, index) => {
                return (<div className="rounded-xl py-4 px-8" style={{ border: "0.5px solid #E3E3E3" }} >
                  <p className="pl-3 text-xl font-semibold mb-2" style={{ color: "#005D8D" }} >{type.title}</p>
                  <p className="pl-3  text-base font-medium" style={{ color: "#676C80" }}>{type.description}</p>
                  <ul className="pl-3 " style={{ listStyleType: "disc" }} >
                    {type?.sub_points?.length ? type.sub_points.map(res => {
                      return <li className="text-base font-normal py-2" style={{ color: "#676C80" }}> {res.description}</li>
                    }) : null}
                  </ul>
                </div>)
              })}
            </div>
          </div>
          <h4 className=" my-4 text-center" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }}>{surgeryData?.attributes?.thirdComp?.points[0]?.mainnotes}</h4>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.fourthComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#F0F7FF" }}
        >
          <div className="container mx-auto text-center">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {surgeryData?.attributes?.fourthComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }} >{surgeryData?.attributes?.fourthComp.subTitle}</h6>
            <div className="mb-[70px] md:mb-0 ">
              <Slider {...SurgerySlide}>
                {surgeryData?.attributes?.fourthComp?.points.map(
                  (cause, index) => (
                    <div className="px-3 mb-6 md:mb-0">
                      <div className="bg-White rounded-2xl  p-5">
                        <div
                          className="p-4 rounded-lg h-[200px] overflow-y-auto"
                          key={index}
                          style={{ backgroundColor: "#ffff" }}
                        >
                          <img
                            src={cause.icon_url} // You can dynamically change the icon if icon_url is available
                            // alt={cause.description}
                            className="pl-6 mb-4 align-left"
                          />
                          {cause?.title &&
                            //   <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal sm:mb-[50px] mb-[20px]">
                            //   <span className="text-secondaryColor">top rated surgeons</span>
                            // </h2>
                            <h1
                              className="pl-6 text-secondaryColor font-semibold text-left"
                              style={{ color: "#005D8D" }}
                            >
                              <span className="font-semibold">{cause.title}</span>
                            </h1>}
                          <p
                            className="pl-6 text-base font-medium text-left"
                            style={{ color: "#676C80" }}
                          >
                            {cause.description}
                          </p>
                          <ul className="pl-4 mt-4 text-left" style={{ listStyleType: "disc", color: "#004171" }}>
                            {cause?.subPoint1_title ? <li className="py-2 font-normal" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint1_title}</span>{cause?.subPoint1_description}</li> : null}
                            {cause?.subPoint2_title ? <li className="py-2" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint2_title}</span>{cause?.subPoint2_description}</li> : null}
                            {cause?.subPoint3_title ? <li className="py-2" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint3_title}</span>{cause?.subPoint3_description}</li> : null}
                            {cause?.subPoint4_title ? <li className="py-2" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint4_title}</span>{cause?.subPoint4_description}</li> : null}
                            {cause?.subPoint5_title ? <li className="py-2" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint5_title}</span>{cause?.subPoint5_description}</li> : null}
                            {cause?.subPoint6_title ? <li className="py-2" ><span className="font-semibold" style={{ color: "#004171" }} >{cause?.subPoint6_title}</span>{cause?.subPoint6_description}</li> : null}

                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            {surgeryData?.attributes?.fourthComp?.note ? <p className="text-center mt-8" style={{ color: "#676C80", fontSize: 16, fontWeight: 500 }} >{surgeryData?.attributes?.fourthComp?.note}</p> : null}
          </div>
        </div>
      ) : (
        <p></p>
      )
      }
      {
        surgeryData?.attributes?.fifthComp ? (
          <div className="bg-primaryColor text-white py-10 px-4 md:px-16 md:pb-24">
            <div className="container mx-auto">
              <div className="flex flex-col md:flex-row md:items-start">
                <div className="md:w-1/2 mb-6 md:mb-0">
                  <h2 className="text-xl md:text-2xl font-semibold md:mb-4 text-White mr-4">
                    {surgeryData?.attributes?.fifthComp.title}
                  </h2>
                </div>

                <div className="md:w-1/2">
                  <p className="text-sm md:text-base text-White mb-3 font-light">
                    {surgeryData?.attributes?.fifthComp.description}
                  </p>
                  {surgeryData?.attributes?.fifthComp.points &&
                    <ul style={{ listStyleType: "disc" }} >
                      {surgeryData?.attributes?.fifthComp.points.map(res => {
                        return <li className="text-base font-light mb-2" style={{ color: "#ffff" }}><span className="font-semibold" >{res?.title}</span> {res.description}</li>
                      })}
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p></p>
        )
      }
      {surgeryData?.attributes ? renderSurgeries(surgeryData?.attributes) : null}
      {/* 

      {surgeryData?.attributes?.benefits && (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="container mx-auto flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 text-left">
              <h2
                className="text-2xl md:text-3xl font-bold mb-8"
                style={{ color: "rgb(0, 93, 141)" }}
              >
                {surgeryData?.attributes?.benefits?.heading}
              </h2>

              <p
                className="text-lg text-gray-600 mb-6 mr-4"
                style={{ color: "rgb(0, 93, 141)" }}
              >
                {surgeryData?.attributes?.benefits?.description}
              </p>
            </div>

            <div className="w-full md:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 md:mt-0">
              {surgeryData?.attributes?.benefits?.benefitsSub?.map(
                (benefit, index) => (
                  <div
                    key={index}
                    className="bg-blue-50 p-4 rounded-lg shadow-md text-center"
                  >
                    {benefit.icon_url && (
                      <img
                        src={benefit.icon_url}
                        alt={benefit.label}
                        className="mx-auto mb-4"
                      />
                    )}
                    <p
                      className="text-sm md:text-base font-medium text-gray-700"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {benefit.label}
                    </p>
                    {benefit.Description && (
                      <p className="text-xs md:text-sm text-gray-600 mt-2">
                        {benefit.Description}
                      </p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {surgeryData?.attributes?.followUp ? (
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16 mb-10">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 mb-6 md:mb-0">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 text-White">
                  {renderHeadingWithHighlightedText(
                    surgeryData?.attributes?.followUp?.heading,
                    "Follow-Up Exams"
                  )}
                </h2>
              </div>

              <div className="md:w-1/2">
                <p className="text-base md:text-lg text-White">
                  {surgeryData?.attributes?.followUp?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes?.develop ? (
        <div className=" py-10 px-4 md:px-16" style={{ background: "#EBF8FF" }}>
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-blue-900"
              style={{ color: "rgba(0, 65, 113, 1)" }}
            >
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.develop[0]?.heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <p
              className="text-gray-700 text-base md:text-lg mb-6"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {surgeryData?.attributes?.develop[0]?.description}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {surgeryData?.attributes?.develop[0]?.icons?.map(
                (item, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow-md text-center"
                  >
                    <img
                      src={item.icon_url}
                      alt={`Icon ${index + 1}`}
                      className="mx-auto mb-4"
                    />
                    <p className="text-sm md:text-base font-medium text-gray-700">
                      Icon {index + 1} description here
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.doctor ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto text-center"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8">
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.doctor[0]?.Heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {surgeryData?.attributes?.doctor[0]?.doctor?.map(
                (doctor, index) => (
                  <div
                    key={doctor.id}
                    className="bg-white p-6 rounded-lg border-2 border-gray-300 shadow-lg"
                  >
                    <img
                      src={doctor.icon_url}
                      alt={`${doctor.title} icon`}
                      className="mb-4 w-16 h-16 object-cover mx-auto"
                    />
                    <h3
                      className="text-lg font-bold mb-4 text-center"
                      style={{ color: "rgb(0, 93, 141)" }}
                    >
                      {doctor.title}
                    </h3>
                    <p
                      className="text-left text-gray-700"
                      style={{ color: "#676C80" }}
                    >
                      {doctor.description}{" "}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes?.Preventcomp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto text-center"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8">
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.Preventcomp?.title,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {surgeryData.attributes.Preventcomp.title2.map((doctor) => (
                <div
                  key={doctor.id}
                  className="bg-white p-6 rounded-lg border-2 border-gray-300 shadow-lg"
                >
                  <img
                    src={doctor.name}
                    alt="Prevention Icon"
                    className="w-10 h-10 mx-auto"
                  />
                  <h3
                    className="text-lg  mb-4"
                    style={{ color: "rgba(0, 65, 113, 1)" }}
                  >
                    {doctor.descriptn}
                  </h3>

                  <p
                    className="text-left text-gray-700"
                    style={{ color: "#676C80" }}
                  >
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.typesofsur &&
      surgeryData.attributes.typesofsur.length > 0 ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#F0F7FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.typesofsur[0].Heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-12">
              {surgeryData.attributes.typesofsur[0].doctor?.map(
                (doc, index) => (
                  <div
                    className="flex items-start bg-white p-4 rounded-lg shadow-md"
                    key={index}
                  >
                    {doc.icon_url && (
                      <img
                        src={doc.icon_url}
                        alt={doc.title || "Doctor icon"}
                        className="w-8 h-8 mr-4 mt-1"
                      />
                    )}
                    <div>
                      <p
                        className="text-lg font-light text-gray-800"
                        style={{ color: "rgba(0, 65, 113, 1)" }}
                      >
                        {doc.title}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4"></p>
      )} */}
      <Modal
        title="Book Appointment"
        visible={openModal}
        width={"50%"}
        okText="Submit"
        centered={true}
        onOk={() => {
          setModal(false);
        }}
        cancelText="Cancel"
        className="commonModal"
        onCancel={handleCancel}
        footer={null}
      >
        <>
          <div className="flex">
            <div className="w-1/2 flex-col mr-2 hidden">
              <img
                style={{ height: "30%" }}
                className="m-auto"
                src={Surg_2}
                alt="#"
              />
              <div className="p-2 gap-4 space-y-3">
                <h1 className="font-bold text-2xl">Our next step</h1>
                <p>Check how we make your process easy :</p>

                <div className=" flex p-3 shadow-lg mt-4">
                  <div className="w-1/5">
                    <img src={Surg_3} alt="#" />
                  </div>
                  <div className="w-full">
                    <h1
                      classname=""
                      style={{ color: "#004171", fontWeight: "700" }}
                    >
                      Care Coordinator
                    </h1>
                    <p className="text-sm">
                      Share your details and our care coordinator will get in
                      touch with you.
                    </p>
                  </div>
                </div>

                <div className=" flex p-3 shadow-lg">
                  <div className="w-1/5 mt-2">
                    <img src={Surg_4} alt="#" />
                  </div>
                  <div className="w-full">
                    <h1 style={{ color: "#004171", fontWeight: "700" }}>
                      Detailed Evaluation
                    </h1>
                    <p className="text-sm">
                      Top notch doctors at your service who will make a detailed
                      evaluation of your health.
                    </p>
                  </div>
                </div>

                <div className=" flex  p-3 shadow-lg">
                  <div className="w-1/5 mt-2">
                    <img src={Surg_5} alt="#" />
                  </div>
                  <div className="w-full ml-2">
                    <h1
                      classname=""
                      style={{ color: "#004171", fontWeight: "700" }}
                    >
                      Schedule
                    </h1>
                    <p className="text-sm">
                      Appointment will be scheduled to the earliest at our
                      partner hospitals..
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="text-base text-textGray mb-5">
                Fill up the following details and we'll appoint you the best
                suitable surgeon
              </p>
              <Form
                // onChange={handleChange}
                form={form}
                onFinish={handleSubmit}
                className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
              >
                <div className="">
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Patient Name <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="patientName">
                    <Input
                      type="text"
                      placeholder="Name"
                      defaultValue={profile.firstName}
                      name="patientName"
                      required={true}
                      className=" h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                    />
                  </Form.Item>
                  {formerror.patientName && (
                    <span>{formerror.patientName}</span>
                  )}
                </div>
                <div className="">
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Email ID <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="patientEmail">
                    <Input
                      type="text"
                      placeholder="Mail ID"
                      defaultValue={profile.email}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      // name="mailId"
                      required={true}
                      className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                    />
                  </Form.Item>
                  {formerror.patientName && (
                    <span>{formerror.patientName}</span>
                  )}
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="mobileNo">
                    <InputNumber
                      name="mobileNo"
                      maxLength={10}
                      onKeyPress={handleKeyPress}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      required={true}
                      placeholder="Phone No."
                      defaultValue={profile.mobile}
                      className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                    // onInput={(e) =>
                    //   (e.target.value = e.target.value.slice(0, 10))
                    // }
                    />
                  </Form.Item>
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    City <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="city">
                    <Select
                      autocomplete="off"
                      id="city"
                      name="city"
                      // onChange={handleChange}
                      options={[
                        {
                          value: "Bhubaneswar",
                          label: "Bhubaneswar",
                        },
                        {
                          value: "Puri",
                          label: "Puri",
                        },
                        {
                          value: "Cuttack",
                          label: "Cuttack",
                        },
                      ]}
                      required={true}
                      optionLabel=""
                      placeholder="Select a City"
                      // defaultValue={profile.city}
                      className="h-[50px] rounded-md w-full text-sm"
                    />
                  </Form.Item>
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Select Disease <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="surgeryId">
                    <Select
                      autocomplete="off"
                      id="surgeryId"
                      name="surgeryId"
                      value={extractedNumber}
                      defaultValue={extractedNumber}
                      required={true}
                      options={[1].map((hc) => ({
                        value: extractedNumber,
                        label: surgeryName,
                        key: surgeryName,
                      }))}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionLabel=""
                      placeholder="Select Disease"
                      className="h-[50px] rounded-md w-full text-sm"
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className=" flex justify-center gap-3 md:gap-5 mt-8">
                <button
                  onClick={handleCancel}
                  className=" border border-primaryColor text-center text-primaryColor md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                  className="bg-primaryColor text-center text-White md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                >
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </Layouts >
  );
}

export default SurgeryDetails;