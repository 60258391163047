// 1. Install moment-timezone:
//    npm install moment-timezone

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import moment from "moment-timezone"; // Use moment-timezone
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import SampleCollectedGrey from '../../../../Assets/Images/Grey icon/Sample collected.svg';
import SampleCollectedGreen from '../../../../Assets/Images/Green icons/Sample collected.svg';
import SampleReadyGrey from '../../../../Assets/Images/Grey icon/sample ready to dispatch.svg';
import SampleReadyGreen from '../../../../Assets/Images/Green icons/sample ready to dispatch.svg';
import OnBusGrey from '../../../../Assets/Images/Grey icon/sample on the bus.svg';
import OnBusGreen from '../../../../Assets/Images/Green icons/sample on the bus.svg';
import DeliveredLabGrey from '../../../../Assets/Images/Grey icon/sample delivered to lab.svg';
import DeliveredLabGreen from '../../../../Assets/Images/Green icons/sample delivered to lab.svg';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed ? "#59d897" : '#eaeaf0',
  zIndex: 1,
  color: ownerState.completed ? "#00654D" : '#a1a1a1', 
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: ownerState.completed ? '0 4px 10px 0 rgba(89,216,151,.25)' : 'none',
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: (
      <img
        src={completed ? SampleCollectedGreen : SampleCollectedGrey}
        alt="Sample Collected"
        style={{ width: 35, height: 35 }}
      />
    ),
    2: (
      <img
        src={completed ? SampleReadyGreen : SampleReadyGrey}
        alt="Sample Ready to Dispatch"
        style={{ width: 35, height: 35 }}
      />
    ),
    3: (
      <img
        src={completed ? OnBusGreen : OnBusGrey}
        alt="Sample Shipped"
        style={{ width: 35, height: 35 }}
      />
    ),
    4: (
      <img
        src={completed ? OnBusGreen : OnBusGrey}
        alt="Sample on Bus"
        style={{ width: 35, height: 35 }}
      />
    ),
    5: (
      <img
        src={completed ? DeliveredLabGreen : DeliveredLabGrey}
        alt="Sample Delivered to Lab"
        style={{ width: 35, height: 35 }}
      />
    ),
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const initialSteps = [
  "Sample Collected",
  "Sample Ready to Dispatch",
  "Sample Shipped",
  "Sample on the Bus (In-Transit)",
  "Sample Delivered to Lab",
];

export default function LabOrderTracking({ trackingStatus }) {
  // Handle No Tracking Data Scenario
  if (!trackingStatus || !Array.isArray(trackingStatus) || trackingStatus.length === 0) {
    return (
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
          <Step>
            <StepLabel>No Tracking Data Available</StepLabel>
          </Step>
        </Stepper>
      </Stack>
    );
  }

  // Sort by reachedDate ascending
  const sortedStatus = [...trackingStatus].sort(
    (a, b) => new Date(a.reachedDate) - new Date(b.reachedDate)
  );

  // The active step is how many statuses have been reached
  const activeStep = sortedStatus.length;

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {initialSteps.map((label, index) => {
          const reachedDate = sortedStatus[index]?.reachedDate;
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                optional={
                  reachedDate ? (
                    <Typography variant="caption" color="textSecondary">
                      
                      {moment
                        .utc(reachedDate)
                        .tz("Asia/Kolkata")
                        .format("DD-MM-YYYY hh:mm A")}
                    </Typography>
                  ) : null
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
}

LabOrderTracking.propTypes = {
  trackingStatus: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      statusName: PropTypes.string.isRequired,
      reachedDate: PropTypes.string.isRequired,
      consignmentId: PropTypes.string, // Optional
    })
  ),
};
