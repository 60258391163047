import {
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Button,
  Upload,
 } from "antd";
 
 import { UploadOutlined } from "@ant-design/icons";
 import React, { useEffect, useState } from "react";
 import {
  useHistory,
  useParams,
 } from "react-router-dom/cjs/react-router-dom.min";
 import { useDispatch, useSelector } from "react-redux";
 import {
  getAllStates,
  getprocedurelist,
  saveEnquiry,
 } from "../../../Redux/Action/hospitalpageActions";
 import moment from "moment";
 import { DatePicker } from "antd";
 import dayjs from "dayjs";
 import { APP_ROUTES } from "../../PageRouting/AppRoutes";
 import FamilyDropdown from "../PostLogin/HealthRecords/FamilyDropDown";
 import { values } from "lodash";
 import http from "../../../Redux/Services/http-common";
 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";
 import { Modal } from "antd";
 import CurebayButton from "../../Shared/Button/PrimaryButton";
 
 function SurgeryEnquiry(props) {
  const history = useHistory();
  const params = useParams();
  const hospital_code = params.enquire;
  const parts = hospital_code.split("--");
  const hospitalCode = parts[parts.length - 1];
  const dispatch = useDispatch();
  const [stateList, setstateList] = useState([]);
  const { Option } = Select;
  //const patientData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const patientData =patientinfo?.patientinfoData;
  const procedurelist = useSelector((state) => state.procedurelist);
  const { procedurelistData } = procedurelist;
  const { RangePicker } = DatePicker;
 
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPatientDob, setSelectedPatientDob] = useState(null);
  const [selectedPatientMail, setSelectedPatientMail] = useState("");
  const [selectedPatientMobile, setSelectedPatientMobile] = useState("");
  const [selectedPatientAddress1, setSelectedPatientAddress1] = useState("");
  const [selectedPatientAddress2, setSelectedPatientAddress2] = useState("");
  const [selectedPatientState, setSelectedPatientState] = useState("");
  const [selectedPatientCity, setSelectedPatientCity] = useState("");
  const [profile, setProfile] = useState(patientData);
  const [loading, setLoading] = useState(false);
  const [prescription, setselectedprescription] = useState("");
  const [idProof, setselectedidproof] = useState("");
  const [idProoftype, setselectedidprooftype] = useState("");
  const [selectedreport, setSelectedReport] = useState("");
  const [selectedIdreport, setSelctedIdReport] = useState("");
  const [medicalReportType, setmedicalReportType] = useState("");
 
  const [openModalpopup, setOpenModalpopup] = useState(false);
  const [jsongetdata, setJsongetdata] = useState("");
 
  const form = props.form;
  const hospitaldatanew = props.hospitaldata;
  const hospitalname = hospitaldatanew;
 
  console.log(hospitalname.hospitalName, hospitalname, "hospitaldata");
 
  const dateObject = patientData?.dob
    ? dayjs(patientData.dob, "DD/MM/YYYY")
    : "";
  useEffect(() => {
    const dateObject = patientData?.dob
      ? dayjs(patientData.dob, "DD/MM/YYYY")
      : "";
  }, []);
 
  // useEffect(() => {
  //   const formattedDOB = dayjs(patientData.dob).format('DD/MM/YYYY');
 
  //   const updatedPatientData = {
  //     ...patientData,
  //     dob: formattedDOB
  //   };
  //   form.setFieldsValue(updatedPatientData);
  // }, [patientData]);
 
  // console.log("patientData..............................", patientData)
 
  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };
 
  useEffect(() => {
    dispatch(getprocedurelist());
    dispatch(getAllStates())
      .then((result) => {
        setstateList(result);
      })
      .catch((error) => {});
  }, []);
 
  useEffect(() => {
    if (selectedPatientDob) {
      form.setFieldsValue({ dob: dayjs(selectedPatientDob, "MM/DD/YYYY") });
    } else if (
      profile &&
      (selectedPatientDob === null ||
        selectedPatientDob === undefined ||
        selectedPatientDob === "")
    ) {
      form.setFieldsValue({ dob: "" });
    }
 
    if (selectedPatientMobile) {
      form.setFieldsValue({ mobile: selectedPatientMobile });
    } else if (
      profile &&
      (selectedPatientMobile === null ||
        selectedPatientMobile === undefined ||
        selectedPatientMobile === "")
    ) {
      form.setFieldsValue({ mobile: "" });
    }
 
    if (selectedPatientMail) {
      form.setFieldsValue({ email: selectedPatientMail });
    } else if (
      profile &&
      (selectedPatientMail === null ||
        selectedPatientMail === undefined ||
        selectedPatientMail === "")
    ) {
      form.setFieldsValue({ email: "" });
    }
 
    if (selectedPatientAddress1) {
      form.setFieldsValue({ address1: selectedPatientAddress1 });
    } else if (
      profile &&
      (selectedPatientAddress1 === null ||
        selectedPatientAddress1 === undefined ||
        selectedPatientAddress1 === "")
    ) {
      form.setFieldsValue({ address1: "" });
    }
 
    if (selectedPatientAddress2) {
      form.setFieldsValue({ address2: selectedPatientAddress2 });
    } else if (
      profile &&
      (selectedPatientAddress2 === null ||
        selectedPatientAddress2 === undefined ||
        selectedPatientAddress2 === "")
    ) {
      form.setFieldsValue({ address2: "" });
    }
 
    if (selectedPatientState) {
      form.setFieldsValue({ state: selectedPatientState });
    } else if (
      profile &&
      (selectedPatientState === null ||
        selectedPatientState === undefined ||
        selectedPatientState === "")
    ) {
      form.setFieldsValue({ state: "" });
    }
 
    if (selectedPatientCity) {
      form.setFieldsValue({ city: selectedPatientCity });
    } else if (
      profile &&
      (selectedPatientCity === null ||
        selectedPatientCity === undefined ||
        selectedPatientCity === "")
    ) {
      form.setFieldsValue({ city: "" });
    }
  }, [profile]);
 
  useEffect(() => {
    form.setFieldsValue({
      mobile: patientData?.mobile,
      email: patientData?.email,
      date: patientData?.date,
      gender: patientData?.gender,
      dob: dayjs(patientData.dob, "MM/DD/YYYY"),
      pinCode: patientData?.pinCode,
      address1: patientData?.address1,
      address2: patientData?.address2,
      state: patientData?.state,
      city: patientData?.city,
      country: patientData.country,
    });
    setProfile(patientData);
  }, []);
 
  const onChange = (date, dateString) => {
    if (date && typeof date.isValid === "function") {
      // Check if date.isValid is a function before converting
      const dayjsDate = dayjs(date);
 
      console.log(dayjsDate, dateString);
    } else {
      console.log("Invalid date selected");
    }
  };
  const [procedureName, setProcedureName] = useState();
  const [procedureId, setProcedureId] = useState();
  const handleProcedureChange = (value, option) => {
    console.log("Selected procedureId:", value);
    console.log("Selected procedureName:", option.props.children, value);
    // setProcedureId(value); // Assuming you have state for procedureId
    setProcedureName(option.props.children);
    setProcedureId(value);
  };
 
  const dobChange = (
    code,
    dob,
    email,
    mobile,
    address1,
    address2,
    state,
    city,
    selectedMember
  ) => {
    if (selectedMember?.code === patientData.code) {
      setSelectedPatientDob(patientData.dob);
      setSelectedPatientMail(patientData.email);
      setSelectedPatientMobile(patientData.mobile);
      setSelectedPatientAddress1(patientData.address1);
      setSelectedPatientAddress2(patientData.address2);
      setSelectedPatientState(patientData.state);
      setSelectedPatientCity(patientData.city);
      setProfile(patientData);
    } else {
      setSelectedPatientDob(dob);
      setSelectedPatientMail(email);
      setSelectedPatientMobile(mobile);
      setSelectedPatientAddress1(address1);
      setSelectedPatientAddress2(address2);
      setSelectedPatientState(state);
      setSelectedPatientCity(city);
      setProfile(selectedMember);
    }
  };
 
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      // dates is an array of [fromDate, toDate]
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };
 
  const handleDobDateChange = (event) => {
    const selectedDateValue = event.target.value;
    const dateComponents = selectedDateValue.split("-");
    const year = dateComponents[0];
    const month = dateComponents[1];
    const day = dateComponents[2];
    const formattedDate = `${month}/${day}/${year}`;
 
    setSelectedDate(formattedDate);
  };
  console.log(values.uploadFile, "dgghh");
 
  // const handleFormSubmit = () => {
  const handleFormSubmit = async () => {
    let values = form.getFieldValue();
 
    console.log(profile, "profile");
    console.log(
      // serviceTypenew,
      // serviceTypeName,
      // serviceSubTypeName,
      // serviceSubType,
      // hospitalizationtype,
      // hospitalizationtypename,
      procedureName,
      procedureId,
      "dfghjk"
    );
 
    // if (patientData.firstName === undefined || patientData.firstName === "") {
    if (values.firstName === undefined || values.firstName === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.date === undefined || patientData.date === "") {
    if (values.dob === undefined || values.dob === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.email === undefined || patientData.email === "") {
    //   if (values.email === undefined || values.email === "") {
    //     message.error("Please fill in all mandatory fields");
    //     return;
    //   }
    // }
 
    if (values.email === undefined || values.email === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    // if (patientData.mobile === undefined || patientData.mobile === "") {
    // if (values.mobile === undefined || values.mobile === "") {
    if (!values.mobile) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.mobile.toString().length !== 10) {
      message.error("Please fill the correct phone number");
      return;
    }
 
    // }
 
    // if (patientData.address1 === undefined || patientData.address1 === "") {
    if (values.address1 === undefined || values.address1 === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.address2 === undefined || patientData.address2 === "") {
    if (values.address2 === undefined || values.address2 === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.state === undefined || patientData.state === "") {
    if (values.state === undefined || values.state === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.city === undefined || patientData.city === "") {
    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.city === undefined || patientData.city === "") {
    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    // if (patientData.country === undefined || patientData.country === "") {
    if (values.country === undefined || values.country === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
    // }
 
    if (values.serviceType === undefined || values.serviceType === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.procedureName === undefined || values.procedureName === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (
      values.HospitalizationType === undefined ||
      values.HospitalizationType === ""
    ) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.servicesubType === undefined || values.servicesubType === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (fromDate === undefined || fromDate === "" || fromDate === null) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    // if (medicalReportType === undefined || medicalReportType === "") {
    //   message.error("Please fill in all mandatory fields");
    //   return;
    // }
    if (toDate === undefined || toDate === "" || toDate === null) {
      message.error("Please fill in all mandatory fields");
      return;
    }
    if (
      procedureId == 83 ||
      procedureId == 80 ||
      procedureId == 81 ||
      procedureId == 83 ||
      procedureId == 84 ||
      procedureId == 85 ||
      procedureId == 86 ||
      procedureId == 87 ||
      procedureId == 88 ||
      procedureId == 89 ||
      procedureId == 90 ||
      procedureId == 91 ||
      procedureId == 92 ||
      procedureId == 93 ||
      procedureId == 94
    ) {
      if (
        values.procedureNameDescription === undefined ||
        values.procedureNameDescription === ""
      ) {
        message.error("Please fill in all mandatory fields");
        return;
      }
    }
 
    if (!prescription.length) {
      message.error("Please fill in all mandatory fields");
      return;
    }
    setLoading(true);
    const payload = [
      {
        name: profile.firstName,
        dob: values.dob
          ? values.dob.format("MM/DD/YYYY")
          : profile.date.format("MM/DD/YYYY"),
        emailId: values.email ? values.email : profile.email,
        contactNumber: values.mobile
          ? parseInt(values.mobile, 10)
          : profile.mobile,
        address1: values.address1 ? values.address1 : profile.address1,
        address2: values.address2 ? values.address2 : profile.address2,
        city: values.city ? values.city : profile.city,
        state: values.state ? values.state : profile.state,
        country: values.country ? values.country : profile.country,
        nationality: values.nationality || "",
        insuranceName: values.insuranceName || "",
        insuranceId: values.insuranceId || "",
        insuranceAmount: values.insuranceAmount || "",
        procedureName: procedureName,
        procedureNameDescription: values.procedureNameDescription,
        procedureId: procedureId,
        passportNumber: values.passportNumber,
        notes: "",
        notes1: values.medicalcondition,
        patientId: profile.code,
        business: 0,
        preferredHospitalAndClinics: hospitalCode,
        serviceType: serviceTypenew,
        serviceTypeName: serviceTypeName,
        hospitalId: hospitalname.hospitalCode,
        hospitalName: hospitalname.hospitalName,
        // locationId: "LOC_CBP-16",
        status: 11,
        createdBy: profile?.code ? profile?.code : patientData?.code,
        modifiedBy: profile?.code ? profile?.code : patientData?.code,
        disableStatus: "N",
        activeServiceStatus: "N",
        idProof: idProof || null,
        idProofType: idProoftype || "",
        procedurePlannedStartDate: fromDate
          ? fromDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        procedurePlannedEndDate: toDate
          ? toDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        procedurePlannedDate: "",
        // locationName: "Bhubaneswar",
        sourceChannel: "B2C",
        medicalReport: prescription,
        medicalReportType: medicalReportType,
        serviceSubType: serviceSubTypeName,
        serviceSubTypeId: serviceSubType,
        hospitalizationTypeId: hospitalizationtype,
        // hospitalizationType: hospitalizationtypename,
        // CHEHospitalName: "Central eClinic - Bhubaneswar",
        CHEId: "CENTRALCLINIC",
        CHELocationId: "LOC_CENTRALECLINIC",
        // CHELocationName: "Bhubaneswar",
        // userRoleCode: "B2C",
        userName: hospitalname.userName,
        userMobile: hospitalname.userMobile,
        // userEmail: hospitalname.userEmail,
        userId: hospitalname.userCode,
      },
    ];
    // const payload = [
    //   {
    //     // name: values.firstName ? values.firstName : profile.firstName,
    //     name: profile.firstName,
    //     dob: values.dob
    //       ? values.dob.format("MM/DD/YYYY")
    //       : profile.date.format("MM/DD/YYYY"),
    //     emailId: values.email ? values.email : profile.email,
    //     contactNumber: values.mobile
    //       ? parseInt(values.mobile, 10)
    //       : profile.mobile,
    //     address1: values.address1 ? values.address1 : profile.address1,
    //     address2: values.address2 ? values.address2 : profile.address2,
    //     city: values.city ? values.city : profile.city,
    //     state: values.state ? values.state : profile.state,
    //     country: values.country ? values.country : profile.country,
    //     nationality: values.nationality,
    //     insuranceName: values.insuranceName,
    //     insuranceId: values.insuranceId,
    //     insuranceAmount: values.insuranceAmount,
 
    //     // subSubType: values.servicesubType,
 
    //     serviceSubTypeId: values.servicesubType,
    //     passportNumber: values.passportNumber,
    //     procedurePlannedDate: "",
    //     notes: "",
    //     notes1: values.medicalcondition,
    //     patientId: profile.code,
    //     business: 0,
    //     preferredHospitalAndClinics: hospitalCode,
    //     // procedureId,hospitalizationTypeId,serviceSubType,serviceType
 
    //     serviceType: serviceTypenew,
    //     serviceTypeName: serviceTypeName,
 
    //     serviceSubType: serviceSubType,
    //     serviceSubTypeId: serviceSubType,
 
    //     hospitalizationTypeId: hospitalizationtype,
    //     hospitalizationType: hospitalizationtypename,
 
    //     procedureId: procedureId,
    //     procedureName: procedureName || values.procedureNameOthers,
 
    //     //serviceSubType: serviceSubTypeName,
 
    //     hospitalId: hospitalname.hospitalCode,
    //     // hospitalId: "BP-15",
    //     hospitalName: hospitalname.hospitalName,
    //     // locationId: "LOC_CBP-16",
    //     status: 11,
    //     createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     modifiedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     fromDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     toDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     fixedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     createdBy: profile?.code ? profile?.code : patientData?.code,
    //     modifiedBy: profile?.code ? profile?.code : patientData?.code,
    //     locationId: profile?.code ? profile?.code : patientData?.code,
    //     procedurePlannedStartDate: fromDate
    //       ? fromDate.format("YYYY-MM-DD HH:mm:ss")
    //       : null,
    //     procedurePlannedEndDate: toDate
    //       ? toDate.format("YYYY-MM-DD HH:mm:ss")
    //       : null,
    //     idProof: idProof,
    //     idProofType: idProoftype,
    //     medicalReport: prescription,
    //     medicalReportType: medicalReportType,
    //   },
    // ];
    const Payloadnew = [
      {
        patientId: profile?.code,
        procedureId: procedureId,
        hospitalId: hospitalname.hospitalCode,
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      },
    ];
 
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientServiceEnquiryFormValidation/`,
      Payloadnew
    );
    console.log(payload, "payloaddataa");
 
    if (postResponse.data[0].message == "No Patient service enquiry") {
      dispatch(saveEnquiry(payload))
        .then((res) => {
          message.success("Enquiry Form submitted");
          setLoading(false);
          form.resetFields();
          setFromDate("");
          setToDate("");
          setSelctedIdReport("");
          setSelectedReport("");
          history.push({ pathname: APP_ROUTES.MYREQEUSTS });
          window.scrollTo({ top: 0, behavior: "smooth" });
          props.onClose();
        })
        .catch((error) => {
          // Handle error
          setLoading(false);
          console.error("Error submitting form:", error);
        });
 
      console.log(postResponse.data[0].message, "postResponse");
    } else {
      // message.error(postResponse.data[0].message);
      // toast(postResponse.data[0].message);
      setLoading(false);
      setJsongetdata(postResponse.data[0].message);
      setOpenModalpopup(true);
    }
  };
 
  const handleFormSubmitnew = async () => {
    let values = form.getFieldValue();
 
    if (values.firstName === undefined || values.firstName === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.dob === undefined || values.dob === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.email === undefined || values.email === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (!values.mobile) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.mobile.toString().length !== 10) {
      message.error("Please fill the correct phone number");
      return;
    }
 
    if (values.address1 === undefined || values.address1 === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.address2 === undefined || values.address2 === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.state === undefined || values.state === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.country === undefined || values.country === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.serviceType === undefined || values.serviceType === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.procedureName === undefined || values.procedureName === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (
      values.HospitalizationType === undefined ||
      values.HospitalizationType === ""
    ) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (values.servicesubType === undefined || values.servicesubType === "") {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (fromDate === undefined || fromDate === "" || fromDate === null) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (toDate === undefined || toDate === "" || toDate === null) {
      message.error("Please fill in all mandatory fields");
      return;
    }
 
    if (!prescription.length) {
      message.error("Please fill in all mandatory fields");
      return;
    }
    if (
      procedureId == 83 ||
      procedureId == 80 ||
      procedureId == 81 ||
      procedureId == 83 ||
      procedureId == 84 ||
      procedureId == 85 ||
      procedureId == 86 ||
      procedureId == 87 ||
      procedureId == 88 ||
      procedureId == 89 ||
      procedureId == 90 ||
      procedureId == 91 ||
      procedureId == 92 ||
      procedureId == 93 ||
      procedureId == 94
    ) {
      if (
        values.procedureNameDescription === undefined ||
        values.procedureNameDescription === ""
      ) {
        message.error("Please fill in all mandatory fields");
        return;
      }
    }
    const payload = [
      {
        name: profile.firstName,
        dob: values.dob
          ? values.dob.format("MM/DD/YYYY")
          : profile.date.format("MM/DD/YYYY"),
        emailId: values.email ? values.email : profile.email,
        contactNumber: values.mobile
          ? parseInt(values.mobile, 10)
          : profile.mobile,
        address1: values.address1 ? values.address1 : profile.address1,
        address2: values.address2 ? values.address2 : profile.address2,
        city: values.city ? values.city : profile.city,
        state: values.state ? values.state : profile.state,
        country: values.country ? values.country : profile.country,
        nationality: values.nationality || "",
        insuranceName: values.insuranceName || "",
        insuranceId: values.insuranceId || "",
        insuranceAmount: values.insuranceAmount || "",
        procedureName: procedureName,
        procedureNameDescription: values.procedureNameDescription,
        procedureId: procedureId,
        passportNumber: values.passportNumber,
        notes: "",
        notes1: values.medicalcondition,
        patientId: profile.code,
        business: 0,
        preferredHospitalAndClinics: hospitalCode,
        serviceType: serviceTypenew,
        serviceTypeName: serviceTypeName,
        hospitalId: hospitalname.hospitalCode,
        hospitalName: hospitalname.hospitalName,
        // locationId: "LOC_CBP-16",
        status: 11,
        createdBy: profile?.code ? profile?.code : patientData?.code,
        modifiedBy: profile?.code ? profile?.code : patientData?.code,
        disableStatus: "N",
        activeServiceStatus: "N",
        idProof: idProof || null,
        idProofType: idProoftype || "",
        procedurePlannedStartDate: fromDate
          ? fromDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        procedurePlannedEndDate: toDate
          ? toDate.format("YYYY-MM-DD HH:mm:ss")
          : null,
        procedurePlannedDate: "",
        // locationName: "Bhubaneswar",
        sourceChannel: "B2C",
        medicalReport: prescription,
        medicalReportType: medicalReportType,
        serviceSubType: serviceSubTypeName,
        serviceSubTypeId: serviceSubType,
        hospitalizationTypeId: hospitalizationtype,
        // hospitalizationType: hospitalizationtypename,
        // CHEHospitalName: "Central eClinic - Bhubaneswar",
        CHEId: "CENTRALCLINIC",
        CHELocationId: "LOC_CENTRALECLINIC",
        // CHELocationName: "Bhubaneswar",
        // userRoleCode: "B2C",
        userName: hospitalname.userName,
        userMobile: hospitalname.userMobile,
        // userEmail: hospitalname.userEmail,
        userId: hospitalname.userCode,
      },
    ];
    setLoading(true);
    dispatch(saveEnquiry(payload))
      .then((res) => {
        message.success("Enquiry Form submitted");
        setLoading(false);
        form.resetFields();
        setFromDate("");
        setToDate("");
        setSelctedIdReport("");
        setSelectedReport("");
        history.push({ pathname: APP_ROUTES.MYREQEUSTS });
        window.scrollTo({ top: 0, behavior: "smooth" });
        props.onClose();
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
        console.error("Error submitting form:", error);
      });
  };
 
  const handleCancel = () => {
    form.resetFields();
    props.onClose();
  };
  const handleCancelmodal = () => {
    setOpenModalpopup(false);
  };
 
  // const changeHandler = async (file) => {
  //   const name= file ? file.name :"";
  //   setSelectedReport(name)
  //   if (
  //     !file?.name.includes(".png") &&
  //     !file?.name.includes(".jpg") &&
  //     !file?.name.includes(".jpeg") &&
  //     !file?.name.includes(".pdf")
  //   ) {
  //     message.error("This file is not supported, Please choose an image");
  //     return;
  //   }
  //   let b64File = await encodeBase64File(file);
  //   setselectedprescription(b64File);
 
  // };
 
  const changeHandler = async (info) => {
    //  if (info.file.status === 'done') {
    // Extract the file name from the uploaded file object
 
    const name = info.file.name;
    const fileType = name.split(".").pop().toLowerCase();
    setSelectedReport(name);
    setmedicalReportType(fileType);
    console.log(info, "infio");
    let b64File = await encodeBase64File(info.file.originFileObj);
    setselectedprescription(b64File);
    console.log(b64File, "infi");
    //   } else if (info.file.status === 'error') {
    //  message.error(`${info.file.name} file upload failed.`);     }
  };
 
  const changeHandlernew = async (info) => {
    console.log(info, "rghj");
    const name = info.file.name;
 
    const fileType = name.split(".").pop().toLowerCase(); // Extract file extension
    setSelctedIdReport(name);
    setselectedidprooftype(fileType);
    // message.success(`${info.file.name} file uploaded successfully`);
 
    let b64File = await encodeBase64Filenew(info.file.originFileObj);
    setselectedidproof(b64File);
  };
  // const changeHandlernew = async (file) => {
  //   if (
  //     !file?.name.includes(".png") &&
  //     !file?.name.includes(".jpg") &&
  //     !file?.name.includes(".jpeg") &&
  //     !file?.name.includes(".pdf")
  //   ) {
  //     message.error("This file is not supported, Please choose an image");
  //     return;
  //   }
  //   let b64File = await encodeBase64Filenew(file);
  //   setselectedidproof(b64File);
  // };
 
  const encodeBase64File = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        // setIdproofvalue(baseURL);
        // console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(",")[1]);
      };
      console.log(fileInfo);
    });
  };
  const encodeBase64Filenew = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        // setPrescriptionValue(baseURL);
        // console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(",")[1]);
      };
      console.log(fileInfo);
    });
  };
  const openImageInNewTab = () => {
    if (prescription && medicalReportType) {
      if (medicalReportType === "pdf") {
        // For PDF files, open in a new tab
        const newTab = window.open();
        newTab.document.body.innerHTML = `<embed src="data:application/pdf;base64, ${prescription}" style="width:100%; height:100%;" />`;
      } else if (
        medicalReportType === "png" ||
        medicalReportType === "jpg" ||
        medicalReportType === "jpeg"
      ) {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<img src="data:image/${medicalReportType};base64, ${prescription}" style="max-width:100%;" />`;
      }
    } else {
      message.error("No image available to display.");
    }
  };
  const openImageInNewTabidproof = () => {
    if (idProof && idProoftype) {
      if (idProoftype === "pdf") {
        // For PDF files, open in a new tab
        const newTab = window.open();
        newTab.document.body.innerHTML = `<embed src="data:application/pdf;base64, ${idProof}" style="width:100%; height:100%;" />`;
      } else if (
        idProoftype === "png" ||
        idProoftype === "jpg" ||
        idProoftype === "jpeg"
      ) {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<img src="data:image/${idProoftype};base64, ${idProof}" style="max-width:100%;" />`;
      }
    } else {
      message.error("No image available to display.");
    }
  };
  const [serviceTypenew, setServiceType] = useState(); // Default value
  const [serviceTypeName, setServiceTypeName] = useState();
 
  const [serviceSubType, setServiceSubType] = useState(); // Default value
  const [serviceSubTypeName, setServiceSubTypeName] = useState();
 
  const [hospitalizationtype, setHospitalizationType] = useState();
  const [hospitalizationtypename, setHospitalizationTypename] = useState();
  const [hospitalizationData, setHospitalizationData] = useState([]);
 
  const handleServiceTypeChange = (valueObj, option) => {
    console.log("Selectedhijji", valueObj, option.props.name);
    setServiceType(valueObj);
    setServiceTypeName(option.props.name);
  };
 
  const handleServiceSubTypeChange = (valueObj, option) => {
    // You can set both value and name to state or perform any other logic here
    setServiceSubType(valueObj);
    setServiceSubTypeName(option.props.name); // Assuming setServiceSubTypeName is the setter for the service subtype name state
  };
 
  const handleHospitalizationTypeChange = (valueObj, option) => {
    setHospitalizationType(valueObj); // Update serviceType state
    setHospitalizationTypename(option.props.name);
    if (valueObj == "12") {
      setProcedureName();
      setProcedureId();
    }
 
    // gethospitalizationsubtype(value);
  };
 
  // async function gethospitalizationsubtype(value) {
  //   let response = await http.get(
  //     `${process.env.REACT_APP_NEWBASEURL}hospitalizationServiceType/list/filter?serviceTypeId=${serviceTypenew}&hospitalizationTypeId=${value}&status=1`
  //   );
  //   console.log(response.data, "dsvldsbvsdbvoubdsv");
  //   setHospitalizationData(response.data);
  // }
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(
          `${process.env.REACT_APP_NEWBASEURL}hospitalizationServiceType/list/filter?serviceTypeId=${serviceTypenew}&hospitalizationTypeId=${hospitalizationtype}&status=1`
        );
        if (response.data.status == 200) {
          setHospitalizationData(response.data.data);
        } else {
          setHospitalizationData([]);
        } // Assuming response.data is an object with a 'data' property containing the array
      } catch (error) {
        console.error("Error fetching hospitalization data:", error);
      }
    };
    fetchData();
  }, [serviceTypenew, hospitalizationtype]);
 
  const renderHospitalizationOptions = () => {
    console.log(serviceTypenew, "rdtfcghjkl");
    if (serviceTypenew === 8) {
      // IPD
      return (
        <>
          <Select.Option value={10} name="Opthalmology">
            Opthalmology
          </Select.Option>
          <Select.Option value={8} name="Gynecology">
            Gynecology
          </Select.Option>
          <Select.Option value={1} name="General Surgery">
            General Surgery
          </Select.Option>
          <Select.Option value={5} name="Urology">
            Urology
          </Select.Option>
          <Select.Option value={7} name="ENT">
            ENT
          </Select.Option>
          <Select.Option value={4} name="Orthopaedic">
            Orthopaedic
          </Select.Option>
          <Select.Option value={2} name="Oncology">
            Oncology
          </Select.Option>
          <Select.Option value={6} name="Cardiology">
            Cardiology
          </Select.Option>
          <Select.Option value={3} name="Neurology">
            Neurology
          </Select.Option>
          <Select.Option value={15} name="Nephrology">
            Nephrology
          </Select.Option>
          <Select.Option value={11} name="General Medicine">
            General Medicine
          </Select.Option>
          <Select.Option value={9} name="Gastrologic">
            Gastrologic
          </Select.Option>
        </>
      );
    } else if (serviceTypenew === 9) {
      return (
        <>
          <Select.Option value={12} name="OPD Others">
            OPD Others
          </Select.Option>
        </>
      );
    } else if (serviceTypenew === 10) {
      return (
        <>
          <Select.Option value={13} name="Investigation Others">
            Investigation Others
          </Select.Option>
        </>
      );
    } else if (serviceTypenew === 11) {
      return (
        <>
          <Select.Option value={14} name="Day Care Others">
            Day Care Others
          </Select.Option>
        </>
      );
    } else {
      return null;
    }
  };
 
  return (
    <div>
      <ToastContainer />
      <div className="bg-White rounded-xl w-full">
        <Form
          initialValues={{
            salutation: patientData?.salutation,
            firstName: patientData?.firstName,
            lastName: patientData?.lastName,
            mobile: patientData?.mobile,
            email: patientData?.email,
            date: patientData?.date,
            gender: patientData?.gender,
            dob: patientData
              ? dayjs(patientData.dob, "MM/DD/YYYY")
              : new Date(),
            pinCode: patientData?.pinCode,
            address1: patientData?.address1,
            address2: patientData?.address2,
            state: patientData?.state,
            city: patientData?.city,
          }}
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-5"
          form={form}
          onFinish={handleFormSubmit}
        >
          <Form.Item
            className="mb-0"
            name="firstName"
            label="Name of Patient *"
          >
            {/* <Input placeholder="Name" /> */}
 
            <FamilyDropdown onSelect={dobChange} />
          </Form.Item>
          <Form.Item className="mb-0" name="dob" label="DOB *">
            {/* <input
            defaultValue={dob}
              type="date"
              id="dob"
              name="dob"
              placeholder="Dob"
              pattern="\d{2}/\d{2}/\d{4}"
              onChange={handleDobDateChange}
              className="w-full  px-3 border border-gray rounded-md h-[50px]" // Apply your custom CSS class
            /> */}
            {/* <DatePicker onChange={handleDobDateChange} /> */}
            <DatePicker
              placeholder="Please Select Your Date of Birth"
              format="MM/DD/YYYY"
              // disabledDate={disabledDate}
              className="w-full"
              disabled={true} 
              // value={patientData?.dob ? patientData.dob : ""}
              // onChange={changeDate}
            />
          </Form.Item>
          <Form.Item className="mb-0" name="email" label="Email ID *">
            <Input placeholder="Email" />
          </Form.Item>
 
          <Form.Item className="mb-0" name="mobile" label="Phone Number *">
            <InputNumber
              name="mobile"
              placeholder="Phone Number"
              onKeyPress={handleKeyPress}
              maxLength={10}
              className="custom-input-number"
            />
          </Form.Item>
          <Form.Item className="mb-0" name="address1" label="Address 1 *">
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item className="mb-0" name="address2" label="Address 2 ">
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item className="mb-0" name="state" label="State *">
            <Select
              placeholder="State"
              showSearch
              options={stateList.map((hc) => ({
                value: hc.code,
                value1: hc.description,
                label: hc.description,
                key: hc.code,
              }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item className="mb-0" name="city" label="City *">
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item className="mb-0" name="country" label="Country *">
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item className="mb-0" name="serviceType" label="Service Type *">
            <Select
              placeholder="Service Type"
              optionFilterProp="children"
              onChange={handleServiceTypeChange}
            >
              <Select.Option value={8} name="IPD">
                IPD
              </Select.Option>
              <Select.Option value={9} name="OPD">
                OPD
              </Select.Option>
              <Select.Option value={10} name="Investigation">
                Investigation
              </Select.Option>
              <Select.Option value={11} name="Day Care">
                Day Care
              </Select.Option>
            </Select>
          </Form.Item>
 
          {/* <Form.Item
            className="mb-0"
            name="servicesubType"
            label="Service Subtype *"
          >
            <Select placeholder="Service Subtype" optionFilterProp="children">
              <Select.Option value={1}>Emergency</Select.Option>
              <Select.Option value={2}>Non-Emergency</Select.Option>
              <Select.Option value={3}>Others</Select.Option>
            </Select>
          </Form.Item> */}
 
          <Form.Item
            className="mb-0"
            name="servicesubType"
            label="Service Subtype *"
          >
            <Select
              placeholder="Service Subtype"
              optionFilterProp="children"
              onChange={handleServiceSubTypeChange} // Updated event handler
            >
              <Select.Option value={1} name="Emergency">
                Emergency
              </Select.Option>
              <Select.Option value={2} name="Non-Emergency">
                Non-Emergency
              </Select.Option>
              <Select.Option value={3} name="Others">
                Others
              </Select.Option>
            </Select>
          </Form.Item>
 
          <Form.Item
            className="mb-0"
            name="HospitalizationType"
            label="Hospitalization Type *"
          >
            <Select
              placeholder="Hospitalization Type"
              optionFilterProp="children"
              onChange={handleHospitalizationTypeChange}
            >
              {renderHospitalizationOptions()}
            </Select>
          </Form.Item>
 
          <Form.Item
            className="mb-0"
            name="procedureName"
            label="Hospitalization Subtype *"
          >
            <Select onChange={handleProcedureChange}>
              {/* {procedurelistData.map((option) => (
                <Option key={option.procedureId} value={option.procedureId}>
                  {option.procedureName}
                </Option>
              ))} */}
              {hospitalizationData &&
                hospitalizationData.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.hospitalizationSubTypeId}
                  >
                    {item.hospitalizationSubType}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
 
          {procedureId == 83 ||
          procedureId == 80 ||
          procedureId == 81 ||
          procedureId == 83 ||
          procedureId == 84 ||
          procedureId == 85 ||
          procedureId == 86 ||
          procedureId == 87 ||
          procedureId == 88 ||
          procedureId == 89 ||
          procedureId == 90 ||
          procedureId == 91 ||
          procedureId == 92 ||
          procedureId == 93 ||
          procedureId == 94 ? (
            // Assuming hospitalizationType is the state variable
            <Form.Item
              className="mb-0"
              name="procedureNameDescription"
              label="Hospitalization Sub Type Description *"
            >
              <Input placeholder="Hospitalization Type" />
            </Form.Item>
          ) : (
            ""
          )}
 
          <Form.Item
            name=""
            className="mb-0"
            style={{ width: "max-content" }}
            label="Procedure Planned From & To Date *"
          >
            <RangePicker className="w-[78%]" onChange={handleDateChange} />
          </Form.Item>
          <Form.Item className="mb-0" name="nationality" label="Nationality">
            <Select
              placeholder="Nationality"
              optionFilterProp="children"
              options={[
                {
                  value: "Indian",
                  label: "Indian",
                },
                {
                  value: "Others",
                  label: "Others",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="insuranceName"
            label="Insurance Name"
          >
            <Input placeholder="Insurance Name" />
          </Form.Item>
          <Form.Item className="mb-0" name="insuranceId" label="Insurance ID">
            <Input
              placeholder="Insurance ID"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
 
          <Form.Item
            className="mb-0"
            name="insuranceAmount"
            label="Insurance Amount"
            
          >
            <Input placeholder="Insurance Amount" 
             onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="passportNumber"
            label="Passport Number"
          >
            <Input placeholder="Passport Number" 
             onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="medicalcondition"
            label="Any other medical conditions?"
          >
            <Input placeholder="medical conditions" />
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="uploadFile"
            label="Upload medical Report *"
          >
            {/* <Input placeholder="medication conditions" /> */}
 
            <Upload
              accept=".pdf, .png, .jpg, .jpeg,"
              onChange={changeHandler}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} type="primary">
                Upload medical Report
              </Button>
            </Upload>
 
            {selectedreport && (
              <div
                className=" font-bold text-base mt-2 cursor-pointer text-danger"
                onClick={openImageInNewTab}
              >
                {selectedreport}
              </div>
            )}
          </Form.Item>
          <Form.Item className="mb-0" name="idproof" label="Upload ID Proof">
            {/* <Input placeholder="medication conditions" /> */}
 
            <Upload
              accept=".pdf, .png, .jpg, .jpeg,"
              onChange={changeHandlernew}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} type="primary">
                Upload ID Proof
              </Button>
            </Upload>
 
            {selectedIdreport && (
              <div
                className=" font-bold text-base mt-2 cursor-pointer text-danger"
                onClick={openImageInNewTabidproof}
              >
                {selectedIdreport}
              </div>
            )}
 
            {/* <Input placeholder="Upload ID Proof"
                       id="uploadFile"
                       name="uploadFile"
                       type="file"
                       accept=".pdf, .png, .jpg, .jpeg,"
                       onChange={(e) => {
                         changeHandlernew(e.target.files[0]);
                       }}
                       /> */}
          </Form.Item>
        </Form>
        <div className="flex items-center w-full my-8">
          <CurebayButton
            variant="outlined"
            className="w-full mr-2 bg-white outline-1"
            // color="primary"
            onClick={handleCancel}
            size="large"
            type="primary"
          >
            Cancel
          </CurebayButton>
          {/* <button
            className="w-full border border-primaryColor p-3 mx-2 rounded-md text-primaryColor font-medium"
            
          >
            
          </button> */}
          <CurebayButton
            loading={loading}
            size="large"
            className="w-full"
            type="primary"
            onClick={handleFormSubmit}
          >
            Submit
          </CurebayButton>
        </div>
      </div>
      <Modal
        title="Surgery Enquiry Form"
        className="commonModal"
        open={openModalpopup}
        onOk={() => {
          setOpenModalpopup(false);
        }}
        centered={true}
        footer={null}
        onCancel={() => {
          handleCancelmodal();
        }}
        okText="Submit"
        cancelText="Cancel"
        width="40%"
        maskClosable={false}
      >
        <div>
          <h1>{jsongetdata}. Would you like to make another enquiry?</h1>
          <div className="flex items-center w-full my-8">
            <CurebayButton
              loading={loading}
              className=" w-full"
              type="primary"
              onClick={handleFormSubmitnew}
              size="large"
            >
              Yes
            </CurebayButton>
            <button
              className="w-full border border-primaryColor py-2 px-3 mx-2 rounded-md text-primaryColor font-medium"
              onClick={() => {
                handleCancel();
                handleCancelmodal();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
 }
 
 export default SurgeryEnquiry;