import React, { useState, useEffect } from "react";

const ResendOtp = ({ timerDuration = 30, onResendOtp }) => {
  const [timer, setTimer] = useState(timerDuration); // Countdown timer state
  const [isResendEnabled, setIsResendEnabled] = useState(false); // Resend button state

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Decrease timer
      }, 1000);
    } else {
      setIsResendEnabled(true); // Enable resend button when timer expires
    }

    return () => clearInterval(interval); // Cleanup timer on component unmount
  }, [timer]);

  const handleResendClick = (e) => {
    setIsResendEnabled(false); // Disable button
    setTimer(timerDuration); // Reset timer
    if (onResendOtp) {
      onResendOtp(e); // Trigger OTP resend callback
    }
  };

  return (
    <div style={styles.container}>
      <p style={styles.infoText}>
        {isResendEnabled
          ? "Didn't receive the OTP?"
          : `You can resend OTP in ${timer} seconds`}
      </p>
      <button
        style={{
          ...styles.button,
          color: isResendEnabled ? "#007BFF" : "#D3D3D3",
          cursor: isResendEnabled ? "pointer" : "not-allowed",
        }}
        onClick={handleResendClick}
        disabled={!isResendEnabled}
      >
        Resend OTP
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "right",
    // margin: "20px 0",
  },
  infoText: {
    marginTop: "10px",
    fontSize: "14px",
    color: "#555",
  },
  button: {
    padding: "2px 2px",
    textDecoration: "underline",
    fontSize: "14px",
    border: "none",
    borderRadius: "5px",
    outline: "none",
  },
};

export default ResendOtp;
