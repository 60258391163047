import React, { useState, useRef } from "react";
import DiscountIcon from "../../../Assets/Images/Cart/discountIcon.jpg";
import { useEffect } from "react";
import http from "../../../Redux/Services/http-common";
import { Skeleton } from "primereact/skeleton";

import { useHistory } from "react-router-dom";
import { applyCouponCode, getCouponList } from "./cartApi/couponapis";
import { useDispatch, useSelector } from "react-redux";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import { message, Modal, Spin, Input } from "antd";
import Loader from "../../Shared/loader";
import { CopyOutlined } from "@ant-design/icons";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import InfoDetailsCorporate from "./infoCorporateDiscount";
import "../../../index.css";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from 'primereact/tooltip';


function CartPrice(props) {
  const [messageApi, contextHolder] = message.useMessage();

  const { cartData, patient, readOnly = false, buttonText } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [verify, updateVerify] = useState("");
  const [tId, setTId] = useState("");
  const userData = useSelector((state) => state.authReducer.patientData);
  const [discount, updateDiscount] = useState(0);
  const [isShowCouponList, setShowCouponList] = useState(false);
  const [medicineCheckbox, setMedicineCheckbox] = useState(false);
  const [isCouponApply, setCouponApply] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [appliedCouponsList, setAppliedCouponsList] = useState([]);
  const [selectedCouponCode, setselectedCouponCode] = useState(null);
  const [medicineTotalAmount, setMedicineTotalAmount] = useState("0");
  const [firstTime, setFirstTime] = useState(true);
  const [isCouponAnEmail, setIsCouponAnEmail] = useState(false);
  const [medicineDiscountAmount, setMedicineTotalDiscountAmount] =
    useState("0");
  const [labTotalAmount, setLabTotalAmount] = useState("0");
  const [labDiscountAmount, setLabTotalDiscountAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingForCoupon, setLoadingForCoupon] = useState(false);
  const [couponVerify, setCouponVerify] = useState("");
  const [otp, setOtp] = useState("");
  const [isMedMemberShipAreadyApplied, setIsMedMemberShipAreadyApplied] =
    useState(0);
  const [isLabMemberShipAreadyApplied, setIsLabMemberShipAreadyApplied] =
    useState(0);
  const [checkingMembership, setCheckingMembership] = useState(false);
  const [medMembershipData, setMedMembershipData] = useState({});
  const [labMembershipData, setLabMembershipData] = useState({});
  const [userFromMedicare, setUserFromMedicare] = useState(false);
  const [showOtpUI, setShowOtpUI] = useState(false);
  const [flagOtp,setFlagotp] =useState(false)
  const [medmembershipError, setMedMembershipError] = useState({
    error: "",
    loading: false,
  });
  const [labmembershipError, setLabMembershipError] = useState({
    error: "",
    loading: false,
  });
  // const [cartData,setCartData] = useState({})
  const titles = ["Medicines", "Pathology", "Radiology"];
  const content = (
    <>
      {titles.map((item) => (
        <InfoDetailsCorporate key={item} title={item} />
      ))}
    </>
  );
  const medmembershipref = useRef(null);

  const hideShow = () => {
    if (isMedMemberShipAreadyApplied > 0) {
      return;
    }
    if (isShowCouponList) {
      setShowCouponList(false);
    } else {
      setShowCouponList(true);
    }
  };

  useEffect(() => {
    checkIfCouponAppled();
    checkIfMembershipAlreadyApplied();
  }, [cartData]);

  // const handlegetCartData = () =>{
  //   getCartData(userData.code).then((res) => {
  //     if (res.data?.length) {
  //       setCartData(res.data[0]);
  //     } else {
  //     }
  //     console.log(res.data[0], "sdvudsgvuidsgviudsgvds");
  //   });
  // }

  useEffect(() => {
    setCheckingMembership(true);
    http
      .get(
        `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${patient.code}&source=B2C`
      )
      .then((res) => {
        if (res.data.data.length && res.data.data == "Medicare") {
          setUserFromMedicare(true);
        } else if (res.data.data.length) {
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_PHAR_ORDER`
            )
            .then((res) => {
              if (cartData?.patientMedicineOrder?.length > 0) {
                if (res.data.data.length) {
                  setMedMembershipData(res.data.data[0]);
                }
              } else {
                setMedMembershipData({
                  membershipCard: 0,
                });
              }
              setCheckingMembership(false);
            })
            .catch((err) => {
              setCheckingMembership(false);
              setMedMembershipData({
                membershipCard: 0,
              });
            });
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_DIA_ORDER`
            )
            .then((res) => {
              if (cartData?.patientLabTestsOrder?.length > 0) {
                if (res.data.data.length) {
                  setLabMembershipData(res.data.data[0]);
                }
              } else {
                setLabMembershipData({
                  membershipCard: 0,
                });
              }
              setCheckingMembership(false);
            })
            .catch((err) => {
              setLabMembershipData({
                membershipCard: 0,
              });
              setCheckingMembership(false);
            });
        }

        setCheckingMembership(false);
      })
      .catch((err) => {
        setCheckingMembership(false);
        setMedMembershipData({
          membershipCard: 0,
        });
        setLabMembershipData({
          membershipCard: 0,
        });
      });
  }, [cartData, patient]);

  const checkIfMembershipAlreadyApplied = () => {
    if (!cartData) return;
    let id = "";
    if (cartData?.patientMedicineOrder?.length) {
      id = cartData.patientMedicineOrder[0]?.membershipId;
    }
    if (cartData?.patientLabTestsOrder?.length) {
      if (id < 2 || id == null) {
        id = cartData.patientLabTestsOrder[0]?.membershipId;
      }
    }

    setIsMedMemberShipAreadyApplied(id);
  };

  const checkIfCouponAppled = () => {
    if (!cartData) return;
    let appliedCoupons = [];
    let medicineTotalAmount = 0;
    let medicineTotalDiscountedAmount = 0;
    let labTotalAmount = 0;
    let labTotalDiscountedAmount = 0;
    if (cartData.patientMedicineOrder?.length) {
      if (cartData.patientMedicineOrder[0].voucheryCode) {
        appliedCoupons.push(cartData.patientMedicineOrder[0].voucheryCode);
      }
      medicineTotalAmount = parseFloat(cartData.patientMedicineOrder[0].amount);
      medicineTotalDiscountedAmount = parseFloat(
        cartData.patientMedicineOrder[0].totalAmount
      );
    }
    if (cartData.patientLabTestsOrder?.length) {
      if (cartData.patientLabTestsOrder[0].voucherCode) {
        appliedCoupons.push(cartData.patientLabTestsOrder[0].voucherCode);
      }
      labTotalAmount = parseFloat(cartData.patientLabTestsOrder[0].totalAmount);
      labTotalDiscountedAmount = parseFloat(
        cartData.patientLabTestsOrder[0].paidAmount
      );
    }
    setMedicineTotalAmount(medicineTotalAmount);
    setMedicineTotalDiscountAmount(medicineTotalDiscountedAmount);
    setLabTotalAmount(labTotalAmount);
    setLabTotalDiscountAmount(labTotalDiscountedAmount);
    let totalDiscountAmount = parseFloat(
      medicineTotalAmount +
        labTotalAmount -
        (medicineTotalDiscountedAmount + labTotalDiscountedAmount)
    );
    setTotalDiscountAmount(totalDiscountAmount);
    setTotalAmount(medicineTotalDiscountedAmount + labTotalDiscountedAmount);
    if (appliedCoupons?.length) {
      setCouponApply(true);
    } else {
      setCouponApply(false);
    }
    setAppliedCouponsList(appliedCoupons);
  };

  useEffect(() => {
    console.log(isCouponApply, !loadingForCoupon, "sdviodshousdgvuosdo");
    if (isCouponApply && !loadingForCoupon) {
      setShowCouponList(false);
    }
  }, [isCouponApply, loadingForCoupon]);

  // const handlegetCouponList = () =>{
  //   getCouponList(userData?.code, "v_cart")
  //   .then(res =>{
  //     if(res?.data?.data?.vouchersList?.length){
  //       setCouponList(res.data.data.vouchersList)
  //     }
  //   })
  // }

  const showError = () => {
    return (
      <>
        {loadingForCoupon ? (
          <div></div>
        ) : (
          <div
            className={
              isCouponApply
                ? `text-danger mt-3 text-xs px-4`
                : `text-secondaryColor mt-3  text-xs px-4`
            }
          >
            {couponVerify === "" ? "" : couponVerify}
          </div>
        )}
      </>
    );
  };

  const errorTimer = () => {
    let myTimeout = setTimeout(() => {
      setMedMembershipError({
        loading: false,
        error: "",
      });
      setLabMembershipError({
        loading: false,
        error: "",
      });
    }, 2000);
    // clearTimeout(myTimeout)
  };

  // useEffect(() => {
  //   if (userFromMedicare) {
  //     handleMedMembershipClick(null, "Medicare");
  //   }
  // }, [userFromMedicare]);

  // useEffect(() =>{
  //   if(isMedMemberShipAreadyApplied == '0' && firstTime && (cartData?.patientMedicineOrder?.length || cartData?.patientLabTestsOrder?.length) && (medMembershipData?.membershipCard || labMembershipData?.membershipCard) && !isCouponApply){
  //     setFirstTime(false);
  //     setTimeout(() => {
  //       handleMedMembershipClick(" ")
  //     }, 500);

  //   }

  // },[isMedMemberShipAreadyApplied, firstTime, cartData,medMembershipData, labMembershipData, isCouponApply])

  // useEffect(() =>{
  //   if(labMembershipData?.membershipCard && isLabMemberShipAreadyApplied == '0'){
  //     handleLabMembershipClick("")
  //   }

  // },[labMembershipData, isLabMemberShipAreadyApplied])

  console.log(
    isCouponApply,
    isMedMemberShipAreadyApplied,
    "sdvdsvousdgvoudsvds"
  );

  const handleMedMembershipClick = (e) => {
    console.log("sdbsduovccccc");
    if (isCouponApply || cartData?.corporateDiscount == "Y") {
      setMedMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });

      errorTimer();
      return;
    }
    console.log(medmembershipError, "sdbsduovccccc");
    if (medmembershipError.loading) {
      if (typeof e == "object") {
        e?.stopPropagation();
      }
      return;
    }
    console.log("sdbsduovccccc");
    let flag = false;
    if (isMedMemberShipAreadyApplied > 0) {
      flag = false;
    } else {
      flag = true;
    }
    setMedMembershipError({
      loading: true,
    });
    if (typeof e == "object") {
      e?.stopPropagation();
    }
    console.log(
      medmembershipError,
      "sdbsduovccccc",
      cartData,
      medMembershipData,
      labMembershipData
    );
    if (flag) {
      if (
        cartData?.patientMedicineOrder?.length &&
        medMembershipData?.membershipCard > 0
      ) {
        http
          .get(
            `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${medMembershipData?.membershipCard}&cartId=${cartData.cartId}&serviceType=medicine&amount=${totalAmount}`
          )
          .then((res) => {
            console.log(res, "dsvdsovusdvsd");
            setMedMembershipError({
              loading: false,
              error: "",
            });
            props.getCartData();
          })
          .catch((err) => {
            console.log(err.response, "dsvdsovusdvsd");
            setMedMembershipError({
              loading: false,
              error: err.response.data.message,
            });
          });
      }

      if (
        cartData?.patientLabTestsOrder?.length &&
        labMembershipData?.membershipCard > 0
      ) {
        http
          .get(
            `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${labMembershipData.membershipCard}&cartId=${cartData.cartId}&serviceType=labTests`
          )
          .then((res) => {
            console.log(res, "dsvdsovusdvsd");
            setMedMembershipError({
              loading: false,
              error: "",
            });
            dispatch(getCartDetails(userData.code));
          })
          .catch((err) => {
            console.log(err.response, "dsvdsovusdvsd");
            setMedMembershipError({
              loading: false,
              error: err.response.data.message,
            });
          });
      }
    } else {
      let data = cartData;
      if (
        cartData?.patientMedicineOrder?.length &&
        cartData.patientMedicineOrder[0].patientMedicineOrderDetailsList.length
      ) {
        data.patientMedicineOrder[0].membershipId = 0;
        data.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }

      if (
        cartData?.patientLabTestsOrder?.length &&
        cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.length
      ) {
        data.patientLabTestsOrder[0].membershipId = 0;
        data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.map(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }

      console.log(data, "sdvdsvodsuvodsuvds");
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then((res) => {
            setMedMembershipError({
              loading: false,
              error: "",
            });
          })
          .catch((err) => {
            setMedMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  const handleLabMembershipClick = (e) => {
    if (isCouponApply) {
      setLabMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });
      errorTimer();
      return;
    }
    if (labmembershipError.loading) {
      if (typeof e == "object") {
        e.stopPropagation();
      }
      return;
    }
    let flag = false;
    if (isLabMemberShipAreadyApplied > 0) {
      flag = false;
    } else {
      flag = true;
    }
    setLabMembershipError({
      loading: true,
    });
    if (typeof e == "object") {
      e.stopPropagation();
    }
    if (flag) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${labMembershipData.membershipCard}&cartId=${cartData.cartId}&serviceType=labTests`
        )
        .then((res) => {
          console.log(res, "dsvdsovusdvsd");
          setLabMembershipError({
            loading: false,
            error: "",
          });
          dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
          console.log(err.response, "dsvdsovusdvsd");
          setLabMembershipError({
            loading: false,
            error: err.response.data.message,
          });
        });
    } else {
      let data = cartData;
      data.patientLabTestsOrder[0].membershipId = 0;
      data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.map(
        (res) => {
          res.discountAmount = 0;
          res.totalAmount = 0;
        }
      );
      console.log(data, "sdvdsvodsuvodsuvds");
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then((res) => {
            setLabMembershipError({
              loading: false,
              error: "",
            });
          })
          .catch((err) => {
            setLabMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  useEffect(() => {
    if (selectedCouponCode) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsCouponAnEmail(emailRegex.test(selectedCouponCode));
    } else {
      setIsCouponAnEmail(false);
    }
  }, [selectedCouponCode]);

  const handleCouponSelectClick = (item, i) => {
    setselectedCouponCode(item.voucherCode);
    setAppliedCouponsList([]);
    // if (selectedCouponCode === item.voucherCode) {
    //   setselectedCouponCode(null);  // Unapply the coupon
    // } else {
    //   setselectedCouponCode(item.voucherCode);  // Apply the coupon
    // }
  };

  const [removeCouponTriggered, setRemoveCouponTriggered] = useState(false);

  const handleRemoveClick = (event, index) => {
    event.stopPropagation();
    handleCouponSelectClick({ voucherCode: "REMOVE" }, index);
    setRemoveCouponTriggered(true);
  };

  useEffect(() => {
    if (removeCouponTriggered) {
      handleApplyClick();
      setRemoveCouponTriggered(false);
    }
  }, [removeCouponTriggered]);

  const handleInputChange = (e) => {
    setselectedCouponCode(e.target.value);
  };

  const handleApplyClick = () => {
    if (selectedCouponCode == "") {
      setCouponVerify("Please enter valid Coupon code");

      return;
    } else if (selectedCouponCode == null) {
      setCouponVerify("Please select a  Coupon code");

      return;
    }

    if (isMedMemberShipAreadyApplied > 0) {
      setCouponVerify("Please remove membership discount");
      return;
    }
    const validCouponCodes = ["CB_MED10", "REMOVE"];

    if (!validCouponCodes.includes(selectedCouponCode)) {
      setCouponVerify("This coupon code is not available");
      return;
    }

    let amountLocalStorage = JSON.parse(localStorage.getItem("couponCode"));

    console.log("amountLocalStorage", amountLocalStorage);
    console.log("amountLocalStorage", selectedCouponCode);

    if (amountLocalStorage === selectedCouponCode) {
      return;
    }
    let payloads = {
      customer_identifier: cartData?.patientId,
      total_transaction_cost: +totalAmount.toFixed(2),
      cart_id: cartData?.cartId,
      transaction_id: new Date(new Date()).getTime(),
      voucher_code: selectedCouponCode,
      total_medicine_bill: medicineTotalAmount,
      customer_type: "Registered",
      total_labtest_bill: labTotalAmount,
    };

    setLoadingForCoupon(true);
    applyCouponCode(payloads).then((res) => {
      if (res.data.errors == null) {
        setCouponVerify("Coupon Removed");
        setLoadingForCoupon(false);
        localStorage.setItem("couponCode", JSON.stringify(selectedCouponCode));
        props.getCartData();
      } else {
        setTotalDiscountAmount(0);
        setCouponVerify(res.data.errors[0].message);
        setLoadingForCoupon(false);
        props.getCartData();
      }

      console.log("is coupan response", res);
    });
    setIsModalOpen(false);
  };

  useEffect(() => {
    localStorage.setItem(
      "totalAmount",
      JSON.stringify(parseFloat(totalAmount).toFixed(2))
    );
  }, [totalAmount]);

  useEffect(() => {
    if (
      parseFloat(medicineTotalAmount).toFixed(2) -
        parseFloat(medicineDiscountAmount).toFixed(2) >
      0
    ) {
      let amount = parseFloat(medicineDiscountAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    } else {
      let amount = parseFloat(medicineTotalAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    }
  }, [medicineDiscountAmount, medicineTotalAmount]);

  console.log(isCouponApply, "dvpihwiehviewhvoehvoehvoie");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setCouponVerify("");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setCouponVerify("");
    setIsModalOpen(false);
  };
  const [text, setText] = useState("CB_MED10");
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      message.success("Copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  if (medmembershipError.loading) {
    return (
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={medmembershipError.loading}
        onClick={() => {}}
      >
        <Spin indicator={<LoadingOutlined spin />} color="#ffff" size="large" />
      </Backdrop>
    );
  }

  const handleCorporateDiscountOtpVerification = (text) => {
    if (isMedMemberShipAreadyApplied > 0 || isLabMemberShipAreadyApplied > 0) {
      // message.error("Please remove membership discount to avail corporate discount.");
      setCouponVerify("Please remove membership discount");
      setShowOtpUI(false);
      return;
    }
    let payload = {
      email: selectedCouponCode,
      otp: text,
    };
    if (text.length == 6) {
      http
        .post(
          `${process.env.REACT_APP_BASEURL}PatientOTP/verifyCorporatePatientOTP`,
          payload
        )
        .then((res) => {
          if (res) {
            let payload = {
              patientId: userData?.code,
              cartId: cartData?.cartId,
              emailId: selectedCouponCode,
              serviceType: "medicine_lab",
              patientLabTestsOrder: cartData?.patientLabTestsOrder,
              patientMedicineOrder: cartData?.patientMedicineOrder,
              corporateEmployee: true,
            };
            http
              .post(
                `${process.env.REACT_APP_BASEURL}PatientCart/getCorporateDiscount`,
                payload
              )
              .then((res) => {
                if (res) {
                  dispatch(getCartDetails(userData.code));
                  setselectedCouponCode(null);
                  setShowOtpUI(false);
                  setFlagotp(true);
                  setIsModalOpen(false);
                }
              })
              .catch((err) => {
                console.log(err.response, "svkjsdvdsgusdgvds");
              });
          }
        })
        .catch((err) => {
          console.log(err.response.data, "svkjsdvdsgusdgvds");
          if (err?.response?.data?.details?.length) {
            message.error(err?.response?.data?.details[0]);
          }
        });
    }
  };

  const onChange = (text) => {
    setOtp(text);
    if (text.length) {
      handleCorporateDiscountOtpVerification(text);
    }
  };
  const onInput = (value) => {
    console.log("onInput:", value);
  };

  const sharedProps = {
    onChange,
    onInput,
  };

  const handleCorporateOtp = async () => {
    try {
      let result = await http.post(
        `${process.env.REACT_APP_BASEURL}PatientOTP/patientEmail/`,
        {
          email: selectedCouponCode,
        }
      );
      console.log(result,"RESULTOTP")
      if (result?.data === 1) {
        setShowOtpUI(true);
      } else {
        setShowOtpUI(false);
        // toast('We’re sorry! It seems this corporate email is not eligible for a discount. Please try our other coupon codes to enjoy extra savings.');
        message.warning("We’re sorry! It seems this corporate email is not eligible for a discount. Please try our other coupon codes to enjoy extra savings.")
      }
    } catch (error) {}
  };

  const handleRemoveCorporateDiscount = async () => {
    let payload = {
      patientId: userData?.code,
      cartId: cartData?.cartId,
      emailId: selectedCouponCode,
      serviceType: "medicine_lab",
      patientLabTestsOrder: cartData?.patientLabTestsOrder,
      patientMedicineOrder: cartData?.patientMedicineOrder,
      corporateEmployee: false,
    };
    http
      .post(
        `${process.env.REACT_APP_BASEURL}PatientCart/getCorporateDiscount`,
        payload
      )
      .then((res) => {
        dispatch(getCartDetails(userData.code));
      });
  };
  return (
    <>
      {/* {loading &&<Loader/>} */}
      <ToastContainer />
      {contextHolder}
      {checkingMembership ? (
        <div className="w-full">
          <div className=" bg-white-600 w-full rounded-lg ">
            <p className="text-sm lg:text-base font-semibold text-gray-800 mb-3">
              Select Coupon
            </p>
            <Skeleton className="mb-2 rounded-lg" height="2rem"></Skeleton>
            <div className="bg-white rounded-lg px-7 py-3">
              <p
                className="text-sm lg:text-sm font-semibold text-gray-800 leading-24 mb-2 "
                style={{ color: "#000000" }}
              >
                Cart total
              </p>
              <Skeleton className="mb-2 rounded-lg" height="9rem"></Skeleton>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className=" w-full rounded-lg">
            {!readOnly ? (
              <div>
                <div className="flex gap-4 w-full">
                  {cartData?.corporateDiscount == "Y" ? (
                    <div
                      className="px-2 py-3 w-full flex flex-col justify-start items-start sm:flex-row sm:items-center sm:justify-between"
                      style={{
                        background:
                          "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)",
                        borderRadius: 8,
                      }}
                    >
                      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 w-full">
                        <div className="flex items-center">
                          <p className="text-xs font-medium text-white">
                            <span className="text-secondaryColor">
                              Congratulations{" "}
                            </span>
                            🎉{" "}
                            <span className="text-White">
                              !! You get a corporate discount on your order.
                            </span>
                          </p>
                          <Popover
                            content={content}
                            trigger="click"
                            placement="top"
                            overlayClassName="custom-popover"
                          >
                            <div
                              className="flex items-center justify-center rounded-full bg-White ml-2"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <InfoCircleOutlined
                                className="cursor-pointer"
                                style={{
                                  color: "green",
                                  fontSize: "25px",
                                }}
                              />
                            </div>
                          </Popover>
                        </div>
                        <button
                          onClick={handleRemoveCorporateDiscount}
                          className="text-xs font-medium text-White border rounded border-white p-1 px-2 mt-2 sm:mt-0 sm:ml-auto"
                        >
                          REMOVE
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full">
                      <button
                        className=" px-3 py-3 border border-gray rounded   cursor-pointer bg-lightBlue  w-full text-sm"
                        onClick={(e) => {
                          hideShow();
                        }}
                      >
                        <div>
                          <div className="flex w-full justify-between items-center">
                            <div className="flex">
                              <img
                                src={DiscountIcon}
                                style={{ height: "20px" }}
                                className="mr-4"
                              />
                              <p className="font-medium text-sm text-primaryColor">
                                Avail Coupons
                              </p>
                            </div>
                            <button
                              onClick={showModal}
                              className="border border-primaryColor text-primaryColor text-xs font-semibold px-3 py-1.5 rounded"
                            >
                              APPLY
                            </button>
                          </div>

                          {isCouponApply ? (
                            <>
                              <div className="text-secondaryColor text-xs pl-8 pt-1 mb-4">
                                Coupon code applied{" "}
                              </div>
                              <div
                                className="rounded-xl p-3 cursor-pointer relative w-full"
                                style={{
                                  border: "1px solid rgba(0, 93, 141, 0.15)",
                                  background:
                                    selectedCouponCode === "CB_MED10"
                                      ? "rgba(25, 135, 84, 0.23)"
                                      : "white",
                                }}
                                onClick={() =>
                                  handleCouponSelectClick(
                                    { voucherCode: "CB_MED10" },
                                    0
                                  )
                                }
                              >
                                <div className="bg-green justify-start h-min-20 rounded-lg">
                                  <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                                    <h3 className="mx-4 my-2 pb-2 pt-1 w-full text-left">
                                      <b className="border border-1 border-[#313131] border-spacing-1 p-1">
                                        CB_MED10
                                        <CopyOutlined
                                          onClick={handleCopy}
                                          style={{
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            color: "#313131",
                                            marginLeft: "12px",
                                          }}
                                        />
                                      </b>
                                    </h3>
                                  </div>
                                  <p className="mx-4 p-1 text-grey font-normal text-sm text-left my-2">
                                    Flat 10% discount on all the medicines
                                  </p>

                                  <button
                                    className="absolute right-4 bg-danger text-White px-2 py-1 rounded top-[20px]"
                                    onClick={(e) => handleRemoveClick(e, 0)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
                <>
                  {medMembershipData?.membershipCard > 0 ||
                  isMedMemberShipAreadyApplied > 0 ||
                  labMembershipData?.membershipCard > 0 ? (
                    <button
                      onClick={(e) => handleMedMembershipClick(e)}
                      style={
                        isMedMemberShipAreadyApplied > 0
                          ? {
                              backgroundColor: "#66B889",
                              color: "#ffff",
                              borderColor: "#ffff",
                            }
                          : { backgroundColor: "#f3f8ff", color: "#1F2937" }
                      }
                      className={`bg-lightBlue border border-gray rounded w-full mt-4 px-3 py-3 flex flex-col justify-between relative`}
                    >
                      {/* {medmembershipError.loading && (
                          <div
                            className="absolute w-full flex justify-center"
                            style={{
                              backgroundColor: "rgb(255 255 255 / 86%)",
                            }}
                          >
                            <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                          </div>
                        )} */}
                      <div className="flex justify-between w-full items-center ">
                        <div className="flex items-center">
                          <img
                            src={DiscountIcon}
                            style={{ height: "20px" }}
                            className="mr-4"
                          />
                          <div
                            className={`font-medium text-sm ${
                              isMedMemberShipAreadyApplied > 0
                                ? "text-White"
                                : "text-primaryColor"
                            }`}
                          >
                            Avail Membership Discount
                          </div>
                        </div>

                        {/* <input
                            checked={
                              isMedMemberShipAreadyApplied > 0 ? true : false
                            }
                            className="cursor-pointer"
                            onChange={(e) =>
                              handleMedMembershipClick(
                                e,
                                medMembershipData.membershipCard
                              )
                            }
                            type="checkbox"
                          /> */}
                        <div
                          className={`border  ${
                            isMedMemberShipAreadyApplied > 0
                              ? "text-White border-White"
                              : "text-primaryColor border-primaryColor"
                          } text-xs font-semibold px-3 py-1.5 rounded`}
                        >
                          {isMedMemberShipAreadyApplied > 0
                            ? "REMOVE"
                            : "APPLY"}
                        </div>
                      </div>
                      <div className="text-xs w-full font-medium text-center mt-1 text-red-600">
                        {medmembershipError.error}
                      </div>
                    </button>
                  ) : null}
                </>

                {/* {cartData.patientLabTestsOrder == null ? null : cartData
                    .patientLabTestsOrder[0] &&
                  cartData.patientLabTestsOrder[0]
                    .patientLabTestsOrderDetailsList.length === 0 ? null : (
                  <>
                    {labMembershipData?.membershipCard > 0 ||
                    isLabMemberShipAreadyApplied > 0 ? (
                      <button
                        onClick={handleLabMembershipClick}
                        style={
                          isLabMemberShipAreadyApplied > 0
                            ? { backgroundColor: "#66B889", color: "#ffff" }
                            : { backgroundColor: "#ffff", color: "#1F2937" }
                        }
                        className={`w-full mt-4 rounded-lg py-4 px-2 md:flex flex-col justify-between relative`}
                      >
                        {labmembershipError.loading && (
                          <div
                            className="absolute w-full flex justify-center"
                            style={{
                              backgroundColor: "rgb(255 255 255 / 86%)",
                            }}
                          >
                            <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                          </div>
                        )}
                        <div className="flex justify-between w-full items-center">
                          <span className="text-sm font-bold mr-4">
                            Get Membership Discount (Lab)
                          </span>
                          <input
                            checked={
                              isLabMemberShipAreadyApplied > 0 ? true : false
                            }
                            className="cursor-pointer"
                            onChange={handleLabMembershipClick}
                            type="checkbox"
                          />
                        </div>
                        <div className="text-xs w-full font-medium text-center mt-1 text-red-600">
                          {labmembershipError.error}
                        </div>
                      </button>
                    ) : null}
                  </>
                )} */}

                {/* {cartData.patientLabTestsOrder == null ? null :
              cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.length == 0 ? null:
              <>
              {((labMembershipData?.membershipCard > 0 || isLabMemberShipAreadyApplied > 0)) ? <button onClick={handleLabMembershipClick} style = {isLabMemberShipAreadyApplied > 0 ? {backgroundColor: "#66B889", color: "#ffff"} : {backgroundColor: "#ffff", color: "#1F2937"}} className= {` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`} >
          {labmembershipError.loading && <div className="absolute w-full flex justify-center" style={{backgroundColor: "rgb(255 255 255 / 86%)"}} >
              <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
              </div>}
              <div className=  "flex justify-between w-full items-center"  >
                <span className="text-sm font-bold mr-4" >Get Membership Discount (Lab)</span>
                <input checked = {isLabMemberShipAreadyApplied > 0 ? true : false} className = "cursor-pointer" onChange = {handleLabMembershipClick} type= "checkbox" /> 
              </div>
              <div className="text-xs w-full font-medium text-center mt-1 text-red-600" >{labmembershipError.error}</div>
              </button> : null }  
              </> } */}
                <hr className="border-t border-gray my-5" />
              </div>
            ) : null}
            <div className="w-full bg-White ">
              <p className="text-lg font-semibold text-primaryColor mb-2">
                Billing <span className="text-secondaryColor">Details</span>
              </p>

              {medicineTotalAmount > 0 ? (
                <div>
                  <div className="flex justify-between mb-2">
                    <p className=" text-sm font-normal text-textGray">
                      Medicine Bill
                    </p>
                    <div className="flex">
                      <p></p>

                      {medicineDiscountAmount > 0 &&
                      medicineDiscountAmount != medicineTotalAmount ? (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(medicineTotalAmount).toFixed(2)}
                        </p>
                      ) : (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(medicineTotalAmount).toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {labTotalAmount > 0 ? (
                <div>
                  <div className="flex justify-between">
                    <p className=" text-sm font-normal text-textGray">
                      Lab Test Bill
                    </p>
                    <div className="flex">
                      <p></p>

                      {labDiscountAmount > 0 &&
                      labDiscountAmount != labTotalAmount ? (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(labTotalAmount).toFixed(2)}
                        </p>
                      ) : (
                        <p className="text-sm font-normal text-textGray">
                          ₹ {parseFloat(labTotalAmount).toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {totalDiscountAmount > 0 ? (
                <div className="flex justify-between mt-2">
                  <p className=" text-sm font-normal text-textGray">
                    Total Discount
                  </p>
                  <p className=" text-sm font-normal text-secondaryColor">
                    - ₹ {parseFloat(totalDiscountAmount).toFixed(2)}
                  </p>
                </div>
              ) : null}
              <hr className="my-3 border-t border-gray" />
              <div className="flex justify-between mt-3">
                <p className="text-primaryColor font-medium">Payable Amount</p>
                <p className="text-primaryColor font-medium">
                  ₹ {parseFloat(totalAmount).toFixed(2)}
                </p>
              </div>
              <button
                className="w-full mt-4 text-sm bg-transparent font-medium  bg-primaryColor text-White py-2.5 px-4 border border-brand-secondary rounded-md"
                onClick={() => props.handleProceed("")}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Select Coupon"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Tooltip target=".coupon-tooltip" />


        <div
          className={`w-full overflow-y-scroll overflow-x-hidden bg-white rounded-bl-lg rounded-br-lg`}
        >
          <div className="bg-white pb-1 sticky z-20 top-0">
            <div className="relative w-full px-4 mt-4">
            <input
          placeholder="Select Coupon Code or Enter Corporate Email Address"
          name="code"
          value={selectedCouponCode || ""}
          onChange={handleInputChange}
          className="bg-white font-medium text-gray-500 h-12 pl-4 pr-[6rem] py-2 w-full border border-gray-200 rounded-lg text-sm coupon-tooltip"
          data-pr-tooltip="Enter your corporate email or select a coupon code to avail the discount."
          data-pr-position="top" 
          tooltipOptions={{ autoHide: true }}
        />
              <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2  text-secondaryColor px-3 py-1 text-sm font-medium rounded"
                onClick={
                  isCouponAnEmail ? handleCorporateOtp : handleApplyClick
                }
              >
                {isCouponAnEmail ? "SEND OTP" : "Apply"}
              </button>
            </div>

            {showError("785785")}
          </div>
          <div className="flex items-center justify-center mb-2">
            {showOtpUI && (
              <OtpInput
                value={otp}
                onChange={onChange}
                numInputs={6}
                inputType="tel"
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input {...props} className="otp-input" />
                )}
              />
            )}
          </div>
          { !flagOtp && <div className="flex flex-col gap-4 max-h-96 mx-4 z-10 ">
            <div
              className="rounded-xl p-3 cursor-pointer relative w-full"
              style={{
                border: "1px solid rgba(0, 93, 141, 0.15)",
                background:
                  selectedCouponCode === "CB_MED10"
                    ? "rgba(25, 135, 84, 0.23)"
                    : "white",
              }}
              onClick={() =>
                handleCouponSelectClick({ voucherCode: "CB_MED10" }, 0)
              }
            >
              <div className="bg-green justify-center h-min-20 rounded-lg">
                <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                  <h3 className="px-4 pb-2 pt-1 w-full">
                    <b className="border border-1 border-[#313131] border-spacing-1 p-1">
                      CB_MED10
                      <CopyOutlined
                        onClick={handleCopy}
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          color: "#313131",
                          marginLeft: "12px",
                        }}
                      />
                    </b>
                  </h3>
                </div>
                <p className="mx-4 p-1 text-grey font-normal text-sm">
                  Flat 10% discount on all the medicines
                </p>

                <button
                  className="absolute bottom-4 right-4 bg-danger text-White px-2 py-1 rounded mb-12 lg:mb-8"
                  onClick={(e) => handleRemoveClick(e, 0)}
                >
                  Remove
                </button>
              </div>
            </div>

            {/* <div
  className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full "
  style={{
    border: "1px solid rgba(0, 93, 141, 0.15)",
    background: selectedCouponCode === 'CB_LABTEST10'
      ? "rgba(25, 135, 84, 0.23)"
      : "white",
  }}
  onClick={() => handleCouponSelectClick({ voucherCode: 'CB_LABTEST10' }, 1)}

>
  <div className="bg-green justify-center h-min-20 rounded-lg">
    <div className="flex flex-row border-b-2 border-dashed border-white w-full">
      <h3 className="px-4 pb-2 pt-1 w-full ">
        <b className="border-dashed border-2 p-1">CB_LABTEST10</b>
      </h3>
    </div>
    <p className="mx-4 p-1 text-grey font-normal text-sm">
    Flat 10% discount on all the lab tests


    </p>

    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6" style={{
      borderRight: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6" style={{
      borderLeft: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
  </div>
</div> */}

            {/* <div
  className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full "
  style={{
    border: "1px solid rgba(0, 93, 141, 0.15)",
    background: selectedCouponCode === 'CB_CONSULT10'
      ? "rgba(25, 135, 84, 0.23)"
      : "white",
  }}
  onClick={() => handleCouponSelectClick({ voucherCode: 'CB_CONSULT10' }, 2)}
>
  <div className="bg-green justify-center h-min-20 rounded-lg">
    <div className="flex flex-row border-b-2 border-dashed border-white w-full">
      <h3 className="px-4 pb-2 pt-1 w-full ">
        <b className="border-dashed border-2 p-1">CB_CONSULT10</b>
      </h3>
    </div>
    <p className="mx-4 p-1 text-grey font-normal text-sm">
    10% off on doctor Consultation




    </p>

    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6" style={{
      borderRight: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6" style={{
      borderLeft: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
  </div>
</div> */}
          </div>}
          <div className="grid grid-cols-2 items-center gap-5 mt-5">
            {/* <button className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base">
                                Cancel
                              </button>
                              <button className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White">
                                Apply Coupon
                              </button> */}
          </div>
        </div>
      </Modal>
    </>
  );
}
export default CartPrice;
