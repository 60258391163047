import { useDispatch, useSelector } from "react-redux";
import CurebayButton from "./Button/PrimaryButton"
import Bottles from "../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../Assets/pharamacyImages/Medicines.jpg";
import { useHistory } from "react-router-dom";
import { addMedicineToCart } from "../utils/cart-utils";
import { useEffect, useState } from "react";
import { AddtoCart } from "../../Redux/Action/cartPlaceOrderAction";
import { trackAddToCartEvent } from "../utils/analytics/events";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { getCartDetails } from "../../Redux/Action/cartPlaceOrderAction";
import http from "../../Redux/Services/http-common";
const MedicineCard = ({index = -1, cardItem, quantity = 1}) =>{
    const { cartList } = useSelector((state) => state.cartReducer);
    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector((state) => state.authReducer.patientData);
    // const [alternateMedicinedata,setAlternateMedicine]=useState()
    // const [alternateMedicineVendorid,setAlternateMedicineVendorid]=useState()

    const [statuscodeoriginalmed,setStatuscodeoriginalmed] =useState()
   
    const [statuscodealternatemed,setStatuscodealternatemed] =useState()

    const [stockEsquantity, setStockquantity] = useState({
      esmedicinestock: "",
      alternateMedicinestock: "",
    });
      const patientinfo = useSelector((state) => state.patientinfo);
      const { patientinfoData } = patientinfo;

    console.log(userData,cardItem?.vendorId,patientinfoData?.state,"ffcgtc")
    const history = useHistory()
    const dispatch = useDispatch();

    // useEffect(() => {
    //   fetchAlternate();
    //   // fetchStockItem();
    // }, [cardItem]);

//     const fetchAlternate = async () => {
//       try {
       
//         const payload = [ cardItem?.vendorId ];
//         console.log(payload, "Payload Log");

       
//         const response = await http.post(
//           `${process.env.REACT_APP_NEWBASEURL}eVital/alternateMedicine?orderBy=epm`,
//           payload,
//           {
//             headers: {
//               "Content-Type": "application/json", 
              
//             },
//           }
//         );
//         setAlternateMedicine(response?.data?.data)
//         console.log(response?.data?.data,"tftgygy")
//         const alternateVendorid = response?.data?.data?.map((item) => item?.vendorId);


// if (alternateVendorid && alternateVendorid.length === 1) {
//   setAlternateMedicineVendorid(alternateVendorid[0]); 
// }  
       
//         console.log(response.data.data, "Response Data");
//       } catch (error) {
//         console.error("Error fetching data:", error.message);
//       }
//     };

    // useEffect(() => {
    //   console.log(cardItem?.vendorId, alternateMedicineVendorid, "tfrvgvgv"); // Debug the values
    //   if (cardItem?.vendorId && alternateMedicineVendorid) {
    //     fetchStockItem();
    //   }
    // }, [cardItem, alternateMedicineVendorid]); 
    
    // const fetchStockItem = async () => {
    //   try {
    //     const payload1 = cardItem?.vendorId;
    //     const payload2 = alternateMedicineVendorid;
    
    //     console.log(payload1, "Payload 1 Log"); 
    //     console.log(payload2, "Payload 2 Log"); 
    
    //     // First API call
    //     const response1 = await http.post(
    //       `${process.env.REACT_APP_NEWBASEURL}eVital/stockCheck?state=${patientinfoData?.state}`,
    //       [payload1],
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     const response = response1?.data?.data
    //     const statusCode =  response?.status_code
    //     setStatuscodeoriginalmed(statusCode)

    //     console.log(response1?.data?.data,statusCode, "Response 1 Data");
    
    //     // Second API call
    //     const response2 = await http.post(
    //       `${process.env.REACT_APP_NEWBASEURL}eVital/stockCheck?state=${patientinfoData?.state}`,
    //       [payload2],
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     setStatuscodealternatemed(response2?.data?.data?.status_code)
    //     console.log(response2?.data?.data?.status_code, "TRTFGGGV");
    
    //     // Combine responses or set specific states
    //     // setStockquantity({
    //     //   esmedicinestock: response1?.data?.data,
    //     //   alternateMedicinestock: response2?.data?.data,
    //     // });
    
       
    //   } catch (error) {
    //     console.error("Error fetching data:", error.message);
    //   }
    // };
    
    

  

    const resolvePharamcyImageq = (Image) => {
        let PharamncyImage;
        if (Image.toUpperCase().includes("BOTTLE")) {
          PharamncyImage = Bottles;
        } else if (
          Image.toUpperCase().includes("CAPSULE") ||
          Image.toUpperCase().includes("CAPSULES")
        ) {
          PharamncyImage = Capsules;
        } else if (
          Image.toUpperCase().includes("CREAM") ||
          Image.toUpperCase().includes("CREAMS")
        ) {
          PharamncyImage = Creams;
        } else if (Image.toUpperCase().includes("CUREBAY")) {
          PharamncyImage = Curebay;
        } else if (Image.toUpperCase().includes("DEVICES")) {
          PharamncyImage = Devices;
        } else if (Image.toUpperCase().includes("DISPENSERS")) {
          PharamncyImage = Dispensers;
        } else if (Image.toUpperCase().includes("DROPLETS")) {
          PharamncyImage = Droplets;
        } else if (Image.toUpperCase().includes("EMULSIONS")) {
          PharamncyImage = Emulsions;
        } else if (
          Image.toUpperCase().includes("INJECTION") ||
          Image.toUpperCase().includes("INJECTIONS")
        ) {
          PharamncyImage = Injections;
        } else if (Image.toUpperCase().includes("LOTIONS")) {
          PharamncyImage = Lotions;
        } else if (Image.toUpperCase().includes("SATCHELS")) {
          PharamncyImage = Satchels;
        } else if (
          Image.toUpperCase().includes("TABLET") ||
          Image.toUpperCase().includes("TABLETS")
        ) {
          PharamncyImage = Tablet;
        } else {
          PharamncyImage = defaultMed;
        }
        // console.log(PharamncyImage);
        return PharamncyImage;
      };

    const handleImage = (images, type) => {
        if (typeof images === "string") {
          let result = images.replace(/[[\]]/g, "");
          let medicineImage = result.split(",");
          console.log(medicineImage);
          if (medicineImage.length !== 0) {
            if (medicineImage.length === 1 && medicineImage[0] === "") {
              return resolvePharamcyImageq(type);
            } else {
              return medicineImage[0];
            }
          } else {
            return resolvePharamcyImageq(type);
          }
        } else {
          return resolvePharamcyImageq(type);
        }
      };

      const getProductsIDs = () => {
        let ids = [];
        if (cartList) {
          if (cartList?.patientMedicineOrder?.length) {
            cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList?.map(res => {
              ids.push(res.drugCode)
            })
          }
        }
        return ids;
      };
      const drugIDsInCart = getProductsIDs();

      const redirectToMedicinePage = (e, item) => {
        // history.push('/medicine');
        history.push(`/medicine/${item.seoNameId}`);
      };

      // const handleClick = (e) =>{
      //   e.stopPropagation();
      //   if (Object.keys(userData).length === 0) {
      //       history.push(APP_ROUTES.HOME);
      //       return;
      //     } 
      //   setIsLoading(true)
      //   const dataObj = addMedicineToCart(userData, cartList, cardItem, quantity)
      //   console.log(dataObj, "sdvsdvisdhvousdvdssvd")

      //   dispatch(AddtoCart(dataObj)).then((res) => {
      //       trackAddToCartEvent(cardItem?.medicineRate, cardItem.medicineName)
      //       setIsLoading(false);
      //       dispatch(getCartDetails(userData.code));
      //     }).catch(err =>{
      //       setIsLoading(false)
      //     })
    
      // } 


      const handleClick = (e, medicineItem) => {
        e.stopPropagation();
        if (Object.keys(userData).length === 0) {
            history.push(APP_ROUTES.HOME, {isLoginModalOpen : true});
            return;
        } 
        //setIsLoading(true);
        setIsLoading(prevState => ({ ...prevState, [medicineItem.id]: true }));
        const dataObj = addMedicineToCart(userData, cartList, cardItem, quantity);
       

        const payload= {
          patientId: userData?.code,
          drugCode:medicineItem?.id,
          drugName: medicineItem?.medicineName,
          drugCategory: medicineItem?.drugCategory,
          medicineTypeOfSell: medicineItem?.medicineTypeOfSell,
          medicineType: "",
          unitPrice:  Number(medicineItem?.medicineRate),
          quantity: 1,
          prescriptionRequired:medicineItem?.prescriptionRequired,
          vendorId:medicineItem?.vendorId
        
        }
        console.log(payload, "sdvsdvisdhvousdvdssvd");
        // Use fetch or axios to make the API call to "url/newPatientMedicine/"
        fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then((res) => {
          console.log(res,"vghsdvhcvdsg")
            trackAddToCartEvent(medicineItem?.medicineRate, medicineItem.medicineName);
            // setIsLoading(false);
            dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
            console.error("Error adding to cart:", err);
            // setIsLoading(false);
        })
        .finally(() => {
         
          setIsLoading(prevState => ({ ...prevState, [medicineItem.id]: false }));
      });
        
    };

    
    return(
       <>
        <div key={index} className="bg-White rounded-lg p-5 pointer"  onClick={(e) => redirectToMedicinePage(e, cardItem)}>
        <div className="bg-lightBlue h-[150px] flex justify-center items-center rounded-lg cursor-pointer">
          <img
            // src={resolvePharamcyImageq(item.medicineTypeOfSell)}
            src={handleImage(
              cardItem.medicineImages,
              cardItem.medicineTypeOfSell
            )}
            alt="images"
            className="w-[90px] h-[90px] rounded-lg object-fill"
          />
        </div>
        <div className="pt-3">
          <div
            
            className=" cursor-pointer"
          >
            <h5 className="text-primaryColor text-lg mb-2 font-medium text-ellipsis overflow-hidden text-nowrap">
              {cardItem.medicineName}
            </h5>
            <div className="text-secondaryColor text-lg font-semibold flex items-baseline">
              <div className="flex w-fit">
                {" "}
                ₹{cardItem.medicineRate}
              </div>
              <div className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                {cardItem.medicineTypeOfSell}
              </div>
            </div>
          </div>
          <CurebayButton
        
                    className={`${ 
                      // statuscodeoriginalmed === '0' ? "bg-lightGray text-[#313131] cursor-not-allowed" :
                      !drugIDsInCart.includes(cardItem.id)
                        ? "bg-primaryColor text-White hover:bg-primaryColor"
                        : "border border-secondaryColor bg-secondaryColor disabled:opacity-75"
                    }  w-full rounded-lg text-md mt-2`}
                    onClick={(e) => handleClick(e, cardItem)}
                    loading={isLoading[cardItem.id] || false}
                    disabled={drugIDsInCart.includes(cardItem.id)}
                    size="large"
                    type="primary"   
                  >
                   {drugIDsInCart.includes(cardItem.id) ? "Added" : "Add to Cart"}
                  </CurebayButton>
        </div>
      </div>


      </>
    )
}

export default MedicineCard