import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import PrescriptionImage from "../../../Assets/Images/prescriptin.svg";
import DoctorPrescription from "../../../Assets/Images/DoctorPrescription.jpg";
import Search from "antd/es/input/Search";
import { CalendarOutlined } from "@ant-design/icons";
import { Radio, Select, Space } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import userprofileservice from "../../../Redux/Services/userprofileservice";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import { AddtoCart } from "../../../Redux/Action/cartPlaceOrderAction";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import UploadPrescriptionHelper from "../../Helper/uploadPrescription";
import { getbase64 } from "../../Helper/prescriptionTemplate";
import EPrescription from "../../../Assets//Images/EPrescription.svg";
import FamilyDropdown from "../PostLogin/HealthRecords/FamilyDropDown";
import noDataFound from "../../../Assets/Images/No data-found.svg";
import { ToastContainer, toast } from "react-toastify";
import { trackAddToCartEvent } from "../../utils/analytics/events";


function Eprescription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const [screen, setscreen] = useState(window.innerWidth);
  const [isAdding, setIsAdding] = useState(-1);
  const [isActive, setIsActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientCode, setPatientCode] = useState(0);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const [prescribedList, setPrescribedList] = useState();
  const [selectedDrugItem, setSelectedDrugItem] = useState(null);
  const [addingMultipleMedicineLoader, setAddingMultipleMedicineLoader] =
    useState(false);
  const [activePatient, setActivePatient] = useState();
  const [loading, setLoading] = useState({
    loadingSelectedPrescription: false,
    loadingAddedTocart: false,
    addToCartMessage: "",
    errorinSelectedPrescription: false,
    errorMessageforSelectedPrescription: "",
    errorinAddingtoCart: false,
    errorMessageWhenAddingToCart: "",
  });
  const userData = useSelector((state) => state.authReducer.patientData);
  const { cartList } = useSelector((state) => state.cartReducer);

  // const onChange = (item) => {
  //   setValue(item);
  // };

  useEffect(() => {
    loadPrescriptions(userData.code, 1);
    setActivePatient(userData.code);
  }, [userData.code]);

  

  const prescriptionSelected = async (data, index) => {
    console.log(data, "sdviohdsouvgdsoudsvvsd")
    setValue(index);
    if(data.patientDrugPrescriptionList !== null){
      let dataForPrescription = data.patientDrugPrescriptionList.map((res) => {
        return {
          ...res,
          ...data,
        };
      });
      setSelectedPrescription([data])
    }
    else{
      setSelectedPrescription([data])

      // setSelectedPrescription({});
    }
  
    setSelectedDrugItem(data.id);
    setLoading({
      loadingSelectedPrescription: true,
      loadingAddedTocart: false,
      addToCartMessage: "",
      errorinSelectedPrescription: false,
      errorMessageforSelectedPrescription: "",
      errorinAddingtoCart: false,
      errorMessageWhenAddingToCart: "",
    });
    console.log(data, "dslcnobewobeo");
    let prescribedItems = {
      medicines: [],
      labtest: [],
    };

    if (
      !data.patientDrugPrescriptionList?.length &&
      !data?.patientLabTestsList?.length
    ) {
      setSelectedDrugItem(null);
      setLoading({
        loadingSelectedPrescription: false,
        errorinSelectedPrescription: true,
        errorMessageforSelectedPrescription: "No Data found",
      });
      return;
    }

    if (data.patientDrugPrescriptionList?.length) {
      let medicineCode = data.patientDrugPrescriptionList.map((drug) => {
        return drug.drugCode;
      });
      var raw = JSON.stringify(medicineCode);

      await dispatch(getAllProductByIds(raw))
        .then((res) => {
          if (res) {
            console.log(res, "sdsdhvgiusdgviusdgvsd");
            prescribedItems.medicines = res;
          }
        })
        .catch((err) => {
          setSelectedDrugItem(null);
          setLoading({
            loadingSelectedPrescription: false,
            errorinSelectedPrescription: true,
            errorMessageforSelectedPrescription: "Medicines are not available",
          });
        });
    } else {
      setLoading({
        loadingSelectedPrescription: false,
        errorinSelectedPrescription: false,
        errorMessageforSelectedPrescription: "",
      });
    }

    data?.patientLabTestsList?.map((item) => {
      prescribedItems.labtest.push(item);
    });

    if (
      prescribedItems?.medicines?.length ||
      prescribedItems?.labtest?.length
    ) {
      setPrescribedList(prescribedItems);
    }

    if (screen <= 650) {
      if (data.id === isActive) {
        setIsActive(false);
      } else {
        setIsActive(data.id);
      }
    } else {
      setVisible(true);
    }
  };

  function getProductsIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientMedicineOrder) {
        for (const product of cartList.patientMedicineOrder) {
          if (product && product.patientMedicineOrderDetailsList) {
            for (const drug of product.patientMedicineOrderDetailsList) {
              ids.push(drug.drugCode);
            }
          }
        }
      }
    }
    return ids;
  }

  const addMultipleToCart = (e, data, index) => {
    // toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
    if (!userData.code) {
      redirectTo(e, {
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
    e.preventDefault();
    e.stopPropagation();
    // setIsAdding(true);

    let prescriptionRequired = "Y";
    let ePrescriptionRequired = "Y";
    //   :
    let dataObj = {};

    let isAdded = false;
    setAddingMultipleMedicineLoader(true);
    let productDetail = prescribedList.medicines.map((res) => {
      return {
        drugCode: res.id,
        drugName: res.medicineName,
        unitPrice: res.medicineRate,
        drugCategory: res?.drugCategory,
        discountAmount: res?.drugsInfoDiscountedRate
          ? res?.drugsInfoDiscountedRate
          : 0.0,
        totalAmount: res.medicineRate * 1,
        quantity: 1,
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        prescriptionRequired: prescriptionRequired,
        ePrescriptionRequired: ePrescriptionRequired,
        medicineTypeOfSell: res?.medicineTypeOfSell,
        membershipCode: null,
        membershipName: null,
        membershipCard: null,
        membershipDiscountPercentage: 0.0,
      };
    });

    if (cartList && cartList.patientMedicineOrder) {
      cartList.patientMedicineOrder.forEach((element) => {
        productDetail.map((res) => {
          element.patientMedicineOrderDetailsList.push(res);
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,
        labOrdersYN: false,
        drugsOrdersYN: true,
        totalAmount: 0,
        patientMedicineOrder: [
          {
            orderId: "",
            patientId: userData.code,
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            totalAmount: prescribedList.medicines[0].medicineRate,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            country: null,
            pincode: null,
            deliveryAddress1: null,
            deliveryAddress2: null,
            deliveryAddress3: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryCountry: null,
            deliveryZipcode: null,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            // docName: state?.detail?.prescriptionDoc,
            patientMedicineOrderDetailsList: productDetail,
          },
        ],
      };
    }

    console.log(dataObj, "dvcsdhovihdsou");
    if (
      dataObj?.patientMedicineOrder?.length &&
      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0
    ) {
      let id =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (item) => item.drugCode
        );
      id.map((item, index, self) => {
        if (self.indexOf(item) === index) {
        } else {
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].quantity +=
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              index
            ].quantity;
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].totalAmount =
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].quantity *
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].unitPrice;
        }
      });

      let res =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter(
          (item, i, self) => {
            if (id.indexOf(item.drugCode) === i) {
              return item;
            }
          }
        );

      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res;
    }
    dispatch(AddtoCart(dataObj))
      .then(async (res) => {
        let data = await getbase64();
        console.log(data, "sdvdsvhdsuvhsd");
        await UploadPrescriptionHelper(res, data, "base64");
        setAddingMultipleMedicineLoader(false);
        // setAddToCartLoading(false)
        // setDialog(false)
      })
      .catch((err) => {
        setAddingMultipleMedicineLoader(false);
      });
  };

  const redirectTo = (data) => {
    if (userData?.sessionid) {
      history.push({ pathname: APP_ROUTES.UPLOADED_PRESCRIPTION, state: data });
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  };

  const drugIDsInCart = getProductsIDs();
  const addtoCart = (e, data, index, quantity = 1) => {
    // toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
    if (!userData.code) {
      redirectTo(e, {
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
    e.preventDefault();
    setIsAdding(index);

    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.prescriptionRequired == "No") {
      prescriptionRequired = "N";
      // ePrescriptionRequired = "N";
    } else if (data?.prescriptionRequired == "Yes") {
      prescriptionRequired = "Y";
      // ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.drugsInfoDiscountedRate
      ? data?.drugsInfoDiscountedRate
      : data.medicineRate;
    const totalAmount = calculatedAmount * quantity;
    // data.discountPrice !== 0
    //   ? (data.unitPrice - data.discountPrice) * quantity
    //   :
    let dataObj = {};

    let isAdded = false;

    if (cartList && cartList.patientMedicineOrder) {
      cartList.patientMedicineOrder.forEach((element) => {
        element.patientMedicineOrderDetailsList.push({
          drugCode: data.id,
          drugName: data.medicineName,
          drugCategory: data.drugCategory,
          unitPrice: data.medicineRate,
          discountAmount: 0.0,
          totalAmount: totalAmount,
          quantity: quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: prescriptionRequired,
          ePrescriptionRequired: ePrescriptionRequired,
          medicineTypeOfSell: data?.medicineTypeOfSell,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.0,
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,
        labOrdersYN: false,
        drugsOrdersYN: true,
        totalAmount: totalAmount,
        patientMedicineOrder: [
          {
            orderId: "",
            patientId: userData.code,
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            totalAmount: totalAmount,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            country: null,
            pincode: null,
            deliveryAddress1: null,
            deliveryAddress2: null,
            deliveryAddress3: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryCountry: null,
            deliveryZipcode: null,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            patientMedicineOrderDetailsList: [
              {
                drugCode: data.id,
                drugName: data.medicineName,
                drugCategory: data.drugCategory,
                unitPrice: data.medicineRate,
                discountAmount: 0.0,
                totalAmount: totalAmount,
                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: data?.medicineTypeOfSell,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.0,
              },
            ],
          },
        ],
      };
    }
    dispatch(AddtoCart(dataObj)).then(async (res) => {
      console.log(res, "dsvdsvsduvgidusv");
      let data = await getbase64();
      console.log(data, "sdvdsvhdsuvhsd");
      await UploadPrescriptionHelper(res, data, "base64");
      trackAddToCartEvent(data.medicineRate, data.medicineName)
      setIsAdding(-1);
    });
  };


  const loadPrescriptions = (patient, selectPrevDate = 1) => {
    setValue(0);
    setSelectedPrescription([]);
    setIsLoading(true);
    setPatientCode(patient);
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let now = new Date();
    let backdate = 0;
    let payload = {};
    if (selectPrevDate === 1) {
      backdate = new Date(now.setDate(now.getDate() - 30));
    } else if (selectPrevDate === 2) {
      backdate = new Date(now.setDate(now.getDate() - 60));
    } else if (selectPrevDate === 3) {
      backdate = new Date(now.setDate(now.getDate() - 90));
    }
    if (selectPrevDate === 0) {
      payload = {
        patientId: patient,
        visitSummary: "Y",
        status: 1,
        documentRequired: "Y",
      };
    } else {
      payload = {
        patientId: patient,
        visitSummary: "Y",
        status: 1,
        fromDate: moment(backdate).format("MM/DD/yyyy"),
        toDate: moment(Date.now()).format("MM/DD/yyyy"),
        documentRequired: "Y",
      };
    }

    console.log(payload);
    userprofileservice.getmypriscription(payload).then(
      (res) => {
        if (res.data) {
          console.log(res.data);
          setPrescriptionsList(res.data);
        }
        setIsLoading(false);
      },
      (err) => {
        console.log("Error : " + JSON.stringify(err));
        setIsLoading(false);
      }
    );
  };

  const sampleMedicines = [
    {
      medicine_id: 1,
      medicine_name: "Azithrom 250mg Tablet",
      composition: "Azithromycin (250mg)",
      dosage_schedule: "0-0-1",
      duration: "5 days",
      special_instructions: "After Meal",
      Qty: 4,
    },
    {
      medicine_id: 2,
      medicine_name: "Paracetamol 500mg Tablet",
      composition: "Paracetamol (500mg)",
      dosage_schedule: "1-0-1",
      duration: "3 days",
      special_instructions: "With or without food",
      Qty: 2,
    },
    {
      medicine_id: 3,
      medicine_name: "Omeprazole 20mg Capsule",
      composition: "Omeprazole (20mg)",
      dosage_schedule: "0-1-0",
      duration: "7 days",
      special_instructions: "Before Meal",
      Qty: 5,
    },
  ];

  const handleDateSelectDate = (value) => {
    console.log(value);
    loadPrescriptions(patientCode, value);
  };

  console.log("selectedPrescription" , prescriptionsList)

  return (
    <><ToastContainer /><Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h1 className="text-primaryColor text-center text-2xl md:text-4xl font-bold mb-5 !leading-snug">
            CureBay <span className="text-secondaryColor"> e-Prescription</span>
          </h1>
          <div className="md:w-full bg-White rounded-xl sm:p-8 p-4">
            <div className="flex justify-end items-center gap-5">
              <h5 className="text-secondaryColor text-base font-medium">
                Family Member:
              </h5>
              {/* Drop Down Right */}
              {/* <Select
      defaultValue="lucy"
      style={{
        width: 200,
      }}
      options={[
        {
          value: "Jack",
          label: "Jack",
        },
        {
          value: "Seman",
          label: "Seman",
        },
      ]}
    /> */}
              <FamilyDropdown title={"Members"} height={"2rem"} onSelect={loadPrescriptions} />
              <Select
                defaultValue="All"
                style={{
                  width: 120,
                }}
                options={[
                  {
                    value: "All",
                    label: "All",
                  },
                  {
                    value: "1 Month",
                    label: "1 Month",
                  },
                ]}
                onChange={handleDateSelectDate} />
            </div>

            <div className="w-full md:flex gap-6 ">
              <div
                className={`${prescriptionsList?.length === 0
                  ? "w-full"
                  : "md:w-[50%] sm:w-[120%] w-[100%]"}`}
              >
                {prescriptionsList?.length !== 0 ? (
                  prescriptionsList.map((prescription, idx) => (
                    <div className="my-4 bg-White rounded-lg p-3 md:p-5 common-shadow ">
                      <div className="flex justify-between items-center gap-5">
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            name="test"
                            checked={value === idx + 1}
                            onChange={() => {
                              prescriptionSelected(prescription, idx + 1);
                            } } />
                          <div className="h-[90px]">
                            <img
                              src={EPrescription}
                              alt="images"
                              className="w-full h-full" />
                          </div>
                          <div className="sm:ml-8 ml-1">
                            <h4 className="text-primaryColor text-lg mb-3 font-medium">
                              Dr {prescription?.userName}
                            </h4>
                            <p className="text-textGray text-sm mb-1">
                              Patient Name:{" "}
                              <span className="font-light">
                                {prescription?.patientName}
                              </span>
                            </p>
                            <p className="text-textGray text-sm mb-1">
                              Consult Reason:{" "}
                              <span className="font-light">
                                {prescription.consultReason}
                              </span>
                            </p>
                            <div className="flex gap-2  mt-3 text-secondaryColor text-sm">
                              <CalendarOutlined />
                              <p>
                                {moment(prescription?.createdDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full h-full flex items-center justify-center mb-16">
                    <div className="flex flex-col">
                      <img
                        className="h-24 lg:h-28 mt-5 lg:mt-0 "
                        src={noDataFound}
                        alt="No Diagnostics Appointments Available" />
                      <h4 className="font-medium  text-gray-400 text-md">
                      You do not have any prescription yet..
                      </h4>
                    </div>
                  </div>
                )}
              </div>
              <div className="my-4">
                {Object.keys(selectedPrescription).length !== 0 && (
                 <div id= "abc"  className="w-full mb-4  overflow-x-auto cursor-pointer py-4 px-4">
                 <div
                    
                   className="p-5 bg-white-100 relative justify-center  border rounded-xl dark:border-slate-700 prescriptionTemplate"
                 //   style={{width:"1330px"}}
                 >
                    {/* {cart ? <Checkbox className= {`absolute z-10 ${checked ? "-top-2  -left-2" : "top-2 left-2"}`} onChange={e => handleClick(e.checked)} checked={checked}></Checkbox> : null} */}
         
                   {/* <span>{JSON.stringify(selectedPrescription)}</span> */}
                   <div className="px-2">
                     <div className="flex justify-between mb-4  ml-1">
                     </div>
         
                     <div className="lg:flex flex flex-col">
                       <h6 className="font-bold doc-font ">
                         {selectedPrescription[0]?.userSalutation ? selectedPrescription[0]?.userSalutation :"Dr."}{" "}
                         {selectedPrescription[0]?.userName}
                       </h6>
                       <h6 className="font-bold doc-font">
                         {selectedPrescription[0]?.userQualification}
                       </h6>
                       <h6 className="font-bold ">
                         {selectedPrescription[0]?.userSpecialityDept}
                       </h6>
                       <h6 className="font-bold ">
                         Regn No:{selectedPrescription[0]?.userMCIVerification}
                       </h6>
                       {/* <hr className="my-2" /> */}
                       <hr className="hr-line mt-6" />
                     </div>
         
                     <div className="grid grid-cols-2 sm:grid-cols-6 mb-5 mt-6">
                       <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Name:
                       </div>
                       <div className="border text-xs  dark:border-slate-700 px-3 py-1">
                         {selectedPrescription[0]?.patientsalutation +
                           selectedPrescription[0]?.patientName}
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Age/Sex:
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1">
                         {selectedPrescription[0]?.patientAge +
                           " / " +
                           (selectedPrescription[0]?.patientgender === "M"
                             ? "Male"
                             : "Female")}
                       </div>
         
                       {selectedPrescription[0]?.patientHospitalName === null ?" ":
         
                       (
                         <>
                         <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Hospital:
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1">
                         {selectedPrescription[0]?.patientHospitalName}
                       </div>
                       </>
         
                       )}
                      
                       <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Phone Number:
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1">
                         {selectedPrescription[0]?.patientMobileNo}
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Visit Type:
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1">
                         {selectedPrescription[0]?.consultationType === "Q"
                           ? "Quick Consultation"
                           : selectedPrescription[0]?.consultationType === "V"
                             ? "Video Consultation"
                             : selectedPrescription[0]?.consultationType === "I"
                               ? "In-person Consultation"
                               : selectedPrescription[0]?.consultationType === "A"
                                 ? "Audio Consultation"
                                 : "Quick Consultation"}
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                         Visit Date:
                       </div>
                       <div className="border text-xs dark:border-slate-700 px-3 py-1" v>
                         {moment(selectedPrescription[0]?.visitDate).format("DD/MM/yyyy")}
                       </div>
                     </div>
                     {/* <img src={rximg} style={{ width: "25px" }} /> */}
         
                     {selectedPrescription[0]?.consultReason &&
                       <div className="grid grid-cols-1 my-2">
                         <p>
                           <b className="underline underline-offset-1">
                             Chief Complaint:
                           </b>{" "}
                           {selectedPrescription[0]?.consultReason}
                         </p>
                       </div>
                     }
         
                     {(selectedPrescription[0]?.primarySymptoms || selectedPrescription[0]?.primaryDiagnosis) &&
                       <div className="grid grid-cols-1 my-2">
                         <p className="">
                           <b className="underline underline-offset-1">
                             Primary Diagnosis :
                           </b>{" "}
                         </p>
                         <p className="text-base font-montserrat  font-thin ">
                           ICD-10 : {selectedPrescription[0]?.primarySymptoms}{" "}
                         </p>
                         <p className="text-base font-montserrat  font-thin ">
                           Description : {selectedPrescription[0]?.primaryDiagnosis}{" "}
                         </p>
                       </div>
                     }
                     {(selectedPrescription[0]?.secondarySymptoms || selectedPrescription[0]?.secondaryDiagnosis) &&
                       <div className="grid grid-cols-1 my-2">
                         <p className="">
                           <b className="underline underline-offset-1">
                             Secondary Diagnosis :
                           </b>{" "}
                         </p>
                         <p className="text-base font-montserrat  font-thin ">
                           ICD-10 : {selectedPrescription[0]?.secondarySymptoms}{" "}
                         </p>
                         <p className="text-base font-montserrat  font-thin ">
                           Description : {selectedPrescription[0]?.secondaryDiagnosis}{" "}
                         </p>
                       </div>
                     }
                     {selectedPrescription[0]?.symptoms &&
                       <div className="mb-5">
                         <p className="underline underline-offset-1">
                           <b className="underline underline-offset-1">Symptoms :</b>{" "}
                         </p>
                         <p className="text-base font-montserrat  font-thin ">
                           {selectedPrescription[0]?.symptoms}
                         </p>
                       </div>
                     }
                     {selectedPrescription[0]?.patientVitalList && selectedPrescription[0]?.patientVitalList.length && (<>
                       <div className="grid grid-cols-1 my-2">
                         <p>
                           <b className="underline underline-offset-1">
                             Vitals :
                           </b>{" "}
                           {selectedPrescription[0]?.patientVitalList[0].height && (<> Height   - {selectedPrescription[0]?.patientVitalList[0].height} CM  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].weight && (<> Weight   -  {selectedPrescription[0]?.patientVitalList[0].weight}  KG  </>)}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].bmi && (<> BMI   -  {selectedPrescription[0]?.patientVitalList[0].bmi}  KG/M2  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].systolic && (<> Blood Pressure (SYS)  &nbsp;&nbsp; -  {selectedPrescription[0]?.patientVitalList[0].systolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].diastolic && (<> Blood Pressure (DIA)   -  {selectedPrescription[0]?.patientVitalList[0].diastolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].spo2 && (<> Pulse Ox.   -  {selectedPrescription[0]?.patientVitalList[0].spo2}  %  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].heartRate && (<> Heart Rate   -  {selectedPrescription[0]?.patientVitalList[0].heartRate}  Beats/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].respirationRate && (<> Respiration Rate   -  {selectedPrescription[0]?.patientVitalList[0].respirationRate}  Breaths/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           {selectedPrescription[0]?.patientVitalList[0].temperature && (<> Temperature   -  {selectedPrescription[0]?.patientVitalList[0].temperature}  °F  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         </p>
                       </div>
                     </>)}
         
         
                     <div className="mb-2 overflow-scroll">
                        {selectedPrescription[0]?.patientDrugPrescriptionList?.length &&
                       // selectedPrescription?.length &&   
                       //  selectedPrescription[0]?.patientDrugPrescriptionList.map((res, i)=> (
                       //   {selectedPrescription[0]?.patientDrugPrescriptionList?.length &&
                         <>
                           <p className="  mb-2 underline underline-offset-1">
                             <b className="underline underline-offset-1">
                               Medicines Prescribed :
                             </b>{" "}
                           </p>
         
         
                           <table className="table-auto w-full mb-5">
                             <thead>
                               <tr>
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                                   Medication
                                 </th>
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                                   Dosage
                                 </th>
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                                   Qty
                                 </th>
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                                   Instruction
                                 </th>
                                 {/* <th className="border-2 border-inherit">Periodically</th> */}
                                 <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                                   Duration
                                 </th>
                               </tr>
                             </thead>
                             <tbody>
                               {
                                 // selectedPrescription?.map((res, i) 
                                 selectedPrescription[0]?.patientDrugPrescriptionList.map((res, i)=> (
                                   <>
                                     <tr>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {i + 1}
                                       </td>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {res?.drugName}
                                         <br></br>
                                         <span className="text-xs">{res?.composition}</span>
                                       </td>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {res?.dosage}
                                       </td>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {res?.quantity}
                                       </td>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {res.instruction == "0"
                                           ? "After Meal"
                                           : "Before Meal"}
                                       </td>
                                       <td className="border dark:border-slate-700 px-3 py-1">
                                         {" "}
                                         {res?.duration} days
                                       </td>
                                     </tr>
                                   </>
                                 ))}
                             </tbody>
                           </table>
                         </>
                       }
                       <div className="mt-2  mb-5">
                         {selectedPrescription[0]?.patientLabTestsList?.length &&
                           <p className="  underline underline-offset-1  mb-2">
                             <b className="underline underline-offset-1">
                               Lab Test Prescribed :
                             </b>{" "}
                           </p>
                         }
                         {selectedPrescription[0]?.patientLabTestsList?.length &&
                          
                             <div>
                               <div className="">
                                 <div className="text-md font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                                  Test Name
                                 </div>
                                 {/* <div className="text-md font-bold font-rubik  text-left border dark:border-slate-700 px-3 py-1">
                                   Test Type
                                 </div> */}
                               </div>
                                  { selectedPrescription[0]?.patientLabTestsList.map((labres, i) => ( <div
                                     className=" "
                                     key={i}
                                   >
                                     <div className="text-sm  border dark:border-slate-700 px-3 py-1">
                                       {labres?.labTestName}
                                     </div>
                                     {/* <div className="text-sm  text-left border dark:border-slate-700 px-3 py-1">
                                       {labres?.testType}
                                     </div> */}
                                   </div>))}
                                 {/* ))} */}
                             </div>
                           }
                       </div>
                     </div>
                     <div className="my-2">
                       <div className="w-6/6  my-2">
                         {/* <div>
                           <p className="">Cheif Complaint</p>
                           <p className="text-base font-montserrat  font-thin ">
                             {selectedPrescription?.consultReason}
                           </p>
                         </div> */}
         
                         {selectedPrescription[0]?.recommendation && (
                           <div className="mb-5">
                             <p className="underline underline-offset-1">
                               <b className="underline underline-offset-1">
                                 Recommendation :
                               </b>{" "}
                             </p>
                             <p className="text-base font-montserrat  font-thin ">
                               {selectedPrescription[0]?.recommendation}
                             </p>
                           </div>
                         )}
         
                         {selectedPrescription[0]?.followUpVisitDate && (
                           <div>
                             <p className=" my-2">
                               <b className="underline underline-offset-1">
                                 Scheduled follow up visit date :
                               </b>{" "}
                               {moment(selectedPrescription[0]?.followUpVisitDate).format(
                                 "DD/MM/YYYY"
                               )}
                             </p>
                           </div>
                         )}
                       </div>
                     </div>
         
                     <div className="flex  justify-between">
         
         
                       <div className="mt-6">
                         {selectedPrescription[0]?.hospitalStamp &&
                           <img
                             // onClick={download}
                             // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${selectedPrescription[0].hospitalStamp}`}
                             src = {`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0].hospitalStamp}`}
                             alt="Stamp"
                             width={150}
                           />
                         }
                       </div>
         
                       <div className="mt-6">
                         {selectedPrescription[0]?.userSignature && (
                           <>
                             <img
                               // onClick={download}
                              // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${selectedPrescription[0].userSignature}`}
                               src = {`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0].userSignature}`}
                               alt="Signature"
                               width={150}
                             />
                             <p className="font-rubik text-gray-primary text-sm">
                               Dr.{selectedPrescription[0]?.userName}
                             </p>
                             <p className="font-rubik text-gray-primary text-sm">
                               {selectedPrescription[0]?.userQualification}{" "}
                               {selectedPrescription[0]?.userSpecialityDept}
                             </p>
                             <p className="font-rubik text-gray-primary text-sm">
                               Regn No. {selectedPrescription[0]?.userMCIVerification}
                             </p>
                           </>
                         )}
                       </div>
                     </div>
                   </div>
                   <div className="grid grid-cols-1 ">
                     <div className="px-1 py-1">
         
                       {
                         selectedPrescription[0]?.consultationType === "I" ?
                           (
                             <span> </span>
         
                           ) :
                           (
                             <p className="text-gray-500 text-sm ">
                               <b>Disclaimer :</b>
                               <ul className="list-disc ml-8">
                                 <li>The information and advice provided here is provisional in nature as it is based on the limited information made available by the patient</li>
                                 <li>The patient is advised to visit in person for thorough examination at the earliest</li>
                                 <li>The information is confidential in nature and for recipient's use only</li>
                                 <li>The Prescription is generated on a Teleconsultation</li>
                                 <li>Not valid for medico - legal purpose</li>
                                 {" "}
                               </ul> 
                             </p>
                           )
                       }
         
         
                     </div>
                   </div>
                   <div className="grid grid-cols-1 ">
                     <div className="px-1 py-1">
                       <p className="text-gray-500 text-sm ">
         
                       </p>
                     </div>
                   </div>
                 </div>
                 </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts></>
  );
}

export default Eprescription;
