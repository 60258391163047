import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { DownOutlined, UpOutlined , RightOutlined } from '@ant-design/icons';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import moment from "moment";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Order1Icon from '../../../../Assets/Images/Order section icons/Grey icons/Yet to Pack.svg';
import Order2Icon from '../../../../Assets/Images/Order section icons/Grey icons/Packed.svg';
import Order3Icon from '../../../../Assets/Images/Order section icons/Grey icons/Shipped.svg';
import Order4Icon from '../../../../Assets/Images/Order section icons/Grey icons/Order on the Bus.svg';
import Order5Icon from '../../../../Assets/Images/Order section icons/Grey icons/Out for Delivery.svg';
import Order6Icon from '../../../../Assets/Images/Order section icons/Grey icons/Delivered.svg';

import Order1IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Yet to Pack.svg';
import Order2IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Packed.svg';
import Order3IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Shipped.svg';
import Order4IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Order on the Bus.svg';
import Order5IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Out for Delivery.svg';
import Order6IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Delivered.svg';

const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-square': {
    width: 8,
    height: 8,
    borderRadius: '8px',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#784af4',
      },
    },
  ],
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-square" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    width: 2,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed ? "" : '', 
  zIndex: 1,
  color: ownerState.completed ? "#00654D" : '#a1a1a1', 
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: ownerState.completed ? '0 4px 10px 0 rgb(213,245,229,.25)' : 'none', // Add shadow when completed
  ...theme.applyStyles('dark', {
    backgroundColor: ownerState.completed ? theme.palette.success.light : theme.palette.grey[700],
  }),
}));



function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <img src={ Order1IconGreen} alt="Order Step 1" style={{ width: 35, height: 35 }} />,
    2: <img src={(completed || active) ? Order2IconGreen : Order2Icon} alt="Order Step 2" style={{ width: 35, height: 35 }} />,
    3: <img src={(completed || active) ? Order4IconGreen : Order4Icon} alt="Order Step 4" style={{ width: 35, height: 35 }} />,
    4: <img src={(completed || active) ? Order5IconGreen : Order5Icon} alt="Order Step 5" style={{ width: 35, height: 35 }} />,
    5: <img src={(completed || active) ? Order6IconGreen : Order6Icon} alt="Order Step 6" style={{ width: 35, height: 35 }} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}


ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const intitalSteps = ["Yet to Pack", "Packed", "Shipped", "Out for Delivery", "Delivered"];

export default function MobOrderTracking({ trackingStatus }) {

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  console.log(trackingStatus, "trackingStatustrackingStatus")

  if(trackingStatus == null){
    return(
      <div
      className="flex flex-col items-center  bg-White rounded-lg cursor-pointer transition-transform duration-300 transform hover:scale-105"
      onClick={toggleExpanded}
    >
        <div className="text-base font-semibold mb-4">Order Details</div>
      <div
        className="flex justify-between items-center w-full border p-2 rounded"
        onClick={toggleExpanded}
      >
        <span className="text-sm font-medium">Track your order</span>
        <span className="transform transition-transform duration-200">
          {expanded ? (
            <DownOutlined style={{ fontSize: '14px' }} />
          ) : (
            <RightOutlined style={{ fontSize: '14px' }} />
          )}
        </span>
      </div>

      {expanded && (
     <Stack sx={{ width: '100%',  ml: 4,  }} spacing={2}>

          <Stepper
            orientation="vertical"
            activeStep={0}
            connector={<ColorlibConnector />}
          >
            {intitalSteps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1}>
                <div>{label}</div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      )}
    </div>
    )
    }

    const updatedStatus = (() => {
      let orderOnBusIndex = trackingStatus.findIndex(
          item => item.consignmentStatusName === "Order on the Bus"
      );
    
      if (orderOnBusIndex === -1) return trackingStatus;
    
      return trackingStatus
          .filter(item => item.consignmentStatusName !== "Order on the Bus")
          .map((item, index) => {
              if (index > orderOnBusIndex - 1) {
                  return {
                      ...item,
                      consignmentStatus: item.consignmentStatus - 1
                  };
              }
              return item;
          });
    })();

  return (
    <>
 <div
      className="flex flex-col items-center  bg-White rounded-lg cursor-pointer transition-transform duration-300 transform hover:scale-105"
      onClick={toggleExpanded}
    >
        <div className="text-base font-semibold mb-4">Order Details</div>
      <div
        className="flex justify-between items-center w-full border p-2 rounded"
        onClick={toggleExpanded}
      >
        <span className="text-sm font-medium">Track your order</span>
        <span className="transform transition-transform duration-200">
          {expanded ? (
            <DownOutlined style={{ fontSize: '14px' }} />
          ) : (
            <RightOutlined style={{ fontSize: '14px' }} />
          )}
        </span>
      </div>

      {expanded && (
     <Stack sx={{ width: '100%',  ml: 4,  }} spacing={2}>

          <Stepper
            orientation="vertical"
            activeStep={trackingStatus ? trackingStatus[trackingStatus.length - 1].consignmentStatus : 0}
            connector={<ColorlibConnector />}
          >
            {intitalSteps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1}>
                <div>{label}</div>
                {trackingStatus[index - 1]?.createdDate ? <div>{moment(trackingStatus[index]?.createdDate).format("hh:mm A")}</div> : null}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      )}
    </div>
    </>
  );
}

MobOrderTracking.propTypes = {
  trackingStatus: PropTypes.array,
};
