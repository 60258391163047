import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { Col, Modal, Row, Select, message } from "antd";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";

import Hospital from "../../../../Assets/Images/HospitalB2C.webp";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import {
  getPatientmedicinedeliveryList,
  getPatientmedicinedeliveryListFilter,
  getPatientmedicinedeliveryListOnFilter,
} from "../../../../Redux/Action/patientAction";
import moment from "moment";
import {
  getLocalTime,
  getLocalDateWithTime,
} from "../../../Helper/LocalTimeFormat";
import { getAllProductByIds } from "../../../../Redux/Action/pharmacyAction";
import {
  AddtoCart,
  getCartDetails,
} from "../../../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../../../Helper/uploadPrescription";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";
import B2cOrder from "./b2cOrder";
import CheOrder from "./cheorder";

function MedicineOrders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [list, setList] = useState([]);
  const [medicineList, setmedicineList] = useState([]);
  const [orderType, setOrderType] = useState("b2c");
  const [pageStart, setPageStart] = useState(1);
  const [prescriptionDialog, setPrescriptionDialog] = useState(false);
  const [prescriptionDoc, setPrescriptionDoc] = useState([]);
  const [reorderData, setReorderData] = useState([]);
  const [isDialogOpen, setDialog] = useState(false);
  const [cartData, setCartData] = useState({});
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [currSelectedOrder, setCurrSelectedOrder] = useState();
  const [selectedPatient, setSelectedpatient] = useState(userData?.code);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/", {isLoginModalOpen : true});
    }
  }, []);

  const handleChange = (code) => {
    setSelectedpatient(code);
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md  md:px-8 px-6 md:py-5 py-3">
            <div className="md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Medicine Order
              </div>
              <div className="hidden md:flex gap-2">
                {/* <div
                  className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex items-center cursor-pointer"
                  // onClick={showModal}
                >
                  <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
                </div> */}
                <FamilyDropdown
                  onSelect={handleChange}
                  title={"Get medicine orders for"}
                />
                <Select
                  defaultValue={orderType}
                  placeholder={orderType}
                  onChange={(code) => setOrderType(code)}
                  options={[{ value: "b2c" }, { value: "eclinic" }]}
                  className="h-12 w-full md:w-[200px] text-base"
                ></Select>
              </div>
            </div>
          </div>
          <div className="md:hidden flex gap-2 mt-5">
            <FamilyDropdown
              onSelect={handleChange}
              title={"Get medicine orders for"}
            />
            <Select
              defaultValue={orderType}
              placeholder={orderType}
              onChange={(code) => console.log("sdvugsdvsdv", code)}
              options={[{ value: "b2c" }, { value: "eclinic" }]}
              className="h-12 w-full md:w-[200px] text-base"
            ></Select>
            {/* <div
              className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
              // onClick={showModal}
            >
              <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
            </div> */}
          </div>
          {orderType == "b2c" ? (
            <B2cOrder selectedPatient={selectedPatient} />
          ) : (
            <CheOrder selectedPatient={selectedPatient} />
          )}
        </div>
      </section>
    </Layouts>
  );
}

export default MedicineOrders;
