// LabOrders.js
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  message,
} from "antd";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import LabImage from "../../../../Assets/Images/LabImage.jpg";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import {
  getPatientLabTestsList,
  getPatientLabTestsListFilter,
} from "../../../../Redux/Action/UserprofileActions";
import { DICOM_URL } from "../../../../config/constant";
import moment from "moment";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";
import LabServices from "../../../../Redux/Services/labServices";
import doctorService from "../../../../Redux/Services/doctorService";
import http from "../../../../Redux/Services/http-common";
import LabOrderTracking from "./labOrderTracking";
import MobLabOrderTracking from "./mobLabOrderTracking.jsx";
function LabOrders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);

  const [modal1, setModal1] = useState(false);
  const [currSelectedOrder, setCurrSelectedOrder] = useState();
  const [list, setList] = useState([]);
  const [addlist, setAddList] = useState({});
  const [labReport, setLabReport] = useState([]);
  const [filePopup, setFilePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [dicomToken, setDicomToken] = useState("");
  const [dicomURL, setDicomURL] = useState("");
  const [dicomPopup, setDicomPopup] = useState(false);
  const [orderStatusDetails, setOrderStatusDetails] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/", { isLoginModalOpen: true });
    }
  }, [userData, history]);

  useEffect(() => {
    setAddList({});
    dispatch(getPatientLabTestsList(userData.code,pageNumber,pageSize))
      .then((res) => {
        let data = res?.filter((item) => item.procedureStatus !== -1);
        setList(data);
        setTotalSize(data[0].totalSize || 0);
        fetchLabOrderReports(userData.code);
      })
      .catch((err) => {
        setList([]);
      });
  }, [ userData.code, pageNumber, pageSize]);

  console.log(list, "avbduvbsdubvsidvbsdvds")

  const handlePaginationChange = (page, size) => {
    console.log(page,size,"tfggh")
    setpageNumber(page);
    setPageSize(size);
  };

  const fetchLabOrderReports = (code) => {
    let payload = {
      patientId: code,
      docmentRequired: "Y",
    };
    LabServices.getLabReportDetails(payload).then(
      (res) => {
        console.log(res.data, "Fetched lab reports");
        if (res.data) {
          // Group documents by orderId
          const groupedData = res.data.reduce((acc, curr) => {
            const { orderId, photoName, type, id, labTestName } = curr;
            if (!acc[orderId]) {
              acc[orderId] = [];
            }
            acc[orderId].push({ photoName, type, id, labTestName });
            return acc;
          }, {});
          setLabReport(groupedData);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (addlist.fromDate && !addlist.toDate) {
      return message.error("Please select ToDate");
    }
    if (!addlist.fromDate && addlist.toDate) {
      return message.error("Please select FromDate");
    }
    let payload = { ...addlist, patientId: userData.code };
    dispatch(getPatientLabTestsListFilter(payload))
      .then((result) => {
        let data = result?.filter((item) => item.procedureStatus !== -1);
        setList(data);
        setAddList({});
        setIsModalOpen(false);
      })
      .catch((err) => {
        setList([]);
      });
  };
  const handleFamilyMemberChange = (value) => {
    dispatch(getPatientLabTestsList(value))
      .then((res) => {
        let data = res?.filter((item) => item.procedureStatus !== -1);
        setList(data);
        fetchLabOrderReports(value);
      })
      .catch((err) => {
        setList([]);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (e) => {
    setAddList({ ...addlist, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date, dateString) => {
    setAddList({
      ...addlist,
      ["fromDate"]: dateString,
    });
  };
  const handleDateChangeTo = (date, dateString) => {
    setAddList({
      ...addlist,
      ["toDate"]: dateString,
    });
  };

  const viewOrder = (doc) => {
    const fileType =
      doc.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : doc.type.toLowerCase() === "jpg"
        ? "image/jpeg"
        : doc.type.toLowerCase() === "jpeg"
        ? "image/jpeg"
        : doc.type.toLowerCase() === "png"
        ? "image/png"
        : doc.type;

    if (fileType === "dicom") {
      viewDicomFile(doc.dicomId);
    } else {
      window
        .open(process.env.REACT_APP_IMG_BASEURL + doc.photoName, "_blank")
        .focus();
    }
  };

  const viewDicomFile = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDicomURL(data.details);
        setDicomPopup(true);
      });
  };

  const onDownloadDocument = async (name) => {
    let url = selectedRow.photoName
      ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
      : "";
    let filename = selectedRow.type;

    doctorService.imagetoData(selectedRow.photoName).then((res) => {
      var base64;
      if (filename === "application/pdf") {
        base64 = "data:application/pdf;base64,";
      } else {
        base64 = "data:image/png;base64,";
      }
      var FileSaver = require("file-saver");
      FileSaver.saveAs(base64 + res.data.fileData, selectedRow.photoName);
    });
    console.log(selectedRow.photoName, "selectedRow.photoName");
    setFilePopup(false);
  };

  const onCancelDocument = () => {
    setFilePopup(false);
  };

  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onDownloadDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Download
        </button>

        <button
          onClick={() => onCancelDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };

  const showModal1 = (labOrder) => {
    http
      .post(`${process.env.REACT_APP_NEWBASEURL}lab/sampleStatus/list`, {
        orderId: labOrder?.patientLabTestOrderId,
      })
      .then((res) => {
        if (res.data?.length) {
          setOrderStatusDetails(res.data);
          setModal1(true);
          setCurrSelectedOrder(labOrder);
        } else {
          setOrderStatusDetails([]);
          setCurrSelectedOrder({});
          message.warning("Confirming order, please wait for 5 mins.");
        }
      })
      .catch((err) => {});
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Lab Orders
              </div>
              <div className="hidden md:flex gap-2">
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-10 md:h-12 h-10 flex items-center cursor-pointer"
                  onClick={showModal}
                >
                  <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
                </div>
                <FamilyDropdown
                  title={"Get lab orders for"}
                  onSelect={handleFamilyMemberChange}
                />
              </div>
            </div>
          </div>
          <div className="md:hidden flex gap-2 mt-5">
            <FamilyDropdown
              title={"Get lab orders for"}
              onSelect={handleFamilyMemberChange}
            />
            <div
              className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
              onClick={showModal}
            >
              <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
            </div>
          </div>
          <div className="mt-5">
            <Row gutter={24}>
              {list?.map((order, i) => (
                <Col xs={24} sm={24} md={12} key={i}>

<div className="w-full rounded-lg p-6 bg-white shadow-md mb-6">
  <div className="flex flex-col md:flex-row gap-6">
    <div className="w-full md:w-1/4 hidden md:block">
      <img
        src={
          order.hospitalPhoto
            ? `${process.env.REACT_APP_IMG_BASEURL}${order.hospitalPhoto}`
            : LabImage
        }
        alt="Hospital"
        className="h-32 w-full rounded-lg shadow-sm object-cover"
      />
     
    </div>
    <div className="w-full">
      <div className="flex justify-between items-center">
        <h3 className="text-primaryColor font-semibold text-xl">
          {order.patientLabTestsOrderDetailsList[0]?.hospitalName}
        </h3>
        <span className="text-secondaryColor bg-lightGreen px-4 py-2 rounded-full text-sm font-medium">
          {order.procedureStatusName}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4 text-sm text-textGray">
        <div>
          <label className="font-semibold">Order ID</label>
          <p>{order.orderId}</p>
        </div>
        <div>
          <label className="font-semibold">Placed On</label>
          <p>{moment(order.collectionSampleDate).format("DD-MM-YYYY")}</p>
        </div>
        <div>
          <label className="font-semibold">Consignment ID</label>
          <p>{order.consignmentId}</p>
        </div>
        <div>
          <label className="font-semibold">Consignment Date</label>
          <p>{moment(order.consignmentDate).format("DD-MM-YYYY")}</p>
        </div>
      </div>
    </div>
  </div>

  <div className="mt-6">
    {order.patientLabTestsOrderDetailsList.map((user1, i) => {
      const doc = labReport[order.orderId]?.find(
        (d) => d.labTestName === user1.labTestName
      );

      return (
        <div
          key={i}
          className="grid grid-cols-4 items-center gap-4 py-3 border-t border-gray"
        >
          <div className="text-sm font-medium text-primaryColor">
            {user1?.labTestName}
          </div>
          <div className="text-sm text-right font-medium text-secondaryColor">
            ₹{" "}
            {parseFloat(user1?.discountAmount)
              ? parseFloat(user1?.discountAmount).toFixed(2)
              : parseFloat(user1?.amount)?.toFixed(2)}
          </div>
          <div className="text-right">
            {doc ? (
              <button
                className="text-secondaryColor underline text-sm hover:text-blue-600"
                onClick={() => viewOrder(doc)}
              >
                View Document
              </button>
            ) : (
              <span className="text-textGray text-xs">No Document</span>
            )}
          </div>
          <div className="text-right">
            <button
              className="text-secondaryColor underline text-sm hover:text-blue-600"
              onClick={() => showModal1(user1)}
            >
              Track Status
            </button>
          </div>
        </div>
      );
    })}
  </div>
</div>

                </Col>
              ))}

              {list && list.length === 0 && (
                <div className="w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="No Data" className="w-[60px]" />
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No lab orders available!
                  </p>
                </div>
              )}
            </Row>

            { list?.length > 0 &&
          <div className="flex justify-center mt-4">
          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={totalSize}
            showSizeChanger={false} 
            onChange={handlePaginationChange}
          />
        </div>
}

          </div>
        </div>
      </section>

      {/* Filter Modal */}
      <Modal
        title="Filter Orders"
        className="commonModal"
        centered={true}
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
        width="50%"
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="Order ID" name="orderId">
            <Input
              id="orderId"
              name="orderId"
              onChange={handleChange}
              value={addlist.orderId}
              placeholder="Enter Order Id"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="From Date" name="fromDate">
            <DatePicker
              onChange={handleDateChange}
              format="MM/DD/YYYY"
              placeholder="fromDate "
              className="w-full"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="To Date" name="toDate">
            <DatePicker
              onChange={handleDateChangeTo}
              format="MM/DD/YYYY"
              placeholder="toDate"
              className="w-full"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="Lab Name" name="labName">
            <Input
              id="labName"
              name="labName"
              onChange={handleChange}
              placeholder="Lab Name"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label="Procedure Status"
            name="procedureStatus"
          >
            <Select
              id="procedureStatus"
              name="procedureStatus"
              options={[
                { value: "1", label: "Ordered" },
                { value: "2", label: "Completed" },
                { value: "0", label: "Pending" },
                { value: "5", label: "Cancelled By Patient" },
                { value: "6", label: "Cancelled By Diagnostics" },
              ]}
              onChange={(e) =>
                setAddList({ ...addlist, ["procedureStatus"]: e })
              }
              placeholder="Select Procedure Status"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label="Report Uploaded Status"
            name="Report status"
          >
            <Select
              id="status"
              name="status"
              options={[
                { value: "1", label: "Pending" },
                { value: "2", label: "Uploaded" },
              ]}
              onChange={(e) => setAddList({ ...addlist, ["status"]: e })}
              placeholder="Select Uploaded Status"
            />
          </Form.Item>
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] md:text-lg"
          >
            Cancel
          </button>
          <button
            className="bg-primaryColor w-full h-[50px] md:text-lg rounded-lg text-White"
            onClick={handleOk}
          >
            Filter
          </button>
        </div>
      </Modal>

      {/* Document Modal */}
      <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Lab Report</p>
        }
        visible={filePopup}
        modal={false}
        width="60%"
        onCancel={() => setFilePopup(false)}
        footer={renderDocumentFooter("displayModal")}
      >
        <p className="p-m-0">
          <div className="lg:flex pt-2 ">
            <div className="w-full">
              <div className="lg:flex lg:pt-1 g:space-x-10 ">
                {selectedRow?.type !== "application/pdf" ? (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    className="box target object-contain"
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}
                  />
                ) : (
                  <iframe
                    id="abc"
                    className="box target w-full h-auto"
                    style={{ width: "100%", height: "500px" }}
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}
                  />
                )}
              </div>
            </div>
          </div>
        </p>
      </Modal>

      {/* Tracking Status Modal */}
      <Modal
        title="View Lab Order Details"
        centered={true}
        open={modal1}
        onCancel={() => setModal1(false)}
        width="60%"
        footer={null}
      >
        {/* {orderStatusDetails.length > 0 ? (
          <LabOrderTracking trackingStatus={orderStatusDetails} />
        ) : (
          <p>No tracking details available.</p>
        )} */}
        {orderStatusDetails.length > 0 ? (
          <div>
            {/* Desktop View */}
            <div className="hidden md:block">
              <LabOrderTracking trackingStatus={orderStatusDetails} />
            </div>

            {/* Mobile View */}
            <div className="block md:hidden">
              <MobLabOrderTracking trackingStatus={orderStatusDetails} />
            </div>
          </div>
        ) : (
          <p>No tracking details available.</p>
        )}
      </Modal>
    </Layouts>
  );
}

export default LabOrders;
