import React, { useState, useEffect } from "react";
import http from "../../../Redux/Services/http-common";
import { Dialog } from "primereact/dialog";
import { format } from "date-fns";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { removeSuccess, updateDoctorAppointment } from "../../../Redux/Action/doctorAction";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import "./Orders/RescheduleModal.css";
import moment from "moment";
import { Sidebar } from 'primereact/sidebar';
import { notification } from "antd";

const RescheduleModal = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ morning: [], afternoon: [], evening: [] });
  const [loading, setLoading] = useState(true);
  const { errMsg, isError, success } = useSelector(
    (state) => state.doctorAppointment
  );
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [update, setUpdate] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [selectedDate, setSelectedDate] = useState();
  const [timeIndex, selectTimeIndex] = useState({});
  const [slot, setSelectedSlot] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const generateDays = () => {
      const daysArray = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() + i);
        daysArray.push({
          displayDate: format(date, "dd, MMM yyyy"),
          apiDate: format(date, "MM/dd/yyyy"),
        });
      }
      setDays(daysArray);
    };

    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    generateDays();

    return () => window.removeEventListener("resize", updateIsMobile);
  }, [currentDate]);

  const onClose = () => {
    props.onClose();
    dispatch(removeSuccess());
  };

  const fetchSlots = async (selectedDate) => {
    try {
      setLoading(true);
      setError(null);

      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}AppointmentTemplate/getAppointmentSlots?userId=${props.data?.userId}&fromDate=${selectedDate}&toDate=${selectedDate}&status=1&video=Y`
      );

      const { morning = [], afternoon = [], evening = [] } = response.data || {};
      setData({ morning, afternoon, evening });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlots(format(currentDate, "MM/dd/yyyy"));
  }, [currentDate]);

  
  const renderSlotsRow = (slots) => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {slots.map((slot, index) => (
          <div
            key={index}
            onClick={() => {
              if (slot.slotAvailability === "Y") {
                selectTimeIndex({ time: slot.fromTime });
                setSelectedSlot(slot);
              }
            }}
            className="text-sm p-2"
            style={{
             
              border: "1px solid",
              borderColor: timeIndex.time === slot.fromTime ? "#007bff" : "#ccc", // Blue border for selected
              borderRadius: "8px",
              cursor: slot.slotAvailability === "Y" ? "pointer" : "not-allowed",
              backgroundColor:
                timeIndex.time === slot.fromTime
                  ? "#e7f3ff" // Light blue for selected
                  : slot.slotAvailability === "Y"
                  ? "#ffffff" // White for available
                  : "#f0f0f0", // Grey for unavailable
              color: timeIndex.time === slot.fromTime ? "#007bff" : "#000", // Blue text for selected
              fontWeight: timeIndex.time === slot.fromTime ? "bold" : "normal",
              transition: "all 0.3s ease",
            }}
          >
            {slot.fromTime}
          </div>
        ))}
      </div>
    );
  };
  const updateAppointment = () => {
    if (!slot) {
      setError("Please select a valid slot.");
      return;
    }

    setLoading(true);
    setUpdate(false);

    const obj = {
      id: props.data.id,
      patientId: props.data.patientId,
      userId: props.data.userId,
      hospitalId: props.data.hospitalId,
      locationId: props.data.locationId,
      whenAppointment: selectedDate || props.data.whenAppointment,
      fromTime: slot?.fromTime || props.data.fromTime,
      toTime: slot?.toTime || props.data.toTime,
      consultationsReason: props.data.consultationsReason,
      consultationsType: props.data.consultationsType,
      status: props.data.status,
      statusName: props.data.statusName,
      sourceChannel: props.data.sourceChannel,
      userPhotoName: props.data.userPhotoName,
      userName: props.data.userName,
      hospitalName: props.data.hospitalName,
      email: userData?.email,
      patientMobileNo: userData?.mobile,
      patientName: props.data.patientName,
      totalAmount: props.data.totalAmount,
      createdBy: props.data.patientId,
      modifiedBy: props.data.patientId,
    };

    dispatch(updateDoctorAppointment(obj))
      .then((res) => {
        if (res === 1) {
          // setUpdate(true);
          onClose();
          notification.success({
            message: "Appointment Rescheduled",
            description: "Your appointment has been rescheduled successfully.",
            duration: 3, 
            placement: "topRight", 
        });
          setLoading(false);
        }
      })
      .catch(() => {
        setError("Failed to update appointment. Please try again.");
        setLoading(false);
        setUpdate(false);
      });
  };

  const handleDateChange = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + (direction === "forward" ? 1 : -1));
    setCurrentDate(newDate);
    setSelectedDate(format(newDate, "MM/dd/yyyy"));
    fetchSlots(format(newDate, "MM/dd/yyyy"));
  };

  return (
    <Sidebar
    visible={true}
    onHide={onClose}
    className="custom-sidebar"
    position="right"
    style={{ width: '100%' }} 
  >
      <div className="modal-container">
        <div className="modal-header">
          <p>Select a Slot to Reschedule an Appointment - {props.data.userName}</p>
          {/* <CloseOutlined onClick={onClose} className="close-icon" /> */}
        </div>
        <div className="date-selector">
  <button
    onClick={() => handleDateChange("backward")}
    className="nav-button"
    disabled={moment(currentDate).isSameOrBefore(moment(), "day")} // Disable if current date is today or earlier
  >
    <LeftOutlined />
  </button>
  {isMobile ? (
    <button className="date-button active">
      <p>{format(currentDate, "EEE")}</p>
      <p>{format(currentDate, "dd MMM yyyy")}</p>
    </button>
  ) : (
    days
      .filter((day) => new Date(day.apiDate) >= new Date().setHours(0, 0, 0, 0)) // Filter out previous dates
      .map((day, index) => (
        <button
          key={index}
          onClick={() => {
            setSelectedDate(day.apiDate);
            fetchSlots(day.apiDate);
          }}
          className={`date-button ${selectedDate === day.apiDate ? "active" : ""}`}
        >
          <p>{day.apiDate === format(new Date(), "MM/dd/yyyy") ? "Today" : day.displayDate.split(",")[0]}</p>
          <p>{day.displayDate.split(",")[1]}</p>
        </button>
      ))
  )}
  <button onClick={() => handleDateChange("forward")} className="nav-button">
    <RightOutlined />
  </button>
</div>


        {loading ? (
          <p className="loading-text">Loading slots...</p>
        ) : error ? (
          <p className="error-text">Error: {error}</p>
        ) : (
          <div className="slots-container">
            <div className="slots-column">
              <h2>Morning Slots</h2>
              {data.morning.length > 0 ? renderSlotsRow(data.morning) : <p>No slots available</p>}
            </div>
            <div className="slots-column">
              <h2>Afternoon Slots</h2>
              {data.afternoon.length > 0 ? renderSlotsRow(data.afternoon) : <p>No slots available</p>}
            </div>
            <div className="slots-column">
              <h2>Evening Slots</h2>
              {data.evening.length > 0 ? renderSlotsRow(data.evening) : <p>No slots available</p>}
            </div>
          </div>
        )}
        {update && <p className="success-text">Your appointment has been successfully updated.</p>}
        {isError && <p className="error-text">{errMsg}</p>}
        <div className="text-right pb-4 mt-6">
          <Button
            disabled={!timeIndex.time}
            
            onClick={updateAppointment}
            className={`disabled:opacity-50 ${!timeIndex.time && "cursor-not-allowed"} bg-secondaryColor text-White px-5 py-3 text-sm font-medium rounded-xl`}
          >
            <b>Update</b>
          </Button>
        </div>
      </div>
    </Sidebar>
    
  );
};

export default RescheduleModal;
